import styled from "styled-components";

export const ModalStakeContainer = styled.div``;
export const ModalStakeWrapper = styled.div``;
export const ModalStakeHeading = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  .title-heading {
    color: var(--Neutral-White, #fff);
    font-family: Rubik;
    font-size: 24px;
    font-weight: 600;
  }
`;
export const ModalStakeOption = styled.div`
  .ant-select {
    border-radius: 5px;
  }
  .title-option {
    color: #dfdfdf;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.14px;
    margin-bottom: 3px;
  }
  .list-mounth {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    li {
      width: calc(100% / 2 - 5px);
      &.is-acitve {
        button {
          background: #4d4d4d;
          span {
            background: var(--Mint-Green-100, #dcfce2);
          }
        }
      }
    }
    button {
      background: unset;
      border: unset;
      outline: none;
      border-radius: 4px;
      border: 1px solid var(--stroke-25-button, rgba(255, 255, 255, 0.4));
      background: #1d1d1d;
      background-blend-mode: luminosity;
      box-shadow: 2px 4px 16px 0px rgba(248, 248, 248, 0.06) inset;
      backdrop-filter: blur(50px);
      width: 100%;
      color: var(--Neutral-White, #fff);
      text-align: center;
      font-family: Rubik;
      font-size: 14px;
      font-weight: 500;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: background 0.26s ease;
      span {
        display: inline-block;
        margin-right: 8px;
        width: 12px;
        height: 12px;
        border-radius: 24px;
        border: 1px solid var(--Neutral-White, #fff);
        background: #3a3a3a;
        transition: background 0.26s ease;
      }
    }
  }
  .text-note {
    color: var(--Sub-light-text, #9f9f9f);
    font-size: 13px;
    text-align: center;
    margin-bottom: 10px;
    span {
      position: relative;
      padding-left: 18px;
      &:before {
        position: absolute;
        content: "";
        top: 50%;
        margin-top: -7px;
        left: 0;
        background: url("/img/Dashboard/mining/icn_warning.png") center / cover
          no-repeat;
        width: 14px;
        height: 14px;
      }
    }
  }
`;
export const BalanceHeading = styled.p`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
    span {
      color: #dfdfdf;
      font-family: Rubik;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.14px;
    }
`

export const ModalStakeBalance = styled.div`
  input {
    border: solid 1px #fff;
    border-radius: 5px;
    padding: 4px 14px;
    font-size: 20px;
    margin-bottom: 10px;
    &:focus,
    &:hover {
      border-color: #fff !important;
    }
    &::placeholder {
      color: #5b5b5b;
      font-family: Rubik;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      position: relative;
      bottom: 4px;
    }
  }
  .ant-checkbox-inner {
    border-radius: 4px;
    border: 1px solid var(--Neutral-White, #fff);
    background: #3a3a3a;
  }
  .ant-checkbox-wrapper {
    span {
      color: var(--Sub-light-text, #9f9f9f);
      font-family: Rubik;
      font-size: 13px;
      letter-spacing: -0.01em;
    }
  }
  .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
    .ant-checkbox-checked:not(.ant-checkbox-disabled)
    .ant-checkbox-inner {
    background-color: unset;
    border: 1px solid var(--Neutral-White, #fff);
  }
  .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
    .ant-checkbox-inner,
  .ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
    border-color: #fff;
    background-color: unset;
  }
  .ant-checkbox .ant-checkbox-inner:after {
    border-color: #9f9f9f;
  }
  .ant-checkbox.ant-wave-target.ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #052e0f;
      border-color: #ceffd1;
      &:after {
        border-color: #c7f61b;
      }
    }
  }
  &.is-profit {
    input {
      pointer-events: none;
      cursor: not-allowed;
    }
  }
`;
export const ModalStakeButton = styled.button`
  margin-top: 10px;
  outline: none;
  background: unset;
  border: none;
  color: var(--Neutral-White, #fff);
  text-align: center;
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  border-radius: 8px;
  border: 1px solid var(--stroke-25-button, rgba(255, 255, 255, 0.4));
  background: var(--Surface-Background, rgba(40, 40, 40, 0.7));
  background-blend-mode: luminosity;
  box-shadow: 2px 4px 16px 0px rgba(248, 248, 248, 0.06) inset;
  backdrop-filter: blur(50px);
  width: 100%;
  padding: 17px;
  cursor: pointer;
  transition: all .26s ease;
  &:disabled  {
    opacity: .5;
    cursor: not-allowed;
  }
`;

export const ModalStakeContract = styled.div`
  input {
    border: solid 1px #fff;
    border-radius: 5px;
    padding: 4px 14px;
    font-size: 20px;
    margin-bottom: 10px;
    &:focus,
    &:hover {
      border-color: #fff !important;
    }
    &::placeholder {
      color: #5b5b5b;
      font-family: Rubik;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      position: relative;
      bottom: 4px;
    }
  }
`