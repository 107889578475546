import React from "react";
import { MemberDesktopContainer } from "./styled";
import { shortenAddress } from "../../../utils/addressUser";
import { formatNumberMega } from "../../../utils/formatNumber";
import moment from "moment";

const NetworkMemberDesktop = ({ data }: any) => {
  const listData = [
    {
      text: "Address",
      value: shortenAddress(data.address),
    },
    {
      text: "Created at",
      value: moment(Number(data.createdAt)).format("D/M/YYYY"),
    },
    {
      text: "Total Income",
      value: formatNumberMega(data.totalIncome),
    },
    {
      text: "Total Staking",
      value: formatNumberMega(data.totalStaking),
    },
    {
      text: "Sponsor",
      value: shortenAddress(data.refCode),
    },
  ];
  return (
    <MemberDesktopContainer>
      {listData?.map((item: any, index: number) => (
        <div className="data-row" key={index}>
          <p className="data-text">{item.text}</p>
          <p className="data-value">{item.value}</p>
        </div>
      ))}
    </MemberDesktopContainer>
  );
};

export default NetworkMemberDesktop;
