import { Modal } from "antd"
import { ModalBody, ModalDecor, ModalTitle, ModalTransaction } from "./styled"
import { Link } from "react-router-dom"
import { ButtonCommon } from "../../../Layout/styled"

const ModalSuccess = ({ hash, isModalOpen, handleOk, handleCancel }: any) => {
  return (
    <Modal className="modal-success" footer={false} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
      <ModalDecor />
      <ModalTitle>
        <h2>
          Successfully
        </h2>
        <p>Your transaction has been successfully completed.</p>
      </ModalTitle>
      <ModalBody>
        <figure>
          <img width={164} height={167} src="./img/Common/modal_icon_success.png" alt="icon" loading="lazy" />
        </figure>
        <ModalTransaction>
          <Link to={`https://bscscan.com/tx/${hash}`} target="_blank" rel="noreferrer">
            Transaction hash
            <img width={14} height={14} src="./img/Dashboard/Mining/icn_external.png" alt="icon" loading="lazy" />
          </Link>
        </ModalTransaction>
        <ButtonCommon onClick={handleCancel} className="gray">
          Got it
        </ButtonCommon>
      </ModalBody>
    </Modal>
  )
}

export default ModalSuccess