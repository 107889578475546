import { ChartSmallWrapper } from "./styled";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { useState } from "react";

const ChartSmall = ({ backgroundLinear }: any) => {
  const [chartData, setChartData] = useState<{
    series: { name: string; data: number[] }[];
    options: ApexOptions;
  }>({
    series: [
      {
        name: "Series 1",
        data: [31, 40, 28, 51, 42, 109, 100],
      },
    ],
    options: {
      chart: {
        height: 150,
        type: "area",
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      colors: ["#0096C7", "red", "green"],
      xaxis: {
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        show: false,
      },
      grid: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 3,
        colors: backgroundLinear ? backgroundLinear : ["#0096C7"],
      },
      fill: {
        type: "gradient",
        gradient: {
          type: "vertical",
          shadeIntensity: 1,
          gradientToColors: backgroundLinear
            ? backgroundLinear
            : ["rgb(0, 150, 199, .5)"],
          opacityFrom: 0.5,
          opacityTo: 0.1,
          stops: [0, 100],
        },
      },
      markers: {
        size: 0,
      },
      tooltip: {
        enabled: false,
      },
    },
  });

  return (
    <ChartSmallWrapper>
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="area"
        height={150}
      />
    </ChartSmallWrapper>
  );
};

export default ChartSmall;
