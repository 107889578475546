import styled from "styled-components";
import { BlockFrame, ButtonCommon } from "../../Layout/styled";
import { ProfileWrapper } from "../../components/Common/Profile/styled";
import { TitleWrapper } from "../../components/Common/Title/styled";
import { MiningReward } from "../Mining/styled";

export const DashboardContainer = styled.div``;
export const DashboardContent = styled.div``;
export const DashboardTop = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 20px;
  ${BlockFrame} {
    &:nth-child(1) {
      width: calc(100% - 600px);
    }
    &:nth-child(2) {
      max-width: 600px;
      width: 100%;
    }
  }
  @media (min-width: 1024px) and (max-width: 1600px) {
    justify-content: space-between;
    ${BlockFrame} {
      &:nth-child(1) {
        width: calc(100% - 370px);
      }
      &:nth-child(2) {
        max-width: 370px;
      }
    }
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    display: block;
    ${BlockFrame} {
      &:nth-child(1) {
        width: 100%;
      }
      &:nth-child(2) {
        margin-top: 20px;
        max-width: 100%;
      }
    }
  }
  @media screen and (max-width: 767.98px) {
    display: block;
    margin-bottom: 25px;
    ${BlockFrame} {
      &:nth-child(1) {
        background: transparent;
        width: unset;
        border: 0;
        padding: 0;
      }
    }
  }
`;
export const DashboardTopHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  ${TitleWrapper} {
    margin-bottom: 0;
  }
  .content-pack {
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 9px;
    border: 1px solid var(--stroke-25-button, rgba(255, 255, 255, 0.4));
    background: var(--Surface-Background, rgba(40, 40, 40, 0.7));
    background-blend-mode: luminosity;
    box-shadow: 2px 4px 16px 0px rgba(248, 248, 248, 0.06) inset;
    backdrop-filter: blur(50px);
    padding: 5px 20px 5px 5px;
    p {
      color: #fff;
      text-align: center;
      font-size: 20px;
      font-weight: 500;
    }
  }
  @media screen and (max-width: 1024px) {
    .content-pack {
      p {
        font-size: 12px;
      }
    }
  }
`;
export const DashboardTopTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  ${TitleWrapper} {
    max-width: 58%;
    margin-bottom: 0;
  }
  ${ButtonCommon} {
    padding: 0;
    border-radius: 12px;
    color: var(--Neutral-White, #fff);
    font-size: 15px;
    font-weight: 500;
    background: url("/img/Dashboard/bg_addLP.png") center / cover no-repeat;
    width: 127px;
    height: 42px;
  }
  @media screen and (max-width: 1024px) {
    margin-bottom: 15px;
    ${TitleWrapper} {
      max-width: 50%;
    }
  }
`;
export const DashboardTopInner = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
export const DashboardMaxOut = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8%;
  height: 100%;
  ${ProfileWrapper} {
    figure {
      width: 34px;
      height: 36px;
    }
    div {
      p {
        font-size: 21px;
      }
      span {
        font-size: 17px;
      }
    }
  }
  @media screen and (max-width: 767.98px) {
    display: block;
    padding: 40px 35px;
    ${ProfileWrapper} {
      figure {
        width: 35px;
        height: 35px;
      }
      div {
        p {
          font-size: 15px;
        }
        span {
          font-size: 17px;
        }
      }
    }
  }
`;
export const PotentialPoolList = styled.ul`
  display: flex;
  gap: 10px;
  & > li {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    width: 33.33%;
    min-height: 235px;
    padding: 16px;
    border-radius: 6px;
    overflow: hidden;
    &::before {
      content: "";
      position: absolute;
      left: 50%;
      margin-left: -30px;
      bottom: 15px;
      width: 60px;
      height: 3px;
      border-radius: 10px;
    }
    &.item-1 {
      background: linear-gradient(
        180deg,
        rgba(99, 255, 128, 0.19) 0%,
        rgba(53, 131, 67, 0.19) 100%
      );
      &::before {
        background-color: #44a456;
      }
    }
    &.item-2 {
      background: linear-gradient(
        180deg,
        rgba(94, 233, 201, 0.28) 0%,
        rgba(53, 131, 113, 0.28) 100%
      );
      &::before {
        background-color: #4dbca2;
      }
    }
    &.item-3 {
      background: linear-gradient(
        180deg,
        rgba(97, 97, 97, 0.22) 0%,
        rgba(199, 199, 199, 0.22) 100%
      );
      &::before {
        background-color: #313131;
      }
    }
    & > div {
      &:nth-child(1) {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      &:nth-child(2) {
        padding-bottom: 30px;
        p {
          color: #fff;
          font-size: 28px;
          font-weight: 500;
          display: flex;
          align-items: center;
          gap: 5px;
        }
        span {
          display: block;
          color: #9f9f9f;
          font-size: 13px;
          margin-bottom: 5px;
        }
      }
    }
  }
  @media (min-width: 768px) and (max-width: 1600px) {
    & > li {
      & > div {
        &:nth-child(1) {
          display: block;
          ${ButtonCommon} {
            margin-top: 10px;
          }
        }
        &:nth-child(2) {
          p {
            font-size: 20px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    width: calc(100% / 2 - 5px);
    &.list-2 {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .item-2,
      .item-3 {
        width: unset;
        min-height: 134px;
      }
    }
    & > li {
      width: 100%;
      padding: 12px;
      & > div {
        &:nth-child(2) {
          padding-bottom: 10px;
          p {
            font-size: 20px;
          }
        }
      }
    }
  }
`;
export const MaxOutCircular = styled.div`
  position: relative;
  max-width: 250px;
  .CircularProgressbar .CircularProgressbar-trail {
    stroke: transparent;
  }
  .CircularProgressbar .CircularProgressbar-path {
    transition: stroke-dashoffset 1.5s ease 0s
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    max-width: 200px;
  }
  @media screen and (max-width: 767.98px) {
    max-width: 207px;
    margin: 20px auto 0;
    .text-max-out {
      color: #9f9f9f;
      text-align: center;
      font-size: 15px;
      margin: 20px -30px 0;
      span {
        font-size: 24px;
        font-weight: 500;
        color: #fff;
      }
    }
  }
`;
export const MaxOutInfo = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -100px;
  margin-left: -100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background: url("./img/Dashboard/bg_maxout_inner.png") no-repeat center / 100%
    auto;
  & > div {
    position: relative;
    margin-bottom: 12px;
    &::before {
      content: "";
      position: absolute;
      left: 50%;
      margin-left: -30px;
      bottom: -6px;
      width: 60px;
      height: 3px;
      border-radius: 10px;
      background-color: #bdc6be;
    }
    & > p {
      text-align: center;
      &:nth-child(1) {
        color: #22c544;
        font-size: 33px;
        font-weight: 700;
      }
      &:nth-child(2) {
        font-size: 16px;
        background: linear-gradient(180deg, #bbf7c6 0%, #8f9790 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
  & > span {
    color: #858585;
    text-align: center;
    font-size: 12px;
  }
  @media (min-width: 768px) and (max-width: 1600px) {
    width: 150px;
    height: 150px;
    margin-top: -75px;
    margin-left: -75px;
  }
  @media screen and (max-width: 767.98px) {
    width: 166px;
    height: 166px;
    margin-top: -116px;
    margin-left: -84px;
  }
`;
export const DashboardMaxOutMain = styled.div`
  margin-bottom: 20px;
  ${BlockFrame} {
    min-height: 426px;
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 25px;
  }
`;
export const MaxoutLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  p {
    color: #9f9f9f;
    font-size: 13px;
    span {
      color: #fff;
      font-size: 21px;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    gap: 20px;
  }
`;

export const DashboardBottom = styled.div`
  display: flex;
  gap: 12px;
  @media (min-width: 1024px) and (max-width: 1171px) {
    flex-wrap: wrap;
  }
  @media (min-width: 768px) and (max-width: 1600px) {
    .block-ranked {
      max-width: calc(100% - 30px) !important;
    }
    .block-earning {
      max-width: calc(100% - 30px) !important;
    }
  }
  @media screen and (max-width: 767.98px) {
    display: block;
  }
`;
export const DashboardBalance = styled.div`
  width: 100%;
  @media (min-width: 768px) and (max-width: 1600px) {
    max-width: unset;
  }
  @media (min-width: 1024px) and (max-width: 1171px) {
    max-width: calc(100% - 488px);
  }
  @media screen and (max-width: 767.98px) {
    width: auto;
    margin-bottom: 25px;
  }
`;
export const BalanceHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 15px;
  ${TitleWrapper} {
    margin-bottom: 0;
  }
`;
export const BalanceBlock = styled.div`
  & > p {
    color: #9f9f9f;
    font-size: 13px;
    line-height: 120%;
    margin-bottom: 52px;
    padding-left: 23px;
    span {
      font-size: 15px;
      color: #fff;
    }
  }
  @media screen and (max-width: 1024px) {
    & > p {
      margin-bottom: 15px;
    }
  }
`;
export const BalanceBlockHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  h2 {
    font-size: 30px;
    color: #9f9f9f;
    span {
      background: linear-gradient(180deg, #ceffd1 0%, #4ec575 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-left: 5px;
    }
  }
  & > div {
    text-align: right;
    p {
      font-size: 13px;
      color: #9f9f9f;
    }
    span {
      color: #ff5959;
      text-align: right;
      font-size: 15px;
      font-weight: 500;
      line-height: 120%;
    }
  }
`;
export const BalanceToken = styled.div`
  position: relative;
  border-radius: 8px;
  /* min-height: 358px; */
  overflow: hidden;
  /* &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background: url("./img/Gif/token_bg.gif") no-repeat center / cover;
    width: 100%;
    height: 100%;
    transform: rotate(-180deg);
    pointer-events: none;
  } */
  /* & > div {
    text-align: center;
    position: relative;
    p {
      color: #9f9f9f;
      font-size: 15px;
    }
    span {
      color: #fff;
      font-size: 32px;
      font-weight: 500;
    }
  } */
  @media screen and (max-width: 1024px) {
    min-height: 247px;
    padding: 0;
  }
`;
export const BalanceListToken = styled.ul`
  li {
    width: 100%;
    min-height: 177px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 8px;
    position: relative;
    z-index: 1;
    &:before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: url("/img/Dashboard/balance_bg.png") center / cover no-repeat;
      pointer-events: none;
      z-index: -1;
    }
    &:not(:last-child) {
      margin-bottom: 8px;
    }
    &:nth-child(1) {
      background: linear-gradient(
        180deg,
        rgba(66, 168, 147, 0.61) 0%,
        #3da08a 100%
      );
    }
    &:nth-child(2) {
      background: var(
        --Green-Stroke,
        linear-gradient(180deg, #6ea380 0%, #1f1f1f 100%)
      );
    }
    .text-balance {
      color: #fff;
      text-align: center;
      font-size: 15px;
    }
    .content-number {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
    }
    .text-number {
      display: block;
      color: #fff;
      text-align: center;
      font-size: 32px;
      font-weight: 500;
    }
    img {
      width: 38px;
      height: 38px;
      object-fit: cover;
    }
    .last-text {
      color: #f0fdf2;
      text-align: center;
      font-size: 13px;
      span {
        color: #5fff59;
        font-size: 15px;
        font-weight: 500;
        &.decreasing {
          color: rgb(246, 70, 93);
        }
      }
    }
  }
`;
export const EarningHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
  ${TitleWrapper} {
    margin-bottom: 0;
  }
`;
export const LevelUpBlock = styled.div``;
export const LevelUpTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 50px;
  & > div {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  @media screen and (max-width: 767.98px) {
    margin-bottom: 10px;
    figure {
      width: 30px;
      height: 30px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    & > div {
      p {
        font-size: 12px;
      }
    }
  }
`;
export const LevelUpContent = styled.div`
  width: 340px;
  margin: 0 auto;
  .cards {
    .swiper-wrapper .swiper-slide {
      padding: 20px;
      width: 296px !important;
      height: 384px;
      border-radius: 18px;
      border: 1px solid #73ffa9;
      background: linear-gradient(
        180deg,
        rgba(140, 202, 157, 0.4) 0%,
        rgba(39, 44, 47, 0.4) 100%
      );
      backdrop-filter: blur(11.820679664611816px);
    }
  }
  ${ButtonCommon} {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    padding: 0;
  }
  @media screen and (max-width: 767.98px) {
    width: 280px;
    .cards {
      .swiper-wrapper .swiper-slide {
        width: 215px !important;
        height: 280px;
        margin: 0 auto;
        background: rgba(140, 202, 157, 1);
      }
    }
    ${ButtonCommon} {
      width: 30px;
      height: 30px;
      img {
        width: 14px;
        height: 14px;
      }
    }
  }
`;
export const LevelCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;
export const LevelContent = styled.div`
  h2 {
    color: #fff;
    font-size: 22px;
    font-weight: 500;
    line-height: 120%;
    margin-bottom: 5px;
  }
  span {
    display: block;
    color: #9e9e9e;
    font-size: 14px;
    margin-bottom: 30px;
  }
  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 8px;
    border-radius: 8px;
    background: #fff;
    p {
      color: #000;
      font-size: 22px;
      font-weight: 600;
    }
  }
  @media screen and (max-width: 767.98px) {
    h2 {
      font-size: 14px;
    }
    span {
      font-size: 12px;
      margin-bottom: 10px;
      color: #000;
    }
    div {
      p {
        font-size: 14px;
      }
    }
  }
`;
export const LevelWrapper = styled.div`
  position: relative;
  max-width: 450px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &::before {
    content: "";
    position: absolute;
    background: url("./img/Common/level/level_decor.png") no-repeat center / 100% auto;
    width: 445px;
    height: 207px;
    bottom: -20px;
    left: 0;
    pointer-events: none;
  }
  ${MiningReward} {
    & > div {
      width: 125px;
      justify-content: center;
    }
  }
`
export const LevelHeader = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  figure {
    width: 37px;
    height: 35px;
  }
  & > div {
    p {
      font-size: 21px;
    }
    span {
      font-size: 17px;
    }
  }
`
export const LevelBody = styled.div`
  figure {
    width: 165px;
    height: 156px;
    margin: 0 auto 20px;
  }
`
export const LevelInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  & > div {
    &:nth-child(2) {
      text-align: right;
    }
    p {
      font-size: 15px;
      margin-bottom: 2px;
    }
    span {
      font-size: 20px;
      font-weight: 500;
    }
  }
`
export const PriceUsd = styled.p`
  color: rgb(159, 159, 159) !important;
  font-size: 14px !important;
`