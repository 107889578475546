import styled from "styled-components";

export const ButtonCommon = styled.button`
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 4px 20px 4px 4px;
  width: max-content;
  cursor: pointer;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  &:disabled {
    cursor: not-allowed;
    opacity: .5;
  }
  p {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    font-size: 16px;
    margin-right: 10px;
    &.gray {
      border-radius: 10px; 
      border: 2px solid #F1F1F1;
      background: rgba(18, 18, 18, 0.54);
      box-shadow: 0px 0px 13px 0px rgba(248, 248, 248, 0.60) inset, 0px 13px 39px -26px rgba(0, 0, 0, 0.40);
    }
  }
  &.yellow {
    border: 1px solid #f7e7bb;
    background: radial-gradient(
        105.75% 89.98% at 2.11% 14.58%,
        rgba(238, 187, 129, 0) 0%,
        rgba(255, 233, 70, 0.2) 100%
      ),
      rgba(35, 35, 35, 0.5);
    box-shadow: 0px 0px 8px 0px rgba(248, 248, 248, 0.6) inset,
      0px 8px 24px -16px rgba(0, 0, 0, 0.4);
    p {
      border: 1px solid rgba(255, 204, 0, 0.12);
      background: rgba(31, 20, 0, 0.54);
      box-shadow: 0px 0px 8px 0px rgba(248, 248, 248, 0.6) inset;
    }
  }
  &.green {
    border: 1px solid #bbf7c6;
    background: radial-gradient(
        105.75% 89.98% at 2.11% 14.58%,
        rgba(129, 238, 151, 0) 0%,
        rgba(129, 238, 151, 0.2) 100%
      ),
      rgba(35, 35, 35, 0.5);
    box-shadow: 0px 0px 8px 0px rgba(248, 248, 248, 0.6) inset,
      0px 8px 24px -16px rgba(0, 0, 0, 0.4);
    p {
      border: 1px solid #bbf7c6;
      background: rgba(18, 18, 18, 0.54);
      box-shadow: 0px 0px 8px 0px rgba(248, 248, 248, 0.6) inset,
        0px 8px 24px -16px rgba(0, 0, 0, 0.4);
    }
  }
  &.shadow {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    border: 1px solid #bbf7c6;
    background: rgba(18, 18, 18, 0.54);
    box-shadow: 0px 0px 8px 0px rgba(248, 248, 248, 0.6) inset,
      0px 8px 24px -16px rgba(0, 0, 0, 0.4);
  }
  &.green-light {
    border-radius: 12px;
    border: 1px solid #549285;
    background: #181818;
    box-shadow: 0px 0px 7.191px 0px rgba(248, 248, 248, 0.6),
      0px 7.191px 21.574px -14.383px rgba(0, 0, 0, 0.4);
  }
  &.gray {
    border-radius: 12px;
    border: 1px solid #525252;
    background: #181818;
  }
  &.gray-shadow {
    border-radius: 13px;
    border: 2px solid #FBFBFB;
    background: radial-gradient(105.75% 89.98% at 2.11% 14.58%, rgba(250, 250, 250, 0.00) 0%, rgba(225, 225, 225, 0.20) 100%), rgba(35, 35, 35, 0.50);
    box-shadow: 0px 0px 13.058px 0px rgba(248, 248, 248, 0.60) inset, 0px 13.058px 39.174px -26.116px rgba(0, 0, 0, 0.40);
  }
  &.remove {
    border-radius: 8px;
    border: 1px solid #ff5050;
    background: radial-gradient(
        105.75% 89.98% at 2.11% 14.58%,
        rgba(238, 129, 129, 0) 0%,
        rgba(238, 129, 129, 0.2) 100%
      ),
      rgba(35, 35, 35, 0.5);
    box-shadow: 0px 0px 8px 0px rgba(248, 248, 248, 0.6) inset,
      0px 8px 24px -16px rgba(0, 0, 0, 0.4);
  }
  @media screen and (max-width: 767.98px) {
    padding: 4px;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.yellow {
      padding: 0;
      border-radius: 6px;
      border: 1px solid rgba(255, 204, 0, 0.12);
      background: rgba(31, 20, 0, 0.54);
      box-shadow: 0px 0px 8px 0px rgba(248, 248, 248, 0.6) inset;
      p {
        box-shadow: none;
        background: unset;
        border: 0;
        padding: 0;
        margin-right: 0;
      }
    }
    &.green {
      width: unset;
      height: unset;
      p {
        padding: 7px;
      }
    }
    &.shadow {
      padding: 0;
    }
  }
`;
export const BlockFrame = styled.div`
  padding: 16px 12px;
  border-radius: 8px;
  border: 1px solid #3d3d3d;
  background: linear-gradient(180deg, #0f1112 0%, #151617 100%);
  overflow: hidden;
`;
export const StatusTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 4px 8px;
  border-radius: 4px;
  width: max-content;
  min-width: 82px;
  p {
    color: #000;
    font-size: 12px;
    font-weight: 500;
  }
  &.success {
    background: linear-gradient(220deg, #8dffcf 11.31%, #19fb9b 83.28%);
    box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.25) inset;
  }
  &.error {
    background: linear-gradient(220deg, #ff8d8d 11.31%, #fb1919 83.28%);
    box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.25) inset;
  }
`;
export const TextHelper = styled.div`
  margin: 0 auto 30px;
  p {
    color: #9f9f9f;
    font-size: 13px;
  }
  h2 {
    color: #fff;
    font-size: 24px;
    font-weight: 600;
  }
`;
export const CheckboxCommon = styled.div`
  .ant-checkbox-wrapper {
    .ant-checkbox-inner {
      background-color: transparent;
      border-color: #575757;
    }
    & > span {
      &:nth-child(2) {
        color: #fff;
      }
    }
  }
`