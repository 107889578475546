import styled from "styled-components";
import { ButtonCommon } from "../../../Layout/styled";
import { HintHeader } from "../../../components/Common/Hint/styled";
import { SelectContainer } from "../../../components/Common/Select/styled";

export const LiquidityAddWrapper = styled.div`
  width: 100%;
`;
export const LiquidityFrame = styled.div`
  margin-bottom: 15px;
`;
export const LiqFrame = styled.div`
  position: relative;
  padding: 20px;
  border-radius: 6px;
  background: rgba(97, 97, 97, 0.22);
  &:not(:last-child) {
    margin-bottom: 2px;
    &::before {
      content: "";
      position: absolute;
      background: url("./img/Common/arrow_down.png") no-repeat center / 100%
        auto;
      width: 36px;
      height: 36px;
      left: 50%;
      margin-left: -18px;
      bottom: -18px;
      z-index: 5;
    }
  }
  & > div {
    &:nth-child(2) {
      text-align: right;
      p {
        color: #9f9f9f;
        font-size: 13px;
      }
      span {
        color: #fff;
        font-size: 15px;
        font-weight: 500;
      }
    }
  }
  @media screen and (max-width: 767px) {
    padding: 16px;
  }
`;
export const LiqFrameHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 15px;
  & > div {
    p {
      color: #9f9f9f;
      font-size: 13px;
    }
    span {
      color: #fff;
      font-size: 15px;
      font-weight: 500;
    }
    &:nth-child(1) {
      text-align: left;
    }
    &:nth-child(2) {
      text-align: right;
    }
  }
  ${SelectContainer} {
    width: 140px;
    .ant-select-selector .ant-select-selection-item div img {
      width: 35px;
      height: 35px;
    }
    .ant-select-selector .ant-select-selection-item div p,
    .ant-select-dropdown .ant-select-item-option-content div p {
      color: #fff;
    }
  }
`;
export const LiqPerList = styled.ul`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 15px;
  margin-bottom: 15px;
  & > li {
    width: 25%;
    display: flex;
    padding: 4px 8px;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 1px solid rgba(75, 75, 75, 0.4);
    background: rgba(40, 40, 40, 0.7);
    background-blend-mode: luminosity;
    box-shadow: 2px 4px 16px 0px rgba(248, 248, 248, 0.06) inset;
    backdrop-filter: blur(50px);
    cursor: pointer;
  }
`;
export const LiquidityInfo = styled.div`
  margin-bottom: 10px;
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    p {
      display: flex;
      align-items: center;
      gap: 8px;
      color: #f0fdf2;
      font-size: 16px;
      font-weight: 500;
      img {
        cursor: pointer;
      }
    }
    ${ButtonCommon} {
      padding: 7px;
    }
  }
  @media screen and (max-width: 1023px) {
    & > div {
      justify-content: space-between;
    }
    ${HintHeader} {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }
`;
export const LiquidityActions = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
  ${ButtonCommon} {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    &:nth-child(1) {
      width: 35%;
    }
    &:nth-child(2) {
      width: 65%;
    }
  }
  @media screen and (max-width: 1023px) {
    width: 100%;
    ${ButtonCommon} {
      &:nth-child(1) {
        padding-top: 3px;
        padding-bottom: 3px;
        min-height: 44px;
      }
    }
  }
`;
export const LiquidityQuote = styled.div`
  margin-bottom: 20px;
  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    font-size: 14px;
    &:not(:last-child) {
      margin-bottom: 12px;
    }
    p {
      color: #c8c8c8;
    }
    span {
      color: #dadada;
      font-weight: 500;
    }
  }
`;
