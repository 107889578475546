import styled from "styled-components";

export const DashboardBody = styled.div`
  padding: 10px 20px;
  background-color: #191919;
  min-height: 100vh;
  &.bot {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
  @media screen and (max-width: 767.98px) {
    padding: 0;
    min-height: unset;
  }
`;
export const DashboardBodyWrapper = styled.div`
  position: relative;
  background: #111015;
  border-radius: 16px;
  padding: 20px;
  min-height: 100vh;
  overflow: hidden;
  &.bg {
    background: #000000;
    &::before,
    &::after {
      content: "";
      position: absolute;
    }
    &::before {
      top: -400px;
      left: 0;
      background: url("./img/Gif/overview_bg.gif") no-repeat center / cover;
      width: 100%;
      height: 100%;
      mix-blend-mode: soft-light;
      opacity: 0.5;
      border-radius: 50%;
      z-index: 5;
    }
    &::after {
      width: 70%;
      height: 70%;
      left: 50%;
      margin-left: -35%;
      top: -410px;
      background: radial-gradient(
          50% 50% at 50% 50%,
          rgba(0, 0, 0, 0) 0%,
          #000 100%
        ),
        url(<path-to-image>) lightgray 50% / cover no-repeat;
      opacity: 0.5;
    }
  }
  &.bot {
    padding-left: 0;
    padding-right: 0;
  }
  @media screen and (max-width: 1023px) {
    min-height: calc(100vh - 140px);
    padding: 20px 10px;
    border-radius: 0;
    background-color: #000;
  }
`;

export const BorderAnimation = styled.div`
  position: absolute;
  inset: 0;
  border-radius: 8px;
  filter: drop-shadow(0 0 15px #f1e20d);
  opacity: 0.5;
  animation: RotateLine 12s linear infinite, borderOpacity 3s linear infinite;
  pointer-events: none;
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 8px;
    padding: 2px;
    background: conic-gradient(
      from calc(var(--angle) + var(--start-angle)),
      transparent 0,
      #f1e20d 40%,
      transparent 45%
    );
    -webkit-mask: linear-gradient(#38943b 0 0) content-box,
      linear-gradient(#38943b 0 0);
    mask: linear-gradient(#38943b 0 0) content-box, linear-gradient(#38943b 0 0);
    mask-composite: xor;
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    animation: RotateLine 2s linear infinite, borderOpacity 3s linear infinite;
  }
  @keyframes RotateLine {
    to {
      --angle: 360deg;
    }
  }
  @keyframes borderOpacity {
    0% {
      opacity: 0.75;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.75;
    }
  }
`;
