import { ProfileWrapper } from "./styled";

const ProfileCommon = ({ avatar, name, subName }: any) => {
  return (
    <ProfileWrapper>
      <figure
        style={{
          background: avatar === "" ? "#fff" : "",
        }}
      >
        {avatar === "" ? (
          <></>
        ) : (
          <img
            width={37}
            height={36}
            src={avatar}
            alt="avatar"
            loading="lazy"
          />
        )}
      </figure>
      <div>
        <p>{name}</p>
        <span>{subName}</span>
      </div>
    </ProfileWrapper>
  );
};

export default ProfileCommon;
