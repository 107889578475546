import styled from "styled-components";

export const PoolPlanContainer = styled.div`
`
export const PoolPlanWrapper = styled.div``
export const BlockPlan = styled.div`
  border-radius: 8px;
  border: 1px solid var(--Gray-Stroke, #3D3D3D);
  background: linear-gradient(180deg, #0F1112 0%, #151617 100%);
  padding: 16px 12px;
  position: relative;
  z-index: 1;
  .title-plan {
    color: var(--Neutral-White, #FFF);
    font-family: Rubik;
    font-size: 24px;
    font-weight: 500;
  }
  .text-plan {
    color: var(--Sub-light-text, #9F9F9F);
    font-family: Rubik;
    font-size: 13px;
    margin-bottom: 20px;
  }

  //talbe
  .ant-table-container {
    .ant-table-cell {
      width: calc(100% / 6);
    }
  }
  .ant-table-thead {
    .ant-table-cell {
      &:nth-child(6) {
        text-align: center;
      }
    }
  }
  .ant-table-tbody {
    .ant-table-cell {
      &:nth-child(6) {
        text-align: center;
      }
    }
  }
  .table-action {
    outline: none;
    background: unset;
    border: none;
    color: var(--Neutral-White, #FFF);
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    border-radius: 6px;
    border: 1px solid var(--stroke-25-button, rgba(255, 255, 255, 0.40));
    background: var(--Surface-Background, rgba(40, 40, 40, 0.70));
    background-blend-mode: luminosity;
    box-shadow: 2px 4px 16px 0px rgba(248, 248, 248, 0.06) inset;
    backdrop-filter: blur(50px);
    padding: 10px 27px;
    cursor: pointer;
    font-family: Rubik;
    &:disabled {
      opacity: .5;
      cursor: not-allowed;
    }
    &.locked {
      border-radius: 6px;
      border: 1px solid var(--stroke-25-button, rgba(255, 255, 255, 0.40));
      background: #1C1D1E;
      background-blend-mode: luminosity;
      box-shadow: 2px 4px 16px 0px rgba(248, 248, 248, 0.06) inset;
      backdrop-filter: blur(50px);
    }
  }
  .table-text {
    &.is-color {
      color: var(--Mint-Green-300, #81EE97);
    }
  }

  //table mobile
  @media screen and (max-width: 1024px) {
    .ant-table-container {
      .ant-table-cell {
        width: 100%;
        border-radius: 10px;
      }
    }
    .ant-table-row {
      display: table-row;
    }
    .block-row {
      position: relative;
      transition: all 0.26s ease;
      position: relative;
      min-height: 92px;
      &:after {
        position: absolute;
        content: "";
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: linear-gradient(
          180deg,
          rgba(99, 255, 128, 0.19) 0%,
          rgba(53, 131, 67, 0.19) 100%
        );
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.26s ease;
      }
      &.is-active {
        .more {
          img {
            transform: rotate(90deg);
          }
        }
      }
      &.leaved {
        cursor: not-allowed;
        opacity: .5;
        pointer-events: none;
      }
      .total-staking {
        height: 0;
        transition: all 0.26s ease;
      }
      .show-detail {
        width: 34px;
        height: 34px;
        display: block;
        position: absolute;
        right: 10px;
        top: 58%;
        right: 5px;
        transform: translateY(-50%);
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .block-row-heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
      }
      .name {
        font-size: 16px;
      }
      .more {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 15px;
        img {
          width: 24px;
          height: 24px;
        }
      }
      .total-inner {
        position: relative;
        padding: 5px;
        display: flex;
        align-items: center;
        gap: 10px;
        border-radius: 9px;
        border: 1px solid var(--stroke-25-button, rgba(255, 255, 255, 0.4));
        background: var(--Surface-Background, rgba(40, 40, 40, 0.7));
        background-blend-mode: luminosity;
        box-shadow: 2px 4px 16px 0px rgba(248, 248, 248, 0.06) inset;
        backdrop-filter: blur(50px);
        &.is-button {
          padding: 0;
        }
      }
      .text-total {
        border-radius: 6px;
        border: 1.5px solid rgba(255, 204, 0, 0.12);
        background: rgba(31, 31, 31, 0.54);
        box-shadow: 0px 0px 8px 0px rgba(248, 248, 248, 0.6) inset;
        width: 125px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
      }
      .number-staking {
        width: calc(100% - 135px);
        display: block;
        color: var(--Neutral-White, #FFF);
        text-align: center;
        font-family: Rubik;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
      }
      .block-content {
        .total-inner {
          &:not(:last-child) {
            margin-bottom: 4px;
          }
        }
      }
      .button-lock {
        color: var(--Neutral-White, #FFF);
        text-align: center;
        font-family: Rubik;
        font-size: 16px;
        font-weight: 500;
        outline: none;
        background: unset;
        border: none;
        width: 100%;
        padding: 11px;
      }
    }
  }
`