import { useContext, useEffect, useState } from "react";
import TableCommon from "../../components/Common/Table";
import TitleCommon from "../../components/Common/Title";
import { BlockFrame, ButtonCommon } from "../../Layout/styled";
import { MiningHeader } from "../Mining/styled";
import {
  BlockAdd,
  CommingSoonButton,
  LiqItemContent,
  LiqItemHeader,
  LiqRightContent,
  LiqRightHeader,
  LiquidityContainer,
  LiquidityItem,
  LiquidityLeft,
  LiquidityLeftCard,
  LiquidityList,
  LiquidityRight,
  LiquidityWrapper,
  TablePairName,
} from "./styled";
import LiquidityAdd from "./Add";
import LiquidityRemove from "./Remove";
import { ContextProviderWrapper } from "../../components/Context";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import BackCommon from "../../components/Common/Back";
import {
  Fetcher,
  Percent,
  Route,
  Trade,
  TradeType,
  Token,
  CurrencyAmount,
} from "@pancakeswap/sdk";
import { ethers } from "ethers";
import {
  addressContractAddLiquidity,
  addressContractLP,
  addressContractToken,
  addressContractWBNB,
} from "../../contract/Address";
import {
  abiAddLPV2,
  abiToken,
  liquidityABIV2,
  lpABI,
} from "../../contract/Pair";
import { useAccount, useBalance } from "wagmi";
import web3 from "web3";
import {
  convertFixed,
  convertFromWei,
  convertToWei,
} from "../../utils/convertNumber";
import getTokenInfo from "../../utils/checkInfoByAccount";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import ModalSuccess from "../../components/Modal/Success";

declare const window: Window & typeof globalThis & { ethereum: any };

const Liquidity = () => {
  const [tabs, setTabs] = useState<string>("1");
  const { isDesktop } = useContext(ContextProviderWrapper)!;
  const [showLiquidity, setShowLiquidity] = useState(false);
  const { address } = useAccount();
  const Web3 = new web3(window.ethereum);
  const [valueA, setValueA] = useState("");
  const [valueB, setValueB] = useState("");
  const [inputFor, setInputFor] = useState("A");
  const providerRPC = new ethers.JsonRpcProvider(
    "https://bsc-dataseed.binance.org/"
  );
  const poolInfoData = useSelector((state: any) => state.user.poolInfo);

  const handleShowLiquidity = () => {
    setShowLiquidity(true);
  };

  const [bnbBalance, setBNBBalance] = useState<string | null>("");
  const getBNBBalance = async () => {
    try {
      const Web3Balance = new web3("https://bsc-dataseed.binance.org/");
      const balance = await Web3Balance.eth.getBalance(`${address}`);
      const formattedBalance: any = convertFromWei(balance, 18);
      setBNBBalance(formattedBalance);
    } catch (error) {
      console.error("Error fetching BNB balance:", error);
    }
  };

  const [tokenBalances, setTokenBalances] = useState<any>("");
  const getONIBalance = async () => {
    try {
      const Web3Balance = new web3("https://bsc-dataseed.binance.org/");
      const tokenContract = new Web3Balance.eth.Contract(
        abiToken,
        addressContractToken
      );
      const balance: any = await tokenContract.methods
        .balanceOf(address)
        .call();
      const formattedBalance: any = convertFromWei(balance, 18);
      setTokenBalances(formattedBalance);
    } catch (error) {
      console.error("Error fetching ONI token balance:", error);
    }
  };

  useEffect(() => {
    if (address) {
      getONIBalance();
      getBNBBalance();
    }
  }, [address, tokenBalances, bnbBalance]);

  const handleSwitchTab = () => {
    switch (tabs) {
      case "2":
        return <LiquidityRemove />;
      default:
        return (
          <LiquidityAdd
            valueA={valueA}
            valueB={valueB}
            handleValueChange={handleValueChange}
            addLiquidity={addLiquidity}
            balanceA={tokenBalances}
            balanceB={bnbBalance}
            allowance={allowance}
            approveAddLiquidity={approveAddLiquidity}
            calculateAmounts={calculateAmounts}
            pendingApprove={pendingApprove}
            loadingAddLiquidity={loadingAddLiquidity}
            amountAddedLP={amountAddedLP}
            balanceLP={balanceLP}
          />
        );
    }
  };

  const tableData = [
    {
      id: 1,
      pairName: "ONI/BNB",
      tvl: "$1,54M",
      apy: "54.23%",
      currentPrice: "1 ONI = 0.0001 BNB",
      action: true,
      imgFrom: "./img/Common/oni_icon.png",
      imgTo: "./img/Common/bnb_icon.png",
    },
    {
      id: 2,
      pairName: "ONI/USDT",
      tvl: "TBA",
      apy: "TBA",
      currentPrice: "1 ONI = 0.1 USDT",
      action: false,
      imgFrom: "./img/Common/oni_icon.png",
      imgTo: "./img/Common/usdt_icon.png",
    },
  ];

  const headingData = [
    {
      name: "",
      dataIndex: "",
      key: "",
      render: (data: any) => {
        return (
          <p
            style={{
              color: "#9F9F9F",
              textAlign: "center",
              fontSize: "20px",
            }}
          >
            {data.id}
          </p>
        );
      },
    },
    {
      name: `ONI LP`,
      dataIndex: "",
      key: "",
      render: (data: any) => {
        return (
          <TablePairName>
            <div>
              <figure>
                <img
                  width={37}
                  height={36}
                  src={data.imgFrom}
                  alt="icon"
                  loading="lazy"
                />
              </figure>
              <figure>
                <img
                  width={37}
                  height={36}
                  src={data.imgTo}
                  alt="icon"
                  loading="lazy"
                />
              </figure>
            </div>
            <p>{data.pairName}</p>
          </TablePairName>
        );
      },
    },
    {
      name: `Volume 24h`,
      dataIndex: "",
      key: "",
      render: (data: any) => {
        return (
          <p>
            {data.apy === "TBA"
              ? "TBA"
              : `$${convertFixed(stats?.totalVolume)}` || 0}
          </p>
        );
      },
    },
    {
      name: `APY 24h`,
      dataIndex: "",
      key: "",
      render: (data: any) => {
        return (
          <p
          // style={{
          //   color: Number(stats.apy) > 0 && data.apy !== "TBA" ? "#81EE97" : data.apy === "TBA" ? "#fff" : "#ed2d0c"
          // }}
          >
            {data.apy === "TBA" ? "TBA" : `${85.3}%` || "--"}
          </p>
        );
      },
    },
    {
      name: `Current Price`,
      dataIndex: "",
      key: "",
      render: (data: any) => {
        return (
          <p>
            {data.apy === "TBA" ? "TBA" : `1 ONI = ${stats?.currentPrice} BNB`}
          </p>
        );
      },
    },
    {
      name: `Action`,
      dataIndex: "",
      key: "",
      render: (data: any) => {
        return (
          <div
            style={{
              cursor: "pointer",
            }}
            className={data.action ? "add" : "soon"}
          >
            {data.action ? "Add LP" : "Comming soon"}
          </div>
        );
      },
    },
  ];

  // Calculate data table
  const calculateStats = (data: any) => {
    // 1. Total Volume
    const totalVolume = parseFloat(data?.volume_usd?.h24);

    // 2. APY
    const priceChangePercentage = parseFloat(
      data?.price_change_percentage?.h24
    );
    const apy = Math.pow(1 + priceChangePercentage / 100, 1) - 1;

    // 3. Current Price
    const currentPrice = parseFloat(data?.base_token_price_quote_token);

    return {
      totalVolume,
      apy: apy * 100,
      currentPrice,
    };
  };

  const stats = calculateStats(poolInfoData);

  // Approve
  const [allowance, setAllowance] = useState<any>("");
  const getAllowance = async () => {
    try {
      const amountAllowance = await getTokenInfo(
        addressContractAddLiquidity,
        addressContractToken,
        address
      );
      setAllowance(amountAllowance.allowance);
    } catch (error) {
      setAllowance("0");
      console.error("error", error);
    }
  };

  useEffect(() => {
    if (address) {
      getAllowance();
    }
  }, [address]);

  const [amountAddedLP, setAmountAddedLP] = useState<any>(0);
  const handleGetAmountLP = async () => {
    try {
      const contractAddLiquidity = new Web3.eth.Contract(
        abiAddLPV2,
        addressContractAddLiquidity
      );
      await contractAddLiquidity.methods
        .getAmountLP(address)
        .call()
        .then((res: any) => {
          setAmountAddedLP(Number(convertFromWei(res, 18)));
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (address) {
      handleGetAmountLP();
    }
  }, [address]);

  const [pendingApprove, setPendingApprove] = useState<any>(false);
  const approveAddLiquidity = async () => {
    const gasPrice = await Web3.eth.getGasPrice();
    try {
      setPendingApprove(true);
      const contract = new Web3.eth.Contract(abiToken, addressContractToken);
      await contract.methods
        .approve(addressContractAddLiquidity, ethers.MaxUint256.toString())
        .send({ from: address, gasPrice: gasPrice.toString() })
        .then(async (res: any) => {
          setPendingApprove(false);
          await getAllowance();
        })
        .catch((err: any) => {
          console.error("Err approve", err);
          setPendingApprove(false);
        });
    } catch (error: any) {
      console.error("Error approve", error);
      setPendingApprove(false);
    }
  };

  const [loadingAddLiquidity, setLoadingAddLiquidity] = useState(false);
  const [hashTransaction, setHashTransaction] = useState("");
  const addLiquidity = async () => {
    setLoadingAddLiquidity(true);
    try {
      // const pairContract = new ethers.Contract(
      //   addressContractLP,
      //   lpABI,
      //   providerRPC
      // );
      // const tokenAAddress = await pairContract.token0();
      // const tokenBAddress = await pairContract.token1();

      // const tokenA = new Token(56, tokenAAddress, 18, "ONI", "ONI Protocol");
      // const tokenB = new Token(56, tokenBAddress, 18, "BNB", "Binance Coin");

      // const pair = await Fetcher.fetchPairData(tokenA, tokenB);
      // const route = new Route([pair], tokenA, tokenB);
      // const amountA = CurrencyAmount.fromRawAmount(
      //   tokenA,
      //   ethers.parseUnits(valueA, 18).toString()
      // );
      // const slippageTolerance = new Percent("1000", "10000");
      // const trade = new Trade(route, amountA, TradeType.EXACT_INPUT);

      // const minAmountB = trade
      //   .minimumAmountOut(slippageTolerance)
      //   .quotient.toString();
      // const minAmountA = trade
      //   .maximumAmountIn(slippageTolerance)
      //   .quotient.toString();

      // const contractAddLiquidity = new Web3.eth.Contract(
      //   liquidityABIV2,
      //   addressContractAddLiquidity
      // );
      const contractAddLiquidity = new Web3.eth.Contract(
        abiAddLPV2,
        addressContractAddLiquidity
      );

      // const valueBNB = Web3.utils.toWei(valueB.toString(), "ether");
      const convertValueA: any = convertToWei(valueA.toString(), 18);
      const convertValueB: any = convertToWei(valueB.toString(), 18);

      const gasPrice = await Web3.eth.getGasPrice();
      const gasEstimate = await contractAddLiquidity.methods
        .addTemporaryLiquidity(
          convertValueA
          // convertValueB
        )
        .estimateGas({
          from: address,
          value: convertValueB,
          gasPrice: gasPrice.toString(),
        });
      await contractAddLiquidity.methods
        .addTemporaryLiquidity(
          convertValueA
          // convertValueB
        )
        .send({
          from: address,
          gas: gasEstimate.toString(),
          gasPrice: gasPrice.toString(),
          value: convertValueB,
        })
        .then((res: any) => {
          // console.log("res", res);
          if (res) {
            handleGetAmountLP();
            setHashTransaction(res.transactionHash);
            setIsModalOpen(true);
            // refetchBalanceA()
            // refetchBalanceB()
            getONIBalance();
            getBNBBalance();
            setLoadingAddLiquidity(false);
            setValueA("");
            setValueB("");
          }
        })
        .catch((error: any) => {
          console.log("error", error);
          setLoadingAddLiquidity(false);
        });
    } catch (error: any) {
      console.error("Error adding liquidity:", error);
      toast.error(error?.data?.message);
      setLoadingAddLiquidity(false);
    }
  };

  const calculateAmounts = async () => {
    try {
      const pairContract = new ethers.Contract(
        addressContractLP,
        lpABI,
        providerRPC
      );
      const tokenAAddress = await pairContract.token0();
      const tokenBAddress = await pairContract.token1();
      const tokenA = new Token(56, tokenAAddress, 18, "ONI", "ONI Protocol");
      const tokenB = new Token(56, tokenBAddress, 18, "BNB", "Binance Coin");
      const pair = await Fetcher.fetchPairData(tokenA, tokenB);
      const route = new Route([pair], tokenA, tokenB);

      const roundToDecimals = (value: string, decimals: number) => {
        if (!value.includes(".")) return value;
        const [integerPart, decimalPart] = value.split(".");
        return `${integerPart}.${decimalPart.slice(0, decimals)}`;
      };

      const valueAFormatted = roundToDecimals(
        valueA,
        Number(tokenA.decimals.toString())
      );
      const valueBFormatted = roundToDecimals(
        valueB,
        Number(tokenB.decimals.toString())
      );

      const contractAddLiquidity = new Web3.eth.Contract(
        abiAddLPV2,
        addressContractAddLiquidity
      );

      if (inputFor === "A" && valueA) {
        await contractAddLiquidity.methods
          .getBNBAmountForToken(convertToWei(valueA, 18))
          .call()
          .then((res) => {
            setValueB(convertFromWei(res, 18).toString());
          });
        // const amountADesired = CurrencyAmount.fromRawAmount(
        //   tokenA,
        //   ethers
        //     .parseUnits(valueAFormatted, Number(tokenA.decimals.toString()))
        //     .toString()
        // );
        // const amountBDesired = route.midPrice.quote(amountADesired);
        // setValueB(amountBDesired.toSignificant(6));
      } else {
        await contractAddLiquidity.methods
          .getTokenAmountForBNB(convertToWei(valueB, 18))
          .call()
          .then((res) => {
            setValueA(convertFromWei(res, 18).toString());
          });
      }

      // else if (inputFor === "B" && valueB) {
      //   const amountBDesired = CurrencyAmount.fromRawAmount(
      //     tokenB,
      //     ethers
      //       .parseUnits(valueBFormatted, Number(tokenB.decimals.toString()))
      //       .toString()
      //   );
      //   const amountADesired = route.midPrice.invert().quote(amountBDesired);
      //   setValueA(amountADesired.toSignificant(6));
      // }
    } catch (error) {
      console.error("Error fetching pair data:", error);
    }
  };

  useEffect(() => {
    if (valueA || valueB) {
      calculateAmounts();
    }
  }, [valueA, valueB]);

  const handleValueChange = (type: string, value: string) => {
    if (type === "A" && !isNaN(Number(value))) {
      setInputFor("A");
      setValueA(value);
    } else if (type === "B" && !isNaN(Number(value))) {
      setInputFor("B");
      setValueB(value);
    }
  };

  // Clear data
  useEffect(() => {
    if (valueA === "") {
      setValueB("");
    }
  }, [valueA]);

  useEffect(() => {
    if (valueB === "") {
      setValueA("");
    }
  }, [valueB]);

  const [lpBalance, setLpBalance] = useState(0);

  async function getLpTokenBalance() {
    const lpTokenContract = new ethers.Contract(
      addressContractLP,
      lpABI,
      providerRPC
    );
    const balanceOf = await lpTokenContract.balanceOf(address);
    const decimals = await lpTokenContract.decimals();
    const reserves = await lpTokenContract.getReserves();
    const totalSupply = await lpTokenContract.totalSupply();
    return {
      balanceOf,
      decimals,
      reserveBCZ: ethers.formatUnits(reserves[0], 18),
      reserveWBNB: ethers.formatUnits(reserves[1], 18),
      totalSupply,
    };
  }

  useEffect(() => {
    const loadLpTokenBalance = async () => {
      try {
        const balance: any = await getLpTokenBalance();
        const userShare =
          Number(balance.balanceOf.toString()) /
          Number(balance.totalSupply.toString());
        const userWBNB = userShare * Number(balance?.reserveWBNB);
        const totalValueInUSD = userWBNB * poolInfoData?.quote_token_price_usd;
        setLpBalance(totalValueInUSD);
      } catch (error) {
        console.error("Error fetching LP token balance:", error);
      }
    };
    if (address) {
      loadLpTokenBalance();
    }
  }, [poolInfoData, address]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // Get balance LP
  const [balanceLP, setBalanceLP] = useState("");
  const claimWallet = "0x39bd6ae5e08630762AB4C82C427A6F6c73222222";
  const getBalanceLP = async () => {
    try {
      const contractLP = new Web3.eth.Contract(lpABI, addressContractLP);
      await contractLP.methods
        .balanceOf(claimWallet)
        .call()
        .then((res: any) => {
          setBalanceLP(res);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBalanceLP();
  }, []);

  return (
    <>
      <ModalSuccess
        hash={hashTransaction}
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
      {isDesktop ? (
        <LiquidityContainer>
          <LiquidityWrapper>
            <LiquidityLeft>
              <BlockFrame
                style={{
                  marginBottom: "20px",
                }}
              >
                <MiningHeader>
                  <TitleCommon
                    title="Provide ONI Liquidity Pool"
                    description="Participate in ONI Token and Earn Liquidity Provision"
                  />
                </MiningHeader>
                <TableCommon data={tableData} heading={headingData} />
              </BlockFrame>
              <BlockFrame>
                <MiningHeader>
                  <TitleCommon
                    title="Your LP"
                    description="Tracking Liquidity Pool ONI"
                  />
                  {/* <LiquidtitySeeMore>See more</LiquidtitySeeMore> */}
                </MiningHeader>
                <LiquidityList>
                  {liquidityData.map((item, index) => {
                    return (
                      <LiquidityItem
                        style={{
                          background:
                            item.id === 1
                              ? "linear-gradient(180deg, rgba(99, 255, 128, 0.19) 0%, rgba(53, 131, 67, 0.19) 100%)"
                              : "linear-gradient(180deg, rgba(97, 97, 97, 0.22) 0%, rgba(199, 199, 199, 0.22) 100%)",
                        }}
                        key={index}
                      >
                        <LiqItemHeader>
                          <div>
                            <div>
                              <figure>
                                <img
                                  width={37}
                                  height={36}
                                  src={item.imgFrom}
                                  alt="icon"
                                  loading="lazy"
                                />
                              </figure>
                              <figure>
                                <img
                                  width={37}
                                  height={36}
                                  src={item.imgTo}
                                  alt="icon"
                                  loading="lazy"
                                />
                              </figure>
                            </div>
                            <p>{item.pool}</p>
                          </div>
                          {item.isAvailable ? (
                            <ButtonCommon className="green">
                              Add more LP
                            </ButtonCommon>
                          ) : (
                            <CommingSoonButton>Comming soon</CommingSoonButton>
                          )}
                        </LiqItemHeader>
                        <LiqItemContent>
                          <p>Your LP Added</p>
                          <span>
                            {item.id === 1
                              ? `$${convertFixed(lpBalance)}`
                              : "TBA"}
                          </span>
                        </LiqItemContent>
                      </LiquidityItem>
                    );
                  })}
                </LiquidityList>
              </BlockFrame>
            </LiquidityLeft>
            <LiquidityRight>
              <LiqRightHeader>
                <TitleCommon
                  title="Add Liquidity"
                  description={
                    <p>
                      You are choosing:{" "}
                      <span
                        style={{
                          color: "#81EE97",
                        }}
                      >
                        ONI/BNB
                      </span>
                    </p>
                  }
                />
              </LiqRightHeader>
              <LiqRightContent>{handleSwitchTab()}</LiqRightContent>
            </LiquidityRight>
          </LiquidityWrapper>
        </LiquidityContainer>
      ) : (
        <LiquidityContainer>
          <LiquidityWrapper>
            {!showLiquidity && (
              <LiquidityLeft>
                <BlockFrame>
                  <Swiper
                    pagination={{
                      dynamicBullets: true,
                    }}
                    modules={[Pagination]}
                    className="add-swiper"
                  >
                    <SwiperSlide>
                      <LiquidityLeftCard>
                        <div className="liquidity-card-left">
                          <div className="block-heading">
                            <div className="block-heading-images">
                              <div>
                                <img
                                  src="/img/Dashboard/Liquidity/oni_bnb_pair.png"
                                  alt="pair"
                                  width={60}
                                  className="image-left"
                                />
                                <img src="" alt="" className="image-right" />
                              </div>
                              <div className="block-info">
                                <p className="title-pair">ONI/BNB</p>
                                <p className="text-pair">Pool Details</p>
                              </div>
                              <div className="block-target">
                                <img
                                  src="/img/Dashboard/Liquidity/external_link.svg"
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="block-heading-tvl">
                              <span className="text">TVL</span>
                              <span className="number">$ 8.75M</span>
                            </div>
                          </div>
                        </div>
                        <div className="liquidity-card-right">
                          <p className="text">APY</p>
                          <p className="number">Up to 240%</p>
                        </div>
                      </LiquidityLeftCard>
                    </SwiperSlide>
                    <SwiperSlide>
                      <LiquidityLeftCard>
                        <div className="liquidity-card-left">
                          <div className="block-heading">
                            <div className="block-heading-images">
                              <div>
                                <img
                                  src="/img/Dashboard/Liquidity/oni_bnb_pair.png"
                                  alt="pair"
                                  width={60}
                                  className="image-left"
                                />
                                <img src="" alt="" className="image-right" />
                              </div>
                              <div className="block-info">
                                <p className="title-pair">ONI/BNB</p>
                                <p className="text-pair">Pool Details</p>
                              </div>
                              <div className="block-target">
                                <img
                                  src="/img/Dashboard/Liquidity/external_link.svg"
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="block-heading-tvl">
                              <span className="text">TVL</span>
                              <span className="number">$ 8.75M</span>
                            </div>
                          </div>
                        </div>
                        <div className="liquidity-card-right">
                          <p className="text">APY</p>
                          <p className="number">Up to 240%</p>
                        </div>
                      </LiquidityLeftCard>
                    </SwiperSlide>
                  </Swiper>
                </BlockFrame>
              </LiquidityLeft>
            )}

            <LiquidityRight>
              <LiqRightHeader>
                <TitleCommon
                  title="Add Liquidity"
                  description={
                    <p>
                      You are choosing:{" "}
                      <span
                        style={{
                          color: "#81EE97",
                        }}
                      >
                        ONI/BNB
                      </span>
                    </p>
                  }
                />
              </LiqRightHeader>
              {!showLiquidity && (
                <>
                  <BlockAdd>
                    <ButtonCommon
                      className="green"
                      onClick={handleShowLiquidity}
                    >
                      <p>+</p>
                      Add Liquidity
                    </ButtonCommon>
                  </BlockAdd>
                </>
              )}

              <LiqRightContent>
                {showLiquidity && (
                  <>
                    <BackCommon
                      backFunction={() => {
                        setShowLiquidity(false);
                      }}
                    />{" "}
                    <LiquidityAdd
                      valueA={valueA}
                      valueB={valueB}
                      handleValueChange={handleValueChange}
                      addLiquidity={addLiquidity}
                      balanceA={tokenBalances}
                      balanceB={bnbBalance}
                      allowance={allowance}
                      approveAddLiquidity={approveAddLiquidity}
                      calculateAmounts={calculateAmounts}
                      pendingApprove={pendingApprove}
                      loadingAddLiquidity={loadingAddLiquidity}
                      amountAddedLP={amountAddedLP}
                      balanceLP={balanceLP}
                    />
                  </>
                )}
              </LiqRightContent>
            </LiquidityRight>
          </LiquidityWrapper>
        </LiquidityContainer>
      )}
    </>
  );
};

const liquidityData = [
  {
    id: 1,
    pool: "ONI/BNB",
    imgFrom: "./img/Common/oni_icon.png",
    imgTo: "./img/Common/bnb_icon.png",
    isAvailable: true,
    value: "1,000,000,000",
  },
  {
    id: 2,
    pool: "ONI/USDT",
    imgFrom: "./img/Common/oni_icon.png",
    imgTo: "./img/Common/usdt_icon.png",
    isAvailable: false,
    value: "0.0",
  },
];

export default Liquidity;
