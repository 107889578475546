import styled from "styled-components";
import { ButtonCommon } from "../../Layout/styled";

export const WithdrawContainer = styled.div``;
export const WithdrawWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 660px;
  max-width: 1360px;
  margin: 0 auto;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;
export const WithdrawLeft = styled.div`
  width: 50%;
  position: relative;
  &:before {
    position: absolute;
    content: "";
    left: -300px;
    top: -334px;
    background: radial-gradient(
      38.23% 38.23% at 50% 50%,
      rgba(113, 255, 137, 0.18) 0%,
      rgba(113, 255, 137, 0) 100%
    );
    filter: blur(100px);
    width: 921px;
    height: 921px;
    border-radius: 50%;
    pointer-events: none;
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;
export const WithdrawProject = styled.div`
  position: relative;
  padding-left: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 15px;
  &:before {
    position: absolute;
    content: "";
    top: 50%;
    left: 0;
    background: url("/img/Dashboard/Withdraw/withdraw_icn_01.png") center /
      cover no-repeat;
    width: 16px;
    height: 16px;
    margin-top: -9px;
  }
  p {
    color: #f7f8f8;
    font-size: 14px;
    font-weight: 500;
  }
  span {
    color: #8a8f98;
    font-size: 12px;
    padding: 2px 5px;
    display: inline-block;
    border-radius: 4px;
    border: 1px solid #2c2e33;
  }
  @media screen and (max-width: 1024px) {
    margin-bottom: 100px;
  }
`;
export const WithdrawTitle = styled.h2`
  color: #f7f8f8;
  font-size: 48px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 6px;
`;
export const WithdrawText = styled.p`
  color: #9f9f9f;
  font-size: 16px;
`;
export const WithdrawImage = styled.div`
  text-align: right;
  position: relative;
  z-index: 1;
  @media screen and (max-width: 1024px) {
    width: 263px;
    height: 270px;
    margin-left: auto;
    margin-right: -10px;
    position: absolute;
    right: 0;
    top: -40px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;
export const WithdrawImageDecor = styled.div`
  position: absolute;
  bottom: 0;
  right: -270px;
  pointer-events: none;
`;
export const WithdrawRight = styled.div`
  width: 596px;
  background: rgba(211, 211, 211, 0.002)
    url("img/Dashboard/Withdraw/withdraw_bg.png") center / cover no-repeat;
  padding: 12px;
  position: relative;
  z-index: 1;
  min-height: 660px;
  @media screen and (max-width: 1024px) {
    width: 100%;
    background: unset;
    padding: 0;
  }
`;
export const FormHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  .form-title {
    span {
      border-radius: 4px;
      background: var(
        --Number,
        linear-gradient(180deg, #ceffd1 0%, #4ec575 100%)
      );
      padding: 2px 10px;
      color: #000;
      text-align: center;
      font-size: 11px;
    }
    p {
      color: #fff;
      font-size: 24px;
      font-weight: 600;
      text-transform: capitalize;
      margin-top: 5px;
    }
  }
  .balance {
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: #303030;
    background-blend-mode: luminosity;
    box-shadow: 2px 4px 16px 0px rgba(248, 248, 248, 0.06) inset;
    backdrop-filter: blur(50px);
    padding: 8px 31px;
    span {
      display: block;
    }
    .text {
      color: #989898;
      font-size: 13px;
    }
    .number {
      display: flex;
      align-items: center;
      gap: 5px;
      color: #fff;
      font-size: 16px;
      font-weight: 500;
    }
  }
  @media screen and (max-width: 1024px) {
    .balance {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
`;
export const FormWithdraw = styled.div`
  .list-fee {
    margin-top: 20px;
    margin-bottom: 50px;
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:not(:last-child) {
        margin-bottom: 8px;
      }
      p {
        color: #c8c8c8;
        font-size: 14px;
      }
    }
  }
  ${ButtonCommon} {
    justify-content: center;
    font-size: 20px;
    padding-top: 19px;
    padding-bottom: 19px;
    &.disable {
      pointer-events: none;
    }
  }
  @media screen and (max-width: 1024px) {
    ${ButtonCommon} {
      padding-top: 11px;
      padding-bottom: 11px;
      font-size: 16px;
    }
  }
`;
export const FormRow = styled.div`
  &:not(:last-child) {
    margin-bottom: 8px;
  }
  .form-title {
    color: #dfdfdf;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 2px;
  }
  .ant-select {
    background: #272727;
    border-radius: 6px;
  }
  .ant-select-selection-item {
    img {
      width: 24px;
      height: 24px;
    }
  }
  .address {
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    border-radius: 4px;
    background: linear-gradient(220deg, #b4b4b4 11.31%, #636363 83.28%);
    box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.25) inset;
    padding: 2px 8px;
  }
  .form-balane {
    border-radius: 6px;
    background: #272727;
    padding: 20px 16px 16px;
  }
  .block-input {
    min-height: 122px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
`;
export const BlockTable = styled.div`
  margin-top: 10px;
  max-width: 1360px;
  margin: 0 auto;
  .ant-table-cell {
    &:nth-child(1),
    &:nth-child(6) {
      text-align: center;
      p {
        text-align: center;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .ant-table-cell {
      p {
        color: gray;
      }
      &:nth-child(1),
      &:nth-child(6) {
        text-align: left;
        p {
          text-align: left !important;
        }
      }
      .text-heading-mobile {
        color: #fff;
        width: 35%;
        display: inline-block;
      }
    }
  }
`;
