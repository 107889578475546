import styled from "styled-components";

export const ProgressContainer = styled.div`
  position: relative;
  max-width: 250px;
  width: 100%;
  height: 24px;
  border-radius: 8px;
  border: 1px solid #BBF7C6;
  background: radial-gradient(105.75% 89.98% at 2.11% 14.58%, rgba(129, 238, 151, 0.00) 0%, rgba(129, 238, 151, 0.20) 100%), rgba(35, 35, 35, 0.50);
  box-shadow: 0px 0px 8px 0px rgba(248, 248, 248, 0.60) inset, 0px 8px 24px -16px rgba(0, 0, 0, 0.40);
  overflow: hidden;
  span {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    border-radius: 6px;
    background: #81EE97;
    box-shadow: 0px 0px 8px 0px rgba(248, 248, 248, 0.60) inset, 0px 8px 24px -16px rgba(0, 0, 0, 0.40);
  }
  p {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    margin-top: -1px;
    color: #052E0F;
    font-size: 12px;
    font-weight: 500;
  }
  @media screen and (max-width: 1023px) {
    height: 18px;
  }
`