import ProfileCommon from "../../components/Common/Profile";
import TitleCommon from "../../components/Common/Title";
import { BlockFrame, ButtonCommon } from "../../Layout/styled";
import {
  ButtonSwitch,
  SwapChart,
  SwapChartHeader,
  SwapContainer,
  SwapForm,
  SwapLeft,
  SwapRight,
  SwapRightActions,
  SwapRightHeader,
  SwapWrapper,
} from "./styled";
import {
  LiqFrame,
  LiqFrameHeader,
  LiqPerList,
  LiquidityActions,
  LiquidityAddWrapper,
  LiquidityFrame,
  LiquidityInfo,
  LiquidityQuote,
} from "../Liquidity/Add/styled";
import InputCommon from "../../components/Common/Input";
import { useContext, useEffect, useState } from "react";
import { ContextProviderWrapper } from "../../components/Context";
import { HintHeader } from "../../components/Common/Hint/styled";
import web3 from "web3";
import { abiPancakeV2, abiPancakePair } from "../../contract/Pair";
import {
  CurrencyAmount,
  Fetcher,
  Token,
  Trade,
  Percent,
} from "@pancakeswap/sdk";
import { ethers } from "ethers";
import {
  addressContractToken,
  addressContractUSDT,
  addressContractWBNB,
  routerContract,
} from "../../contract/Address";
import { useAccount, useBalance } from "wagmi";
import { convertFixed, convertToWei } from "../../utils/convertNumber";
import { Skeleton, Spin } from "antd";
import getTokenInfo from "../../utils/checkInfoByAccount";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import SelectCommon from "../../components/Common/Select";
import ChartCommon from "../../components/Common/Chart";
import toast from "react-hot-toast";
import ModalSuccess from "../../components/Modal/Success";
import { PriceUsd } from "../Dashboard/styled";
import { useSelector } from "react-redux";

declare const window: Window & typeof globalThis & { ethereum: any };

const Swap = () => {
  const { open } = useWeb3Modal();
  const Web3 = new web3(window.ethereum);
  // const providerRPC = new ethers.JsonRpcProvider('https://bsc-dataseed4.binance.org/');
  const { isMobile, isDesktop } = useContext(ContextProviderWrapper)!;
  const poolInfoData = useSelector((state: any) => state.user.poolInfo);
  const tokenBNB = new Token(
    56,
    addressContractWBNB,
    18,
    "BNB",
    "Binance Coin"
  );
  const tokenONI = new Token(
    56,
    addressContractToken,
    18,
    "ONI",
    "ONI Protocol"
  );
  const tokenUSDT = new Token(56, addressContractUSDT, 18, "USDT", "Tether");
  const [switchSwap, setSwitchSwap] = useState(false);
  const [valueTokenA, setValueTokenA] = useState("");
  const [valueTokenB, setValueTokenB] = useState("");
  const [tokenFrom, setTokenFrom] = useState<any>();
  const [tokenTo, setTokenTo] = useState<any>();
  const [switchIcon, setSwitchIcon] = useState(false);
  const [swapDetails, setSwapDetails] = useState({
    minReceived: "",
    feeSaved: "",
    priceImpact: "",
    tradingFee: "",
    swapRoute: "",
  });
  const usdPrice = useSelector((state: any) => state.user.usdtPrice);
  const { address } = useAccount();
  const [valueA, setValueA] = useState<any>("");
  const [valueB, setValueB] = useState<any>("");
  const [inputFor, setInputFor] = useState("A");
  const [isLoading, setIsLoading] = useState(false);
  const [clickedSelection, setClickedSelection] = useState("BNB");
  const onChangeSelect = (e: any) => {
    setClickedSelection(e);
    handleValueChange("B", "");
    handleValueChange("A", "");
  };

  const { data: balanceA } = useBalance(
    clickedSelection === "BNB" && switchSwap
      ? { address }
      : {
          address,
          token:
            clickedSelection === "USDT" && switchSwap
              ? addressContractUSDT
              : addressContractToken,
          chainId: 56,
        }
  );

  const { data: balanceB } = useBalance(
    clickedSelection === "BNB" && !switchSwap
      ? { address }
      : {
          address,
          token:
            clickedSelection === "USDT" && !switchSwap
              ? addressContractUSDT
              : addressContractToken,
          chainId: 56,
        }
  );

  const fetchToken = async () => {
    if (clickedSelection === "USDT") {
      setTokenFrom(switchSwap ? tokenUSDT : tokenONI);
      setTokenTo(switchSwap ? tokenONI : tokenUSDT);
    } else {
      setTokenFrom(switchSwap ? tokenBNB : tokenONI);
      setTokenTo(switchSwap ? tokenONI : tokenBNB);
    }
  };

  useEffect(() => {
    fetchToken();
  }, [switchSwap, clickedSelection]);

  // Calculate 1 ONI = ? BNB and 1 ONI = ? USDT
  useEffect(() => {
    const fetchPrices = async () => {
      try {
        const pairONI_BNB = await Fetcher.fetchPairData(tokenONI, tokenBNB);

        // 1 ONI = ? BNB
        const tradeONItoBNB = Trade.bestTradeExactIn(
          [pairONI_BNB],
          CurrencyAmount.fromRawAmount(
            tokenONI,
            ethers.parseUnits("1", tokenONI.decimals).toString()
          ),
          tokenBNB
        );
        const priceONIinBNB = tradeONItoBNB[0]?.outputAmount?.toSignificant(6);

        // 1 BNB = ? ONI
        const tradeBNBtoONI = Trade.bestTradeExactIn(
          [pairONI_BNB],
          CurrencyAmount.fromRawAmount(
            tokenBNB,
            ethers.parseUnits("1", tokenBNB.decimals).toString()
          ),
          tokenONI
        );
        const priceBNBinONI = tradeBNBtoONI[0]?.outputAmount?.toSignificant(6);

        const pairBNB_USDT = await Fetcher.fetchPairData(tokenBNB, tokenUSDT);

        // 1 ONI -> BNB -> USDT
        const tradeONItoUSDT = Trade.bestTradeExactIn(
          [pairONI_BNB, pairBNB_USDT],
          CurrencyAmount.fromRawAmount(
            tokenONI,
            ethers.parseUnits("1", tokenONI.decimals).toString()
          ),
          tokenUSDT
        );
        const priceONIinUSDT =
          tradeONItoUSDT[0]?.outputAmount?.toSignificant(6);

        // 1 USDT -> BNB -> ONI
        const tradeUSDTtoONI = Trade.bestTradeExactIn(
          [pairBNB_USDT, pairONI_BNB],
          CurrencyAmount.fromRawAmount(
            tokenUSDT,
            ethers.parseUnits("1", tokenUSDT.decimals).toString()
          ),
          tokenONI
        );
        const priceUSDTinONI =
          tradeUSDTtoONI[0]?.outputAmount?.toSignificant(6);

        setValueTokenA(priceONIinBNB || "0");
        setValueTokenB(priceBNBinONI || "0");
        if (clickedSelection === "USDT") {
          setValueTokenB(priceONIinUSDT || "0");
          setValueTokenA(priceUSDTinONI || "0");
        }
      } catch (error) {
        console.error("Error fetching prices:", error);
      }
    };
    fetchPrices();
  }, [clickedSelection]);

  const calculateAmounts = async (
    value: string,
    direction: "AtoB" | "BtoA"
  ) => {
    if (!tokenFrom || !tokenTo) {
      console.error(
        "TokenFrom or TokenTo is undefined. Cannot calculate amounts."
      );
      return;
    }

    setIsLoading(true);
    try {
      let trades;
      if (clickedSelection === "USDT") {
        const pairA = await Fetcher.fetchPairData(tokenFrom, tokenBNB);
        const pairB = await Fetcher.fetchPairData(tokenBNB, tokenTo);

        if (direction === "AtoB") {
          trades = Trade.bestTradeExactIn(
            [pairA, pairB],
            CurrencyAmount.fromRawAmount(
              tokenFrom,
              ethers.parseUnits(value || "0", tokenFrom.decimals).toString()
            ),
            tokenTo
          );
        } else {
          const outputAmount: any = CurrencyAmount.fromRawAmount(
            tokenTo,
            ethers.parseUnits(value || "0", tokenTo.decimals).toString()
          );
          trades = Trade.bestTradeExactOut(
            [pairA, pairB],
            tokenFrom,
            outputAmount
          );
        }
      } else {
        const pair = await Fetcher.fetchPairData(tokenFrom, tokenTo);
        if (direction === "AtoB") {
          trades = Trade.bestTradeExactIn(
            [pair],
            CurrencyAmount.fromRawAmount(
              tokenFrom,
              ethers.parseUnits(value || "0", tokenFrom.decimals).toString()
            ),
            tokenTo
          );
        } else {
          const outputAmount: any = CurrencyAmount.fromRawAmount(
            tokenTo,
            ethers.parseUnits(value || "0", tokenTo.decimals).toString()
          );
          trades = Trade.bestTradeExactOut([pair], tokenFrom, outputAmount);
        }
      }

      const trade = trades[0];

      if (direction === "AtoB") {
        const amountOut = trade.outputAmount.toSignificant(6);
        setValueB(amountOut);
      } else {
        const amountIn = trade.inputAmount.toSignificant(6);
        setValueA(amountIn);
      }

      calculateSwapDetails(trade, tokenFrom, tokenTo, value);
    } catch (error) {
      console.error("Error calculating amounts:", error);
    }
    setIsLoading(false);
  };

  const calculateSwapDetails = async (
    trade: any,
    tokenA: any,
    tokenB: any,
    valueA: any
  ) => {
    try {
      const slippageTolerance = new Percent("50", "10000");

      const minReceived = trade
        .minimumAmountOut(slippageTolerance)
        .toSignificant(6);

      const priceImpact = trade.priceImpact.toFixed(2);
      const tradingFee = `${(parseFloat(valueA) * 0.0025).toFixed(6)} ${
        tokenA.symbol
      }`;
      const swapRoute = `${trade.route.path
        .map((token: any) => token.symbol)
        .join(" -> ")}`;

      setSwapDetails({
        minReceived,
        feeSaved: "",
        priceImpact,
        tradingFee,
        swapRoute,
      });
    } catch (error) {
      console.error("Error calculating swap details:", error);
    }
  };

  const handleValueChange = (type: string, value: string) => {
    if (type === "A" && !isNaN(Number(value))) {
      setInputFor("A");
      setValueA(value);
    } else if (type === "B" && !isNaN(Number(value))) {
      setInputFor("B");
      setValueB(value);
    }
  };

  // Clear data
  useEffect(() => {
    if (valueA === "" || !valueA) {
      setValueB("");
      setValueA("");
      setSwapDetails({
        minReceived: "",
        feeSaved: "",
        priceImpact: "",
        tradingFee: "",
        swapRoute: "",
      });
    }
  }, [valueA]);

  useEffect(() => {
    if (valueB === "" || !valueB) {
      setValueA("");
      setValueB("");
      setSwapDetails({
        minReceived: "",
        feeSaved: "",
        priceImpact: "",
        tradingFee: "",
        swapRoute: "",
      });
    }
  }, [valueB]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (inputFor === "A" && valueA) {
        calculateAmounts(valueA, "AtoB");
      } else if (inputFor === "B" && valueB) {
        calculateAmounts(valueB, "BtoA");
      }
    }, 600);
    return () => clearTimeout(timer);
  }, [valueA, valueB, clickedSelection]);

  // Approve
  const [allowance, setAllowance] = useState<any>("");
  const getAllowance = async () => {
    try {
      const amountAllowance = await getTokenInfo(
        routerContract,
        addressContractToken,
        address
      );
      setAllowance(amountAllowance.allowance);
    } catch (error) {
      setAllowance("0");
      console.error("error", error);
    }
  };

  useEffect(() => {
    if (address) {
      getAllowance();
    }
  }, [address]);

  const [pendingApprove, setPendingApprove] = useState<any>(false);
  const approveSwap = async () => {
    const gasPrice = await Web3.eth.getGasPrice();
    try {
      setPendingApprove(true);

      const contract = new Web3.eth.Contract(
        abiPancakePair,
        addressContractToken
      );
      const gasEstimate = await contract.methods
        .approve(routerContract, ethers.MaxUint256.toString())
        .estimateGas({
          from: address,
          gasPrice: gasPrice.toString(),
        });
      await contract.methods
        .approve(routerContract, ethers.MaxUint256.toString())
        .send({
          from: address,
          gasPrice: gasPrice.toString(),
          gas: gasEstimate.toString(),
        })
        .then(async (res: any) => {
          setPendingApprove(false);
          await getAllowance();
        })
        .catch((err: any) => {
          console.error("Err approve", err);
          setPendingApprove(false);
        });
    } catch (error: any) {
      console.error("Error approve", error);
      setPendingApprove(false);
    }
  };

  const [loadingSwap, setLoadingSwap] = useState(false);
  const [hashTransaction, setHashTransaction] = useState("");

  const handleSwap = async () => {
    setLoadingSwap(true);
    try {
      const routerPancake = new Web3.eth.Contract(abiPancakeV2, routerContract);
      const routerPancakeUniversal = new Web3.eth.Contract(
        [
          {
            inputs: [
              {
                components: [
                  { internalType: "address", name: "permit2", type: "address" },
                  { internalType: "address", name: "weth9", type: "address" },
                  {
                    internalType: "address",
                    name: "seaportV1_5",
                    type: "address",
                  },
                  {
                    internalType: "address",
                    name: "seaportV1_4",
                    type: "address",
                  },
                  {
                    internalType: "address",
                    name: "openseaConduit",
                    type: "address",
                  },
                  { internalType: "address", name: "x2y2", type: "address" },
                  {
                    internalType: "address",
                    name: "looksRareV2",
                    type: "address",
                  },
                  {
                    internalType: "address",
                    name: "routerRewardsDistributor",
                    type: "address",
                  },
                  {
                    internalType: "address",
                    name: "looksRareRewardsDistributor",
                    type: "address",
                  },
                  {
                    internalType: "address",
                    name: "looksRareToken",
                    type: "address",
                  },
                  {
                    internalType: "address",
                    name: "v2Factory",
                    type: "address",
                  },
                  {
                    internalType: "address",
                    name: "v3Factory",
                    type: "address",
                  },
                  {
                    internalType: "address",
                    name: "v3Deployer",
                    type: "address",
                  },
                  {
                    internalType: "bytes32",
                    name: "v2InitCodeHash",
                    type: "bytes32",
                  },
                  {
                    internalType: "bytes32",
                    name: "v3InitCodeHash",
                    type: "bytes32",
                  },
                  {
                    internalType: "address",
                    name: "stableFactory",
                    type: "address",
                  },
                  {
                    internalType: "address",
                    name: "stableInfo",
                    type: "address",
                  },
                  {
                    internalType: "address",
                    name: "pancakeNFTMarket",
                    type: "address",
                  },
                ],
                internalType: "struct RouterParameters",
                name: "params",
                type: "tuple",
              },
            ],
            stateMutability: "nonpayable",
            type: "constructor",
          },
          { inputs: [], name: "BalanceTooLow", type: "error" },
          { inputs: [], name: "BuyPancakeNFTFailed", type: "error" },
          { inputs: [], name: "BuyPunkFailed", type: "error" },
          { inputs: [], name: "ContractLocked", type: "error" },
          { inputs: [], name: "ETHNotAccepted", type: "error" },
          {
            inputs: [
              {
                internalType: "uint256",
                name: "commandIndex",
                type: "uint256",
              },
              { internalType: "bytes", name: "message", type: "bytes" },
            ],
            name: "ExecutionFailed",
            type: "error",
          },
          { inputs: [], name: "FromAddressIsNotOwner", type: "error" },
          { inputs: [], name: "InsufficientETH", type: "error" },
          { inputs: [], name: "InsufficientToken", type: "error" },
          { inputs: [], name: "InvalidBips", type: "error" },
          {
            inputs: [
              { internalType: "uint256", name: "commandType", type: "uint256" },
            ],
            name: "InvalidCommandType",
            type: "error",
          },
          { inputs: [], name: "InvalidOwnerERC1155", type: "error" },
          { inputs: [], name: "InvalidOwnerERC721", type: "error" },
          { inputs: [], name: "InvalidPath", type: "error" },
          { inputs: [], name: "InvalidPoolAddress", type: "error" },
          { inputs: [], name: "InvalidPoolLength", type: "error" },
          { inputs: [], name: "InvalidReserves", type: "error" },
          { inputs: [], name: "InvalidSpender", type: "error" },
          { inputs: [], name: "LengthMismatch", type: "error" },
          { inputs: [], name: "SliceOutOfBounds", type: "error" },
          { inputs: [], name: "StableInvalidPath", type: "error" },
          { inputs: [], name: "StableTooLittleReceived", type: "error" },
          { inputs: [], name: "StableTooMuchRequested", type: "error" },
          { inputs: [], name: "TransactionDeadlinePassed", type: "error" },
          { inputs: [], name: "UnableToClaim", type: "error" },
          { inputs: [], name: "UnsafeCast", type: "error" },
          { inputs: [], name: "V2InvalidPath", type: "error" },
          { inputs: [], name: "V2TooLittleReceived", type: "error" },
          { inputs: [], name: "V2TooMuchRequested", type: "error" },
          { inputs: [], name: "V3InvalidAmountOut", type: "error" },
          { inputs: [], name: "V3InvalidCaller", type: "error" },
          { inputs: [], name: "V3InvalidSwap", type: "error" },
          { inputs: [], name: "V3TooLittleReceived", type: "error" },
          { inputs: [], name: "V3TooMuchRequested", type: "error" },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: "address",
                name: "previousOwner",
                type: "address",
              },
              {
                indexed: true,
                internalType: "address",
                name: "newOwner",
                type: "address",
              },
            ],
            name: "OwnershipTransferred",
            type: "event",
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: false,
                internalType: "address",
                name: "account",
                type: "address",
              },
            ],
            name: "Paused",
            type: "event",
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: false,
                internalType: "uint256",
                name: "amount",
                type: "uint256",
              },
            ],
            name: "RewardsSent",
            type: "event",
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: "address",
                name: "factory",
                type: "address",
              },
              {
                indexed: true,
                internalType: "address",
                name: "info",
                type: "address",
              },
            ],
            name: "SetStableSwap",
            type: "event",
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: false,
                internalType: "address",
                name: "account",
                type: "address",
              },
            ],
            name: "Unpaused",
            type: "event",
          },
          {
            inputs: [
              { internalType: "bytes", name: "looksRareClaim", type: "bytes" },
            ],
            name: "collectRewards",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [
              { internalType: "bytes", name: "commands", type: "bytes" },
              { internalType: "bytes[]", name: "inputs", type: "bytes[]" },
            ],
            name: "execute",
            outputs: [],
            stateMutability: "payable",
            type: "function",
          },
          {
            inputs: [
              { internalType: "bytes", name: "commands", type: "bytes" },
              { internalType: "bytes[]", name: "inputs", type: "bytes[]" },
              { internalType: "uint256", name: "deadline", type: "uint256" },
            ],
            name: "execute",
            outputs: [],
            stateMutability: "payable",
            type: "function",
          },
          {
            inputs: [
              { internalType: "address", name: "", type: "address" },
              { internalType: "address", name: "", type: "address" },
              { internalType: "uint256[]", name: "", type: "uint256[]" },
              { internalType: "uint256[]", name: "", type: "uint256[]" },
              { internalType: "bytes", name: "", type: "bytes" },
            ],
            name: "onERC1155BatchReceived",
            outputs: [{ internalType: "bytes4", name: "", type: "bytes4" }],
            stateMutability: "pure",
            type: "function",
          },
          {
            inputs: [
              { internalType: "address", name: "", type: "address" },
              { internalType: "address", name: "", type: "address" },
              { internalType: "uint256", name: "", type: "uint256" },
              { internalType: "uint256", name: "", type: "uint256" },
              { internalType: "bytes", name: "", type: "bytes" },
            ],
            name: "onERC1155Received",
            outputs: [{ internalType: "bytes4", name: "", type: "bytes4" }],
            stateMutability: "pure",
            type: "function",
          },
          {
            inputs: [
              { internalType: "address", name: "", type: "address" },
              { internalType: "address", name: "", type: "address" },
              { internalType: "uint256", name: "", type: "uint256" },
              { internalType: "bytes", name: "", type: "bytes" },
            ],
            name: "onERC721Received",
            outputs: [{ internalType: "bytes4", name: "", type: "bytes4" }],
            stateMutability: "pure",
            type: "function",
          },
          {
            inputs: [],
            name: "owner",
            outputs: [{ internalType: "address", name: "", type: "address" }],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [
              { internalType: "int256", name: "amount0Delta", type: "int256" },
              { internalType: "int256", name: "amount1Delta", type: "int256" },
              { internalType: "bytes", name: "data", type: "bytes" },
            ],
            name: "pancakeV3SwapCallback",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [],
            name: "pause",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [],
            name: "paused",
            outputs: [{ internalType: "bool", name: "", type: "bool" }],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [],
            name: "renounceOwnership",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [
              { internalType: "address", name: "_factory", type: "address" },
              { internalType: "address", name: "_info", type: "address" },
            ],
            name: "setStableSwap",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [],
            name: "stableSwapFactory",
            outputs: [{ internalType: "address", name: "", type: "address" }],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [],
            name: "stableSwapInfo",
            outputs: [{ internalType: "address", name: "", type: "address" }],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [
              { internalType: "bytes4", name: "interfaceId", type: "bytes4" },
            ],
            name: "supportsInterface",
            outputs: [{ internalType: "bool", name: "", type: "bool" }],
            stateMutability: "pure",
            type: "function",
          },
          {
            inputs: [
              { internalType: "address", name: "newOwner", type: "address" },
            ],
            name: "transferOwnership",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [],
            name: "unpause",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
          { stateMutability: "payable", type: "receive" },
        ],
        "0x1A0A18AC4BECDDbd6389559687d1A73d8927E416"
      );
      const amountInWei = convertToWei(valueA, 18);
      const path = [tokenFrom.address, tokenTo.address];
      const deadline = Math.floor(Date.now() / 1000) + 60 * 20;
      const gasPrice = await Web3.eth.getGasPrice();

      if (switchSwap) {
        // Buying ONI from BNB or USDT
        if (clickedSelection === "USDT") {
          toast.error("Now you can't swap with USDT");
          const abiCoder = new ethers.AbiCoder();
          const inputs = abiCoder.encode(
            ["address", "address", "uint256", "address", "uint256"],
            [
              tokenFrom.address,
              tokenTo.address,
              amountInWei.toString(),
              address,
              deadline,
            ]
          );

          const gasEstimate = await routerPancakeUniversal.methods
            .execute("0x0a09", [inputs], deadline)
            .estimateGas({
              from: address,
              gasPrice: gasPrice.toString(),
            });

          await routerPancakeUniversal.methods
            .execute("0x0a09", [inputs], deadline)
            .send({
              from: address,
              value: web3.utils.toWei("1", "ether"),
              gas: gasEstimate.toString(),
              gasPrice: gasPrice.toString(),
            })
            .then((res: any) => {
              console.log("Giao dịch thành công:", res);
            })
            .catch((error: any) => {
              console.error("Giao dịch thất bại:", error);
            });
        } else {
          const gasEstimate = await routerPancake.methods
            .swapExactETHForTokensSupportingFeeOnTransferTokens(
              0,
              path,
              address,
              deadline
            )
            .estimateGas({
              from: address,
              value: amountInWei.toString(),
              gasPrice: gasPrice.toString(),
            });
          await routerPancake.methods
            .swapExactETHForTokensSupportingFeeOnTransferTokens(
              0,
              path,
              address,
              deadline
            )
            .send({
              from: address,
              value: amountInWei.toString(),
              gas: gasEstimate.toString(),
              gasPrice: gasPrice.toString(),
            })
            .then((res: any) => {
              if (res) {
                setHashTransaction(res.transactionHash);
                setIsModalOpen(true);
                setValueA("");
                setValueB("");
                setSwapDetails({
                  minReceived: "",
                  feeSaved: "",
                  priceImpact: "",
                  tradingFee: "",
                  swapRoute: "",
                });
              }
              console.log("Transaction successful:", res);
              toast.success("Swap successfully");
            })
            .catch((error: any) => {
              console.error("Transaction failed (Buy ONI):", error);
            });
        }
      } else {
        // Selling ONI for BNB
        const gasEstimate = await routerPancake.methods
          .swapExactTokensForETHSupportingFeeOnTransferTokens(
            amountInWei.toString(),
            0,
            path,
            address,
            deadline
          )
          .estimateGas({
            from: address,
            gasPrice: gasPrice.toString(),
          });
        await routerPancake.methods
          .swapExactTokensForETHSupportingFeeOnTransferTokens(
            amountInWei.toString(),
            0,
            path,
            address,
            deadline
          )
          .send({
            from: address,
            gas: gasEstimate.toString(),
            gasPrice: gasPrice.toString(),
          })
          .then((res: any) => {
            if (res) {
              setHashTransaction(res.transactionHash);
              setIsModalOpen(true);
              setValueA("");
              setValueB("");
              setSwapDetails({
                minReceived: "",
                feeSaved: "",
                priceImpact: "",
                tradingFee: "",
                swapRoute: "",
              });
            }
            console.log("Transaction successful (Sell ONI):", res);
            toast.success("Swap successfully");
          })
          .catch((error: any) => {
            console.error("Transaction failed (Sell ONI):", error);
          });
      }
      setLoadingSwap(false);
    } catch (error) {
      console.error("Error swapping tokens:", error);
      setLoadingSwap(false);
    }
  };

  const handleSwitchToken = async () => {
    setSwitchSwap(!switchSwap);
    setClickedSelection("BNB");
    if (inputFor === "A") {
      setInputFor("B");
      handleValueChange("B", "");
      setValueA("");
      setValueB("");
    } else {
      setInputFor("A");
      handleValueChange("A", "");
      setValueA("");
      setValueB("");
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <SwapContainer>
      <ModalSuccess
        hash={hashTransaction}
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
      <SwapWrapper>
        <SwapLeft>
          <BlockFrame>
            <SwapChart>
              <SwapChartHeader>
                <ProfileCommon
                  avatar="./img/Common/oni_icon.png"
                  name="ONI Token"
                  subName="ONI Protocol"
                />
                <figure>
                  <img
                    width={36}
                    height={36}
                    src="./img/Dashboard/Swap/swap_chart_star.png"
                    alt="icon"
                    loading="lazy"
                  />
                </figure>
              </SwapChartHeader>
              <ChartCommon height={isMobile && 300} />
            </SwapChart>
          </BlockFrame>
        </SwapLeft>
        <SwapRight>
          <SwapRightHeader>
            <TitleCommon title="Swap" />
            <SwapRightActions>
              <figure>
                <img
                  width={20}
                  height={21}
                  src="./img/Dashboard/Swap/setting.svg"
                  alt="icon"
                  loading="lazy"
                />
              </figure>
            </SwapRightActions>
          </SwapRightHeader>
          <SwapForm>
            <LiquidityAddWrapper>
              <LiquidityFrame>
                <LiqFrame>
                  <ButtonSwitch
                    onClick={() => {
                      handleSwitchToken();
                    }}
                  >
                    <img
                      width={36}
                      height={36}
                      src="./img/Common/arrow_down.png"
                      alt="icon"
                      loading="lazy"
                    />
                  </ButtonSwitch>
                  <LiqFrameHeader>
                    {!switchSwap ? (
                      <ProfileCommon
                        avatar="./img/Common/oni_icon.png"
                        name="ONI"
                        subName="BNB Smart Chain"
                      />
                    ) : (
                      <SelectCommon
                        disabled={true}
                        data={[
                          {
                            title: "BNB",
                            img: "./img/Common/bnb_icon.png",
                          },
                          {
                            title: "USDT",
                            img: "./img/Common/usdt_icon.png",
                          },
                        ]}
                        onChange={onChangeSelect}
                        suffixIcon={
                          <img
                            width={25}
                            height={24}
                            src="./img/Common/select_btn.png"
                            loading="lazy"
                            alt="icon"
                          />
                        }
                      />
                    )}
                    <div>
                      <p>Your Balance</p>
                      <span>
                        {address && balanceA
                          ? convertFixed(Number(balanceA?.formatted))
                          : 0}
                      </span>
                    </div>
                  </LiqFrameHeader>
                  {isLoading && inputFor === "B" ? (
                    <Skeleton paragraph={{ rows: 0 }} active />
                  ) : (
                    <InputCommon
                      minimum={0}
                      placeHolder="0.00"
                      onChange={(e: any) =>
                        handleValueChange("A", e.target.value)
                      }
                      value={valueA}
                    />
                  )}
                  <PriceUsd>
                    {!switchSwap ? (
                      <>${valueA ? convertFixed(usdPrice * valueA) : "0.00"}</>
                    ) : (
                      <>${valueA ? convertFixed(usdPrice * valueB) : "0.00"}</>
                    )}
                  </PriceUsd>
                  <LiqPerList>
                    {perData.map((item) => {
                      return (
                        <li
                          key={item.id}
                          onClick={() => {
                            const convertNumber = (
                              Number(balanceA?.formatted) * item.value
                            ).toString();
                            handleValueChange("A", convertNumber.toString());
                          }}
                        >
                          <p>{item.text}</p>
                        </li>
                      );
                    })}
                  </LiqPerList>
                </LiqFrame>
                <LiqFrame>
                  <LiqFrameHeader>
                    {switchSwap ? (
                      <ProfileCommon
                        avatar="./img/Common/oni_icon.png"
                        name="ONI"
                        subName="BNB Smart Chain"
                      />
                    ) : (
                      <SelectCommon
                        disabled={true}
                        data={[
                          {
                            title: "BNB",
                            img: "./img/Common/bnb_icon.png",
                          },
                          {
                            title: "USDT",
                            img: "./img/Common/usdt_icon.png",
                          },
                        ]}
                        onChange={onChangeSelect}
                        suffixIcon={
                          <img
                            width={25}
                            height={24}
                            src="./img/Common/select_btn.png"
                            loading="lazy"
                            alt="icon"
                          />
                        }
                      />
                    )}
                    <div>
                      <p>Your Balance</p>
                      <span>
                        {address && balanceB
                          ? convertFixed(Number(balanceB?.formatted))
                          : 0}
                      </span>
                    </div>
                  </LiqFrameHeader>
                  {isLoading && inputFor === "A" ? (
                    <Skeleton paragraph={{ rows: 0 }} active />
                  ) : (
                    <InputCommon
                      minimum={0}
                      placeHolder="0.00"
                      onChange={(e: any) =>
                        handleValueChange("B", e.target.value)
                      }
                      value={valueB}
                    />
                  )}
                  <PriceUsd>
                    {!switchSwap ? (
                      <>
                        $
                        {valueB
                          ? convertFixed(
                              poolInfoData?.quote_token_price_usd * valueB
                            )
                          : "0.00"}
                      </>
                    ) : (
                      <>
                        $
                        {valueB
                          ? convertFixed(
                              poolInfoData?.quote_token_price_usd * valueA
                            )
                          : "0.00"}
                      </>
                    )}
                  </PriceUsd>
                  <LiqPerList>
                    {perData.map((item) => {
                      return (
                        <li
                          key={item.id}
                          onClick={() => {
                            const convertNumber = (
                              Number(balanceB?.formatted) * item.value
                            ).toString();
                            handleValueChange("B", convertNumber.toString());
                          }}
                        >
                          <p>{item.text}</p>
                        </li>
                      );
                    })}
                  </LiqPerList>
                </LiqFrame>
              </LiquidityFrame>
              <LiquidityInfo>
                <div>
                  <p>
                    1 {switchIcon ? tokenTo?.symbol : tokenFrom?.symbol || "--"}{" "}
                    = {switchIcon ? valueTokenB : valueTokenA}{" "}
                    {switchIcon ? tokenFrom?.symbol : tokenTo?.symbol || "--"}
                    <img
                      onClick={() => {
                        setSwitchIcon(!switchIcon);
                      }}
                      width={18}
                      height={18}
                      src="./img/Common/exchange_icon.png"
                      alt="icon"
                      loading="lazy"
                    />
                  </p>
                  {!isDesktop && (
                    <HintHeader>
                      <div>
                        <figure>
                          <img
                            width={14}
                            height={14}
                            src="./img/Common/hint_icon.svg"
                            alt="icon"
                            loading="lazy"
                          />
                        </figure>
                        {/* <p>How it works</p> */}
                      </div>
                    </HintHeader>
                  )}
                </div>
              </LiquidityInfo>
              <LiquidityQuote>
                <div>
                  <p>Slippage</p>
                  <span>Auto (2%)</span>
                </div>
                <div>
                  <p>Minimum received</p>
                  <span>{swapDetails?.minReceived || "--"}</span>
                </div>
                <div>
                  <p>Price Impact</p>
                  <span
                    style={{
                      color:
                        Number(swapDetails?.priceImpact) < 1 &&
                        swapDetails?.priceImpact !== ""
                          ? "#bbf7c6"
                          : Number(swapDetails?.priceImpact) > 49 &&
                            swapDetails?.priceImpact !== ""
                          ? "#f70d0d"
                          : "",
                    }}
                  >
                    {!swapDetails?.priceImpact
                      ? ""
                      : Number(swapDetails?.priceImpact) < 0.01 && " < "}
                    {!swapDetails?.priceImpact
                      ? "--"
                      : Number(swapDetails?.priceImpact) + "%"}
                  </span>
                </div>
                <div>
                  <p>Trading Fee</p>
                  <span>{swapDetails?.tradingFee || "--"}</span>
                </div>
                <div>
                  <p>Route</p>
                  <span>{swapDetails?.swapRoute || "--"}</span>
                </div>
              </LiquidityQuote>
              <LiquidityActions>
                {!address ? (
                  <ButtonCommon
                    style={{
                      width: "100%",
                    }}
                    className="green"
                    onClick={() => {
                      open();
                    }}
                  >
                    Please Connect Wallet
                  </ButtonCommon>
                ) : (
                  <>
                    {Number(valueA) >= Number(allowance) ? (
                      <ButtonCommon
                        style={{
                          width: "100%",
                        }}
                        disabled={pendingApprove}
                        className="gray"
                        onClick={() => {
                          approveSwap();
                        }}
                      >
                        Approve ONI
                      </ButtonCommon>
                    ) : (
                      <ButtonCommon
                        disabled={loadingSwap}
                        onClick={() => {
                          if (
                            Number(!valueA) ||
                            Number(!valueB) ||
                            Number(valueA) === 0 ||
                            Number(valueB) === 0 ||
                            loadingSwap ||
                            Number(balanceA?.formatted.toString()) <
                              Number(valueA)
                          ) {
                            <></>;
                          } else {
                            handleSwap();
                          }
                        }}
                        className="green"
                      >
                        {loadingSwap ? (
                          <Spin />
                        ) : (
                          <>
                            {Number(balanceA?.formatted.toString()) <
                            Number(valueA)
                              ? "Not enough balance"
                              : Number(!valueA) ||
                                Number(!valueB) ||
                                Number(valueA) === 0 ||
                                Number(valueB) === 0
                              ? "Please enter value"
                              : "Swap"}
                          </>
                        )}
                      </ButtonCommon>
                    )}
                  </>
                )}
              </LiquidityActions>
            </LiquidityAddWrapper>
          </SwapForm>
        </SwapRight>
      </SwapWrapper>
    </SwapContainer>
  );
};

const perData = [
  {
    id: 1,
    value: 0.25,
    text: "25%",
  },
  {
    id: 2,
    value: 0.5,
    text: "50%",
  },
  {
    id: 3,
    value: 0.75,
    text: "75%",
  },
  {
    id: 4,
    value: 1,
    text: "100%",
  },
];

export default Swap;
