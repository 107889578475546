import { useEffect, useRef, useState } from "react";
import { createChart, CrosshairMode } from 'lightweight-charts';
import axios from "axios";
import moment from "moment";
import { convertFixed } from "../../../../utils/convertNumber";
import { ChartData, ChartMain, ChartWrapper, TooltipChart } from "../styled";
import LoadingCommon from "../../../Loading";

const ChartLine = ({ height, selectedTimeframe, selectedAggregate }: any) => {
  const chartContainerRef = useRef<any>();
  const chartRef = useRef<any>();
  const areaSeriesRef = useRef<any>();
  const tooltipRef = useRef<any>();
  const [chartData, setChartData] = useState<any>([]);
  const lastPriceRef = useRef<number | null>(null);
  const [percentageChange, setPercentageChange] = useState<number>(0);
  const [lastUpdated, setLastUpdated] = useState<string | null>(null);

  const fetchHistoricalData = async () => {
    try {
      // Call GeckoTerminal API to fetch ONI/WBNB pair data
      const response = await axios.get(`https://api.geckoterminal.com/api/v2/networks/bsc/pools/0x5aDF0C6446A4A50a1A8951aFA6F3bF998071242d/ohlcv/${selectedTimeframe}`, {
        params: {
          aggregate: selectedAggregate,
          limit: 500,
          currency: "usd",
        },
      });

      const prices = response.data.data.attributes.ohlcv_list;

      // Only extract the closing price for the line chart
      const formattedData = prices.map((point: any) => ({
        time: point[0] / 1000, // Timestamp
        value: point[4], // Close price
      }));

      // Ensure data is sorted in ascending order by time
      formattedData.sort((a: any, b: any) => a.time - b.time);

      if (formattedData.length > 0) {
        const currentPrice = formattedData[formattedData.length - 1].value;

        if (lastPriceRef.current !== null) {
          const previousPrice = lastPriceRef.current;
          const change = ((currentPrice - previousPrice) / previousPrice) * 100;
          setPercentageChange(change);
        }

        lastPriceRef.current = currentPrice;
      }

      setLastUpdated(moment().format('HH:mm A [on] DD/MM/YYYY'));

      setChartData(formattedData);
    } catch (error) {
      console.error('Error fetching OHLCV data for ONI/WBNB:', error);
    }
  };

  useEffect(() => {
    const fetchInterval = setInterval(() => {
      fetchHistoricalData();
    }, 10000);

    fetchHistoricalData();

    return () => clearInterval(fetchInterval);
  }, [selectedTimeframe, selectedAggregate]);

  useEffect(() => {
    const chart = createChart(chartContainerRef.current, {
      layout: {
        textColor: '#9F9F9F',
        background: { color: 'transparent' },
      },
      grid: {
        vertLines: {
          color: 'transparent',
        },
        horzLines: {
          color: 'transparent',
        },
      },
      crosshair: {
        mode: CrosshairMode.Normal,
      },
      timeScale: {
        borderColor: '#4E4E4E',
        rightOffset: 2,
        fixLeftEdge: true,
        fixRightEdge: true,
        tickMarkFormatter: (time: number) => {
          return moment(time * 1000).format('HH:mm');
        },
      },
      height: height || 450,
      width: chartContainerRef.current.clientWidth,
    });

    chartRef.current = chart;

    const areaSeries = chart.addAreaSeries({
      topColor: 'rgba(21, 211, 171, 0.25)',
      bottomColor: 'rgba(12, 128, 102, 0.1)',
      lineColor: '#15D3AB',
      lineWidth: 2,
      priceScaleId: 'right',
      priceLineVisible: true,
      priceFormat: {
        type: 'price',
        precision: 6,
        minMove: 0.000001,
      },
    });
    areaSeriesRef.current = areaSeries;

    const handleResize = () => {
      if (chartContainerRef.current) {
        const { width, height } = chartContainerRef.current.getBoundingClientRect();
        chart.applyOptions({ width, height });
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(chartContainerRef.current);

    chartContainerRef.current.addEventListener('mousemove', (event: MouseEvent) => {
      if (!tooltipRef.current) return;

      const boundingRect = chartContainerRef.current.getBoundingClientRect();
      const x = event.clientX - boundingRect.left;
      const y = event.clientY - boundingRect.top;

      const time: any = chart.timeScale().coordinateToTime(x);
      const price = areaSeries.coordinateToPrice(y);

      const closestDataPoint = chartData.reduce((prev: any, curr: any) => {
        return Math.abs(curr.time - time) < Math.abs(prev.time - time) ? curr : prev;
      }, chartData[0] || { time: 0, value: 0 });

      if (time && price !== null) {
        tooltipRef.current.style.display = 'block';
        tooltipRef.current.innerHTML = `ONI/WBNB: ${convertFixed(closestDataPoint.value)}`;
        tooltipRef.current.style.left = `${x}px`;
        tooltipRef.current.style.top = `${y}px`;
      } else {
        tooltipRef.current.style.display = 'none';
      }
    });

    chartContainerRef.current.addEventListener('mouseleave', () => {
      if (tooltipRef.current) {
        tooltipRef.current.style.display = 'none';
      }
    });

    return () => {
      resizeObserver.disconnect();
      chart.remove();
    };
  }, [height, selectedTimeframe, chartData]);

  useEffect(() => {
    if (areaSeriesRef.current && chartData.length > 0) {
      areaSeriesRef.current.setData(chartData);
      chartRef.current.timeScale().fitContent();
    }
  }, [chartData]);

  return (
    <ChartWrapper>
      <LoadingCommon />
      <TooltipChart ref={tooltipRef} />
      <ChartMain ref={chartContainerRef} />
      <ChartData>
        <div style={{ display: 'flex', alignItems: "center", gap: '10px', marginBottom: '5px' }}>
          <div style={{ display: 'flex', alignItems: "center" }}>
            <figure>
              <img width={30} height={30} src="./img/Common/oni_icon.png" alt="ONI icon" loading="lazy" />
            </figure>
            <figure>
              <img width={30} height={30} src="https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c/logo.png" alt="WBNB icon" loading="lazy" />
            </figure>
          </div>
          <p style={{
            marginBottom: "0"
          }}>{chartData.length > 0 ? convertFixed(chartData[chartData.length - 1].value) : 'Loading...'}</p>
        </div>
        <span className="color">{lastUpdated ? `Last updated: ${lastUpdated} ( Refresh every minute )` : 'Fetching data...'}</span>
        {percentageChange === 0 ? <span>There was nothing changed</span> : <span style={{ color: percentageChange > 0 ? '#59FF9E' : '#FF5252' }}>
          {percentageChange > 0 ? `Increased by ${percentageChange.toFixed(2)}%` : `Decreased by ${Math.abs(percentageChange).toFixed(2)}%`} since last minute.
        </span>}
      </ChartData>
    </ChartWrapper>
  );
};

export default ChartLine;
