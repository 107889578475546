import styled from "styled-components";
import { ButtonCommon } from "../../../Layout/styled";

export const LiquidityRemoveAction = styled.div`
  display: flex;
  ${ButtonCommon} {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 16px 20px;
  }
`;
