import styled from "styled-components";
import { ButtonCommon } from "../../Layout/styled";
import { LiqFrame, LiqFrameHeader } from "../Liquidity/Add/styled";
import { ProgressContainer } from "../../components/Common/Progress/styled";

export const LaunchpadContainer = styled.div``;
export const LaunchpadWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  @media screen and (max-width: 1023px) {
    flex-direction: column;
  }
`;
export const LaunchpadLeft = styled.div`
  width: 100%;
`;
export const LaunchpadRight = styled.div`
  max-width: 476px;
  width: 100%;
`;
export const LaunchTag = styled.div`
  width: max-content;
  padding: 2px 10px;
  text-align: center;
  font-size: 12px;
  border-radius: 4px;
  margin-bottom: 10px;
`;
export const LaunchpadListItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 33.33%;
  min-height: 30vw;
  padding: 16px;
  border-radius: 6px;
  border: 1px solid transparent;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    background: url("./img/Dashboard/Launchpad/launchpad_item_bg.png") no-repeat
      center / 100% auto;
    width: 100%;
    height: 586px;
    opacity: 0.1;
    pointer-events: none;
  }
  &.round-1 {
    border-color: rgb(206, 255, 209, 0.5);
    background: linear-gradient(
      180deg,
      rgba(99, 255, 128, 0.36) 0%,
      rgba(53, 131, 67, 0.36) 100%
    );
    ${LaunchTag} {
      color: #000;
      background: linear-gradient(180deg, #ceffd1 0%, #4ec575 100%);
    }
  }
  &.round-2 {
    background: rgba(48, 248, 255, 0.19);
    ${LaunchTag} {
      background: #529497;
      color: #fff;
    }
  }
  &.round-3 {
    background: rgba(113, 255, 236, 0.11);
    ${LaunchTag} {
      background: #456f6c;
      color: #fff;
    }
  }
  ${ButtonCommon} {
    display: flex;
    justify-content: center;
    width: calc(100% - 35px);
    padding: 16px;
  }
  @media screen and (max-width: 767px) {
    width: calc(100% - 110px);
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    width: 366px;
  }
  @media screen and (max-width: 1023px) {
    ${ProgressContainer} {
      max-width: unset;
      width: calc(100% - 25px);
    }
    & > div {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }
    min-height: 346px;
    margin: 0 auto;
    border: 1px solid #ceffd1;
    background: linear-gradient(
      180deg,
      rgba(99, 255, 128, 0.19) 0%,
      rgba(53, 131, 67, 0.19) 100%
    );
    backdrop-filter: blur(27px);
    overflow: hidden;
    &::before {
      z-index: 3;
      height: 296px;
    }
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      background: url("./img/Gif/launchpad_bg_mobile.gif") no-repeat center /
        cover;
      width: 100%;
      height: 120%;
      opacity: 0.1;
      pointer-events: none;
      z-index: 1;
    }
    &.round-1 {
      background: linear-gradient(180deg, #1c3a22 0%, #1d4a26 100%);
    }
    &.round-2 {
      background: #1e5355;
    }
    &.round-3 {
      background: #07423a;
    }
  }
`;
export const LaunchpadList = styled.div`
  display: flex;
  gap: 8px;
  @media screen and (max-width: 1023px) {
    display: block;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    width: 400px;
    margin: 0 auto;
  }
`;
export const LaunchHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
  & > div {
    h2 {
      color: #fff;
      font-size: 24px;
      font-weight: 600;
      text-transform: capitalize;
    }
  }
  & > p {
    padding: 2px 8px;
    border-radius: 6px;
    background: #22c544;
    color: #052e0f;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
  }
  @media screen and (max-width: 1023px) {
    margin-bottom: 120px;
  }
`;
export const LaunchBody = styled.div`
  & > p {
    color: #e4e4e4;
    font-size: 16px;
    font-weight: 500;
    line-height: 125%;
    margin-bottom: 15px;
  }
  & > div {
    display: flex;
    align-items: center;
    border-radius: 9px;
    padding: 5px 20px 5px 5px;
    margin-bottom: 10px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: rgba(40, 40, 40, 0.7);
    background-blend-mode: luminosity;
    box-shadow: 2px 4px 16px 0px rgba(248, 248, 248, 0.06) inset;
    backdrop-filter: blur(50px);
    & > p {
      margin: 0 auto;
    }
    & > div {
      display: flex;
      align-items: center;
      padding: 10px;
      width: max-content;
      gap: 10px;
      border-radius: 6px;
      border: 2px solid rgba(255, 204, 0, 0.12);
      background: rgba(31, 31, 31, 0.54);
      box-shadow: 0px 0px 8px 0px rgba(248, 248, 248, 0.6) inset;
      margin-right: 10px;
      p {
        color: #d3d3d3;
        font-size: 13px;
        font-weight: 600;
      }
    }
  }
  @media screen and (max-width: 1023px) {
    & > p {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
`;
export const LaunchpadFunding = styled.div`
  ${LiqFrameHeader} {
    text-align: right;
    & > div {
      &:nth-child(2) {
        & > p {
          color: #9f9f9f;
        }
      }
    }
  }
  ${LiqFrame} {
    margin-bottom: 15px;
  }
  @media screen and (max-width: 1023px) {
    width: 100%;
    ${LiqFrame} {
      margin-bottom: 0;
    }
  }
`;
export const FundingInfo = styled.div`
  p {
    padding: 2px 10px;
    width: max-content;
    border-radius: 4px;
    background: linear-gradient(180deg, #ceffd1 0%, #4ec575 100%);
    color: #000;
    text-align: center;
    font-size: 12px;
    margin-bottom: 10px;
  }
  h2 {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
  }
`;
