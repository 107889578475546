import styled from "styled-components";

export const DashboardNotiContainer = styled.div`
  position: relative;
  background: url("/img/Dashboard/bg_noti.png") center / cover no-repeat;
  height: 45px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin: -20px -20px 15px;
  .icon-noti {
    position: relative;
    top: 3px;
  }
  p {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
  }
`;
