import {
  BtnMobileMenu,
  HeaderActions,
  HeaderBody,
  HeaderLinkGroup,
  HeaderLogo,
  HeaderNavigation,
  HeaderWrapper,
  MenuBox,
  MenuContainer,
  MenuMobile,
} from "./styled";
import { Link, useLocation } from "react-router-dom";
import { ButtonCommon } from "../../Layout/styled";
import { ContextProviderWrapper } from "../Context";
import { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { shortenAddress } from "../../utils/addressUser";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount } from "wagmi";
import { Menu, MenuProps } from "antd";
import "./index.css";

type MenuItem = Required<MenuProps>["items"][number];

const items: MenuItem[] = [
  {
    key: "dashboard",
    label: <Link to="/">Dashboard</Link>,
  },
  {
    label: <Link to="/liquidity">Liquidity</Link>,
    key: "liquidity",
    icon: <></>,
  },
  {
    label: <Link to="/mining">Mining</Link>,
    key: "mining",
    icon: <></>,
  },
  {
    label: <Link to="/network">Network</Link>,
    key: "network",
    icon: <></>,
  },
  {
    label: <Link to="/swap">Swap</Link>,
    key: "swap",
    icon: <></>,
  },
  {
    label: <Link to="/pool">Pool</Link>,
    key: "pool",
    icon: <></>,
  },
  {
    label: "Wallet",
    key: "SubMenu",
    icon: true,
    popupClassName: "sub-menu",
    children: [
      {
        type: "group",
        label: "Item 1",
        children: [
          { label: <Link to="/withdraw">Withdraw</Link>, key: "withdraw" },
          { label: <Link to="/transfer">Transfer</Link>, key: "transfer" },
        ],
      },
    ],
  },
];

const Header = () => {
  const { open } = useWeb3Modal();
  const { address } = useAccount();
  const { isDesktop } = useContext(ContextProviderWrapper)!;
  const [showMore, setShowMore] = useState(false);
  const [isMenuMobile, setIsMenuMobile] = useState(false);
  const { pathname } = useLocation();

  const linkData = [
    {
      title: "Dashboard",
      href: "/",
      scrollTo: false,
      externalLink: false,
      icon: "/img/Common/sp/icn_navigation_01.png",
      animationDuration: "0.1s",
    },
    {
      title: "Liquidity",
      href: "/liquidity",
      scrollTo: false,
      externalLink: false,
      icon: "/img/Common/sp/icn_navigation_02.png",
      animationDuration: "0.3s",
    },
    {
      title: "Mining",
      href: "/mining",
      scrollTo: false,
      externalLink: false,
      icon: "/img/Common/sp/icn_navigation_03.png",
      animationDuration: "0.4s",
    },
    {
      title: "Network",
      href: "/network",
      scrollTo: false,
      externalLink: false,
      icon: "/img/Common/sp/icn_navigation_06.png",
      animationDuration: "0.5s",
    },
    {
      title: "Swap",
      href: "/swap",
      scrollTo: false,
      externalLink: false,
      icon: "/img/Common/sp/icn_navigation_04.png",
      animationDuration: "0.6s",
    },
    {
      title: "Pool",
      href: "/pool",
      scrollTo: false,
      externalLink: false,
      icon: "/img/Common/sp/icn_navigation_11.png",
      animationDuration: "0.9s",
    },
    {
      animationDuration: "1s",
      title: "Wallet",
      key: "SubMenu",
      icon: "/img/Common/sp/icn_navigation_10.png",
      children: [
        {
          type: "group",
          label: "Item 1",
          children: [
            { label: <Link to="/withdraw">Withdraw</Link>, key: "withdraw" },
            { label: <Link to="/transfer">Transfer</Link>, key: "transfer" },
          ],
        },
      ],
    },
  ];

  //
  const [current, setCurrent] = useState("dashboard");
  const [activeItem, setActiveItem] = useState<string | null>(null);
  const onClick: MenuProps["onClick"] = (e) => {
    setCurrent(e.key);
  };

  const showMenuMobile = (itemTitle: string) => {
    console.log(itemTitle);
    if (itemTitle === "Wallet") {
      setActiveItem(itemTitle); // Activate Wallet only when clicked
      setIsMenuMobile(true);
    } else {
      setActiveItem(null); // Deactivate Wallet
      setIsMenuMobile(false);
    }
  };

  // Reset on close
  const closeMenuMobile = () => {
    setIsMenuMobile(false);
    setActiveItem(null);
  };

  useEffect(() => {
    if (isMenuMobile) {
      document.body.classList.add("hidden");
    } else {
      document.body.classList.remove("hidden");
    }
  }, [isMenuMobile]);


  return (
    <>
      {isDesktop ? (
        <HeaderBody>
          <HeaderWrapper>
            <HeaderLogo>
              <Link to="/">
                <img
                  width={176}
                  height={37}
                  src="./img/Common/logo.png"
                  alt="logo"
                  loading="lazy"
                />
              </Link>
            </HeaderLogo>
            <HeaderNavigation>
              {/* {linkData.map((item, index) => {
                return (
                  <li key={index}>
                    <NavLink to={item.href}>{item.title}</NavLink>
                  </li>
                );
              })} */}
              <Menu
                onClick={onClick}
                selectedKeys={[current]}
                mode="horizontal"
                items={items}
                triggerSubMenuAction="click"
              />
            </HeaderNavigation>
            <HeaderActions>
              <ButtonCommon className="yellow">
                <p>
                  <img
                    width={24}
                    height={24}
                    src="./img/Common/bnb_logo.svg"
                    alt="icon"
                    loading="lazy"
                  />
                </p>
                BNB Chain
              </ButtonCommon>
              <ButtonCommon
                className="green"
                onClick={() => {
                  open();
                }}
              >
                <p>{address?.slice(0, 2) || "ONI"}</p>
                {address
                  ? shortenAddress(address).slice(2, 100)
                  : "Connect Wallet"}
              </ButtonCommon>
            </HeaderActions>
          </HeaderWrapper>
        </HeaderBody>
      ) : (
        <HeaderBody>
          <HeaderWrapper>
            <HeaderLogo>
              <Link to="/">
                <img
                  width={46}
                  height={53}
                  src="./img/Common/sp/logo.png"
                  alt="logo"
                  loading="lazy"
                />
              </Link>
            </HeaderLogo>
            <HeaderActions>
              <ButtonCommon className="yellow">
                <p>
                  <img
                    width={24}
                    height={24}
                    src="./img/Common/bnb_logo.svg"
                    alt="icon"
                    loading="lazy"
                  />
                </p>
              </ButtonCommon>
              <ButtonCommon
                className="green"
                onClick={() => {
                  open();
                }}
              >
                <p>{address?.slice(0, 2) || "ONI"}</p>
                {address
                  ? shortenAddress(address).slice(2, 100)
                  : "Connect Wallet"}
              </ButtonCommon>
            </HeaderActions>
            <BtnMobileMenu
              onClick={() => {
                setIsMenuMobile(!isMenuMobile);
              }}
              isshow={isMenuMobile}
            >
              <span></span>
              <span></span>
              <span></span>
            </BtnMobileMenu>
            <MenuContainer
              isshow={isMenuMobile}
              onClick={() => {
                setIsMenuMobile(false);
              }}
            >
              <MenuBox isshow={isMenuMobile}>
                {linkData.slice(0, 6).map((item: any, index) => {
                  return (
                    <li key={index}>
                      <NavLink to={item.href}>
                        <figure>
                          <img
                            width={29}
                            height={29}
                            src={item.icon}
                            alt="icon"
                            loading="lazy"
                          />
                        </figure>
                        {item.title}
                      </NavLink>
                    </li>
                  );
                })}
                <li>
                  <NavLink to={"/withdraw"}>
                    <figure>
                      <img
                        width={29}
                        height={29}
                        src="/img/Common/sp/icn_navigation_10.png"
                        alt="icon"
                        loading="lazy"
                      />
                    </figure>
                    Withdraw
                  </NavLink>
                </li>
                <li>
                  <NavLink to={"/transfer"}>
                    <figure>
                      <img
                        width={29}
                        height={29}
                        src="/img/Common/sp/icn_navigation_10.png"
                        alt="icon"
                        loading="lazy"
                      />
                    </figure>
                    Transfer
                  </NavLink>
                </li>
              </MenuBox>
            </MenuContainer>
          </HeaderWrapper>
          {/* <HeaderNavigation>
            {isDesktop ? (
              <>
                {linkData.map((item: any, index) => {
                  return (
                    <li key={index}>
                      <NavLink to={item.href}>{item.title}</NavLink>
                    </li>
                  );
                })}
              </>
            ) : (
              <>
                {linkData
                  .slice(showMore ? 4 : 0, showMore ? 8 : 4)
                  .map((item: any, index) => {
                    return (
                      <li key={index}>
                        {item.title !== "Wallet" ? (
                          <NavLink
                            to={item.href}
                            style={{
                              animationDuration: `${item.animationDuration}`,
                            }}
                            className={
                              showMore ? "animation-more" : "animation-less"
                            }
                            onClick={() =>
                              item.title === "Wallet"
                                ? showMenuMobile(item.title)
                                : closeMenuMobile()
                            }
                          >
                            <img
                              width={29}
                              height={29}
                              src={item.icon}
                              alt="icon"
                              loading="lazy"
                            />
                            {item.title}
                          </NavLink>
                        ) : (
                          <div
                            style={{
                              animationDuration: `${item.animationDuration}`,
                            }}
                            className={`sub-menu ${
                              showMore ? "animation-more" : "animation-less"
                            } ${
                              pathname === "/transfer" ||
                              pathname === "/withdraw"
                                ? "active"
                                : ""
                            }`}
                            onClick={() =>
                              item.title === "Wallet"
                                ? showMenuMobile(item.title)
                                : closeMenuMobile()
                            }
                          >
                            <img
                              width={29}
                              height={29}
                              src={item.icon}
                              alt="icon"
                              loading="lazy"
                            />
                            {item.title}
                          </div>
                        )}
                        {item.children && (
                          <MenuMobile
                            className={
                              isMenuMobile && activeItem === "Wallet"
                                ? "is-show"
                                : ""
                            }
                          >
                            <Link
                              onClick={closeMenuMobile}
                              to="/withdraw"
                              className={
                                pathname === "/withdraw" ? "active-submenu" : ""
                              }
                            >
                              Withdraw
                            </Link>
                            <Link
                              onClick={closeMenuMobile}
                              to="/transfer"
                              className={
                                pathname === "/transfer" ? "active-submenu" : ""
                              }
                            >
                              Transfer
                            </Link>
                          </MenuMobile>
                        )}
                      </li>
                    );
                  })}
              </>
            )}
            {!isDesktop && (
              <li
                onClick={() => {
                  setShowMore(!showMore);
                }}
              >
                <NavLink to="#">
                  <img
                    src="./img/Common/sp/icn_navigation_05.png"
                    alt="icon"
                    loading="lazy"
                  />
                  {showMore ? "Back" : "More"}
                </NavLink>
              </li>
            )}
          </HeaderNavigation> */}
        </HeaderBody>
      )}
    </>
  );
};

export default Header;
