import styled from "styled-components";

export const MemberDesktopContainer = styled.div`
  display: flex;
  .data-row {
    width: calc(100% / 5);
    text-align: center;
    .data-text {
      color: #616161;
      font-size: 12px;
    }
    .data-value {
      color: #fff;
      font-size: 15px;
      letter-spacing: -0.3px;
    }
  }
  @media screen and (max-width: 1024px) {
    .data-row {
      .data-text {
        font-size: 11px;
      }
      .data-value {
        font-size: 13px;
      }
    }
  }
`;
