import styled from "styled-components";

export const TitleWrapper = styled.div`
  margin-bottom: 20px;
  h2 {
    font-family: "Rubik", sans-serif;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 5px;
    @media screen and (max-width: 767.98px) {
      font-size: 18px;
    }
  }
  p {
    color: #9f9f9f;
    font-size: 13px;
  }
  @media screen and (max-width: 1023px) {
    h2 {
      white-space: nowrap;
    }
  }
`;
