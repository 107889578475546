import { LoadingWrapper } from "./styled"

const LoadingCommon = ({ className }: any) => {
  return (
    <LoadingWrapper className={className}>
      <img
        width={68}
        height={68}
        src="./img/Dashboard/Bot/onibot_icon.png"
        alt="icon"
        loading="lazy"
      />
    </LoadingWrapper>
  )
}

export default LoadingCommon