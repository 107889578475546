import HintCommon from "../../../components/Common/Hint";
import InputCommon from "../../../components/Common/Input";
import ProfileCommon from "../../../components/Common/Profile";
import { ButtonCommon } from "../../../Layout/styled";
import { LiqFrame, LiqFrameHeader, LiqPerList, LiquidityActions, LiquidityAddWrapper, LiquidityFrame } from "../../Liquidity/Add/styled";

const MiningClaim = () => {
  return (
    <LiquidityAddWrapper>
      <LiquidityFrame>
        <LiqFrame>
          <LiqFrameHeader>
            <ProfileCommon
              avatar="./img/Common/oni_icon.png"
              name="ONI"
              subName="BNB Smart Chain"
            />
            <div>
              <p>Your Balance</p>
              <span>1,298,032.09051</span>
            </div>
          </LiqFrameHeader>
          <InputCommon placeHolder="0.00" />
          <LiqPerList>
            {perData.map((item, index) => {
              return (
                <li key={index}>
                  <p>{item.value}%</p>
                </li>
              );
            })}
          </LiqPerList>
        </LiqFrame>
      </LiquidityFrame>
      <LiquidityActions>
        <ButtonCommon className="green">Claim</ButtonCommon>
      </LiquidityActions>
      <HintCommon />
    </LiquidityAddWrapper>
  )
}

const perData = [
  {
    id: 1,
    value: 25,
  },
  {
    id: 2,
    value: 50,
  },
  {
    id: 3,
    value: 75,
  },
  {
    id: 4,
    value: 100,
  },
];

export default MiningClaim