import styled from "styled-components";
import { TitleWrapper } from "../../components/Common/Title/styled";
import { ButtonCommon } from "../../Layout/styled";
import { ChartSmallWrapper } from "../../components/Common/ChartSmall/styled";

export const OverviewContainer = styled.div`
  position: relative;
  z-index: 5;
  ${TitleWrapper} {
    margin-bottom: 0;
  }
`;
export const OverviewHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 35px;
  padding: 30px 0;
  margin-bottom: 60px;
  ${ButtonCommon} {
    padding: 16px 32px;
  }
  & > figure {
    height: 57px;
  }
  @media screen and (max-width: 767.98px) {
    margin-bottom: 0px;
    padding: 0 0 20px;
    ${ButtonCommon} {
      padding: 16px 10px;
      font-size: 14px;
    }
    & > figure {
      height: 32px;
      img {
        width: auto;
        height: 100%;
      }
    }
  }
`;
export const OverviewBody = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  max-width: 1300px;
  margin: 0 auto;
  @media screen and (max-width: 1115px) {
    flex-direction: column;
  }
`;
export const OverviewLeft = styled.div`
  width: calc(100% - 503px);
  @media screen and (max-width: 1115px) {
    width: 100%;
  }
`;
export const OverviewRight = styled.div`
  max-width: 503px;
  width: 100%;
  flex-shrink: 0;
  @media screen and (max-width: 1115px) {
    width: 100%;
    max-width: unset;
  }
  @media screen and (max-width: 767.98px) {
    ${ChartSmallWrapper} {
      top: 50%;
      bottom: unset;
      transform: translateY(-50%);
    }
  }
`;
export const OverviewTime = styled.div`
  text-align: right;
  margin-bottom: 15px;
  margin-top: -50px;
  p {
    color: #9f9f9f;
    font-size: 13px;
    font-weight: 400;
  }
  span {
    color: #fff;
    font-size: 15px;
    font-weight: 500;
  }
  @media screen and (max-width: 1115px) {
    display: none;
  }
`;
export const OverviewValue = styled.div`
  text-align: right;
  p {
    font-size: 28px;
    background: linear-gradient(180deg, #ceffd1 0%, #4ec575 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  span {
    color: #9f9f9f;
    font-size: 13px;
    font-weight: 400;
  }
`;
export const OverviewFrame = styled.div`
  padding: 16px 20px;
  border-radius: 8px;
  border: 1px solid rgb(61, 61, 61, 0.1);
  background: linear-gradient(
    180deg,
    rgba(91, 118, 100, 0.2) 0%,
    rgba(21, 22, 23, 0.2) 100%
  );
  backdrop-filter: blur(7.5px);
  &.small {
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    ${TitleWrapper} {
      margin-bottom: 30px;
      h2 {
        font-size: 22px;
        white-space: normal;
      }
    }
    ${OverviewValue} {
      text-align: left;
    }
  }
  @media (min-width: 768px) and (max-width: 1115px) {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
  @media screen and (max-width: 767.98px) {
    padding: 12px;
    margin-bottom: 15px;
    ${TitleWrapper} {
      h2 {
        font-size: 15px !important;
      }
    }
  }
`;
export const OverviewFrameTop = styled.div`
  .over-view-inner {
    margin-bottom: 10px;
  }
  @media screen and (max-width: 767.98px) {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 10px;
    .over-view-inner {
      width: calc(100% / 2 - 5px);
      &:nth-child(1) {
        min-height: 390px;
        border-radius: 6px;
        background: linear-gradient(
          180deg,
          rgba(99, 255, 128, 0.19) 0%,
          rgba(53, 131, 67, 0.19) 100%
        );
        margin-bottom: 10px;
        ${OverviewFrame} {
          height: calc(100% - 24px);
          margin-bottom: 0;
        }
      }
      &:nth-child(2) {
        min-height: 191px;
        ${OverviewFrame} {
          height: calc(100% / 2 - 30px);
          margin-bottom: 0;
          &:nth-child(1) {
            margin-bottom: 10px;
            border-radius: 6px;
            background: linear-gradient(
              180deg,
              rgba(94, 233, 201, 0.28) 0%,
              rgba(53, 131, 113, 0.28) 100%
            );
          }
          &:nth-child(2) {
            border-radius: 6px;
            background: linear-gradient(
              180deg,
              rgba(97, 97, 97, 0.22) 0%,
              rgba(199, 199, 199, 0.22) 100%
            );
          }
        }
      }
    }
  }
`;
export const OverviewFrameBottom = styled.div`
  @media screen and (max-width: 767.98px) {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    ${OverviewFrame} {
      width: calc(100% / 2 - 10px);
      height: 165px;
      &:nth-child(1) {
        border-radius: 6px;
        background: rgba(48, 248, 255, 0.19);
      }
      &:nth-child(2) {
        border-radius: 6px;
        background: rgba(217, 255, 113, 0.11);
      }
    }
  }
`;
export const OverviewFrameHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
  margin-bottom: 20px;
`;
export const OvewrviewTextUpdate = styled.div`
  p {
    display: flex;
    gap: 5px;
    align-items: center;
    color: #9f9f9f;
    font-size: 13px;
    font-weight: 400;
  }
  span {
    color: #fff;
    font-size: 15px;
    font-weight: 500;
  }
`;
export const OverviewChart = styled.div`
  margin-top: 10px;
`;
export const OverviewSmallLeft = styled.div`
  @media screen and (max-width: 1024px) {
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
`;
export const OverviewSmallRight = styled.div``;
