
export const miningDataDashboard = [
  {
    id: 1,
    imgFrom: "./img/Dashboard/mining/mining_1.png",
    valueFrom: 100,
    valueTo: 999,
    textFrom: "$100",
    textTo: "$999",
    pair: "Nebula V100-Node1",
    tvl: "$543,673.65",
    apr: "48%",
    reward: "$0.0000",
    icon: "./img/Common/level/level_1.png"
  },
  {
    id: 2,
    imgFrom: "./img/Dashboard/mining/mining_2.png",
    valueFrom: 1000,
    valueTo: 4999,
    textFrom: "$1,000",
    textTo: "$4,999",
    pair: "Hawking DataProc-01",
    tvl: "$2,432,653.67",
    apr: "84%",
    reward: "$564.43",
    icon: "./img/Common/level/level_2.png"
  },
  {
    id: 3,
    imgFrom: "./img/Dashboard/mining/mining_3.png",
    valueFrom: 5000,
    valueTo: 9999,
    textFrom: "$5,000",
    textTo: "$9,999",
    pair: "Blackhol Mining Node-2",
    tvl: "$3,232,453.54",
    apr: "108%",
    reward: "$0.0000",
    icon: "./img/Common/level/level_3.png"
  },
  {
    id: 4,
    imgFrom: "./img/Dashboard/mining/mining_4.png",
    valueFrom: 10000,
    valueTo: 29999,
    textFrom: "$10,000",
    textTo: "$29,999",
    pair: "Data-Processing1 Mining",
    tvl: "$2,642,879.92",
    apr: "144%",
    reward: "$0.0000",
    icon: "./img/Common/level/level_4.png"
  },
  {
    id: 5,
    imgFrom: "./img/Dashboard/mining/mining_5.png",
    valueFrom: 30000,
    valueTo: 1000000000000000,
    textFrom: "",
    textTo: "More than $30,000",
    pair: "Pulsar Inference-Node2",
    tvl: "$5,890,651.63",
    apr: "240%",
    reward: "$0.0000",
    icon: "./img/Common/level/level_5.png"
  },
];