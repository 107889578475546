import styled from "styled-components";

export const ListStake = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  li {
    width: calc(100% / 3 - 4px);
  }
  a {
    width: calc(100% - 20px);
    color: #C7C7C7;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    border-radius: 4px;
    border: 1px solid var(--stroke-25-button, rgba(255, 255, 255, 0.40));
    background: rgba(92, 92, 92, 0.70);
    background-blend-mode: luminosity;
    box-shadow: 2px 4px 16px 0px rgba(248, 248, 248, 0.06) inset;
    backdrop-filter: blur(50px);
    padding: 6px 8px;
  }
`
export const StakeCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  .title-card {
    color: #FFF;
    font-size: 18px;
    font-weight: 500;
  }
  .card-apr {
    color: #9F9F9F;
    font-size: 15px;
    font-weight: 500;
    span {
      color: #81EE97;
      font-size: 15px;
      font-weight: 500;
    }
  }
  .card-right {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .title-right {
    color: #FFF;
    font-size: 16px;
    font-weight: 700;
  }
  .text-right {
    color: #FFF;
    font-size: 13px;
    font-weight: 500;
  }
`
export const BlockInput = styled.div`
  height: 82px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
`
export const ConverPrice = styled.p`
  color: #9F9F9F;
  font-size: 15px;
`
export const TextNote = styled.p`
  color: #9F9F9F;
  font-size: 13px;
  text-align: center;
  span {
    position: relative;
    padding-left: 18px;
    &:before {
      position: absolute;
      content: "";
      top: 50%;
      margin-top: -7px;
      left: 0;
      background: url('/img/Dashboard/mining/icn_warning.png') center / cover no-repeat;
      width: 14px;
      height: 14px;
    }
  }
`