import styled from "styled-components";
import { InputContainer } from "../../components/Common/Input/styled";

export const ToWalletAddress = styled.div`
  ${InputContainer} {
    border-radius: 5px;
    border: 1px solid #464646;
    background: #272727;
    padding: 10px 12px;
    width: calc(100% - 24px);
    input {
      font-size: 14px;
      &::placeholder {
        color: #5b5b5b;
        font-family: Rubik;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
`;
