import styled from "styled-components";

export const LiveChatContainer = styled.div`
  position: fixed;
  bottom: 15px;
  right: 20px;
  color: #fff;
  z-index: 11;
  @media screen and (max-width: 1023px) {
    bottom: 100px;
  }
`;
export const ContentCircle = styled.div`
  width: 66px;
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  &:after {
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: url("/img/Common/icn_support_02.png") center / cover no-repeat;
    width: 100%;
    height: 100%;
    animation: animationRotate 4s linear infinite;
    pointer-events: none;
    @keyframes animationRotate {
      0% {
        transform: translate(-50%, -50%) rotate(0);
      }
      100% {
        transform: translate(-50%, -50%) rotate(360deg);
      }
    }
  }
  .circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .CircularProgressbar .CircularProgressbar-trail {
    stroke: transparent;
  }
`;
export const LogoSupport = styled.button`
  border: 0;
  outline: none;
  background: unset;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  top: 1px;
`;
export const BlockGetName = styled.div`
  width: 353px;
  min-height: 500px;
  height: auto;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #1f1f1f;
  background: linear-gradient(
      180deg,
      rgba(97, 97, 97, 0.22) 0%,
      rgba(69, 40, 40, 0.22) 100%
    ),
    linear-gradient(180deg, #0a0c0d 0%, #151617 100%);
  position: absolute;
  bottom: 82px;
  right: 0;
  transition: all 0.26s ease;
  transform: translateX(110%);
  &.is-show {
    transform: translateX(0);
  }
  @media screen and (max-width: 1023px) {
    width: calc(100vw - 67px);
    min-height: 400px;
  }
`;
export const BlockGetNameInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title {
    color: #fff;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    text-transform: capitalize;
  }
  .description {
    color: var(--Sub-light-text, #9f9f9f);
    font-size: 15px;
    font-style: normal;
  }
`;
export const BlockText = styled.div`
  border-radius: 8px;
  background: url("/img/Common/support_img_01.png") center / cover no-repeat;
  min-height: 336px;
  padding: 12px;
  position: relative;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  .telegram {
    color: #fff;
    margin-bottom: 6px;
    display: block;
  }
  .react_tinylink_card {
    background-color: unset;
    color: #000;
    border: unset;
    background: linear-gradient(135deg, #a8d5ba, #d9e897);
    border-radius: 8px;
  }
  .react_tinylink_card_content_header {
    flex-grow: unset;
  }
  .react_tinylink_card_content {
    line-height: 1;
    margin: 0;
    flex-grow: unset;
  }
  .react_tinylink_card {
    height: 85px;
  }
  p {
    text-align: right;
    width: 100%;
  }
  .question {
    margin-left: auto;
  }
  .answer {
    text-align: left;
  }
  .no-content {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -87px;
    margin-left: -73px;
  }
  @media screen and (max-width: 1023px) {
    min-height: 246px;
  }
`;

export const BlockSendMessage = styled.div`
  border-radius: 6px;
  background: #202224;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  .content-left {
    width: 80%;
  }
  .title-message {
    color: #fff;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
  }
  input {
    height: 28px;
    font-size: 14px !important;
    width: 100%;
    &::placeholder {
      color: #636363;
      font-size: 12px;
      position: relative;
      top: -2px;
    }
  }
`;
export const ButtonSent = styled.button`
  outline: none;
  background-color: unset;
  border: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
  border-radius: 6px;
  border: 1.5px solid #bbf7c6;
  background: radial-gradient(
    44.11% 44.11% at 50% 52.87%,
    rgba(129, 238, 151, 0) 0%,
    rgba(129, 238, 151, 0.2) 70%
  );
  box-shadow: 0px 0px 8px 0px rgba(248, 248, 248, 0.6) inset,
    0px 8px 24px -16px rgba(0, 0, 0, 0.4);
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
