import { BackContainer } from "./styled"

const BackCommon = ({ backFunction }: any) => {
  return (
    <BackContainer onClick={backFunction}>
      <figure>
        <img width={32} height={32} src="./img/Common/back_icon.png" alt="icon" loading="lazy" />
      </figure>
      <p>Go back</p>
    </BackContainer>
  )
}

export default BackCommon