import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const authLink = setContext(async (_, { headers }) => {
  // const auth = getAuth();
  try {
    // const user = auth.currentUser;
    // if (user) {
    //   const idToken = await user.getIdToken();
    //   if (idToken) {
    //     dispatch(IsLoadingRedux(true));
    //     return {
    //       headers: {
    //         ...headers,
    //         Authorization: `${idToken}`,
    //       },
    //     };
    //   }
    // }
  } catch (error) {
    console.error("Error retrieving idToken:", error);
  }

//   const storedToken = localStorage.getItem("idToken");
//   if (storedToken) {
//     const decodedToken = jwtDecode(storedToken);
//     const currentTime = Math.floor(Date.now() / 1000);
//     if (decodedToken.exp && decodedToken.exp < currentTime) {
//       localStorage.removeItem("idToken");
//       signOut(auth)
//       try {
//         const refreshedToken = await auth?.currentUser?.getIdToken(true);
//         if (refreshedToken) {
//           localStorage.setItem("idToken", refreshedToken);
//           return {
//             headers: {
//               ...headers,
//               Authorization: `${refreshedToken}`,
//             },
//           };
//         }
//       } catch (refreshError) {
//         console.error("Error refreshing idToken:", refreshError);
//       }
//     } else {
//       return {
//         headers: {
//           ...headers,
//           Authorization: `${storedToken}`,
//         },
//       };
//     }
//   }
//   return { headers };
});

export const apiHttp = "https://graph.oniprotocol.io/graphql"
// export const apiHttp = "https://oni.b2bwao.com/graphql"

const httpLink = createHttpLink({
  uri: apiHttp,
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {},
      },
      Mutation: {
        fields: {},
      },
    },
  }),
});
