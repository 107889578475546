import { ApolloClient, gql, HttpLink, InMemoryCache } from "@apollo/client";
import { Dispatch, createSlice } from "@reduxjs/toolkit";
import { apiHttp } from "../../services/graphApi";
import { ConnectorUnavailableReconnectingError } from "wagmi";

// Configure Apollo Client
const client = new ApolloClient({
  link: new HttpLink({ uri: apiHttp }),
  cache: new InMemoryCache(),
});

const GET_PRICE_TOKEN: any = gql`
  query {
    settings {
      tag
      config
    }
  }
`;
const GET_SETTING: any = gql`
  query GetSetting($tag: String!) {
    setting(tag: $tag) {
      tag
      config
    }
  }
`;

interface DriverState {
  userProfile: any;
  tokenPrice: any;
  usdtPrice: any;
  setting: any;
  isLoading: any;
  poolInfo: any;
}

const initialState: DriverState = {
  userProfile: null,
  poolInfo: null,
  tokenPrice: null,
  usdtPrice: null,
  setting: null,
  isLoading: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    getUserProfile(state, action) {
      state.userProfile = action.payload;
    },
    getPoolInfo(state, action) {
      state.poolInfo = action.payload;
    },
    getTokenPrice(state, action) {
      state.tokenPrice = action.payload;
    },
    getUSDTPrice(state, action) {
      state.usdtPrice = action.payload;
    },
    getSetting(state, action) {
      state.setting = action.payload;
    },
    isLoadingSuccess(state, action) {
      state.isLoading = action.payload;
    },
  },
});

export function UserOverview(data: any) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(userSlice.actions.getUserProfile(data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function PoolInfo(data: any) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(userSlice.actions.getPoolInfo(data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function USDTPrice(data: any) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(userSlice.actions.getUSDTPrice(data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function IsLoadingRedux(data: any) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(userSlice.actions.isLoadingSuccess(data));
    } catch (error) {
      console.log(error);
    }
  };
}

// Async thunk to fetch token price
export function fetchTokenPrice() {
  return async (dispatch: Dispatch) => {
    dispatch(userSlice.actions.isLoadingSuccess(true)); // Set loading state
    try {
      const response = await client.query({
        query: GET_PRICE_TOKEN,
      });
      dispatch(userSlice.actions.getTokenPrice(response.data.settings));
    } catch (error) {
      console.log(error);
      dispatch(userSlice.actions.getTokenPrice(null)); // Handle error
    } finally {
      dispatch(userSlice.actions.isLoadingSuccess(false)); // Reset loading state
    }
  };
}

export function fetchSetting(tag: string) {
  return async (dispatch: Dispatch) => {
    dispatch(userSlice.actions.isLoadingSuccess(true)); // Set loading state
    try {
      const response = await client.query({
        query: GET_SETTING,
        variables: { tag },
      });
      dispatch(userSlice.actions.getSetting(response.data.setting));
    } catch (error) {
      console.log(error);
      dispatch(userSlice.actions.getSetting(null)); // Handle error
    } finally {
      dispatch(userSlice.actions.isLoadingSuccess(false)); // Reset loading state
    }
  };
}

const userReducer = userSlice.reducer;

export default userReducer;
