import { useContext, useState } from "react";
import ChartSmall from "../../components/Common/ChartSmall";
import TitleCommon from "../../components/Common/Title";
import { ButtonCommon } from "../../Layout/styled";
import {
  OverviewBody,
  OverviewChart,
  OverviewContainer,
  OverviewFrame,
  OverviewFrameBottom,
  OverviewFrameHeader,
  OverviewFrameTop,
  OverviewHeader,
  OverviewLeft,
  OverviewRight,
  OverviewSmallLeft,
  OverviewSmallRight,
  OverviewTime,
  OverviewValue,
  OvewrviewTextUpdate,
} from "./styled";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { ContextProviderWrapper } from "../../components/Context";
import DashboardNoti from "../../components/Common/DashboardNoti";
import ChartCommon from "../../components/Common/Chart";

const Overview = () => {
  const { isMobile } = useContext(ContextProviderWrapper)!;
  const [chartData, setChartData] = useState<{
    series: { name: string; data: number[] }[];
    options: ApexOptions;
  }>({
    series: [
      {
        name: "Revenue",
        data: [10, 20, 15, 25, 22, 34, 20, 15, 22, 19, 23, 20],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 200,
        background: "#171919",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: false,
          columnWidth: "50%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        colors: ["#00FF99"],
        opacity: 1,
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        labels: {
          style: {
            colors: "#B0B0B0",
            fontSize: "12px",
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        show: false,
      },
      grid: {
        show: false,
      },
      tooltip: {
        enabled: true,
        theme: "dark",
        x: {
          show: false,
        },
        y: {
          formatter: function (val) {
            return `${val}M`;
          },
          title: {
            formatter: (seriesName) => `${seriesName}`,
          },
        },
        marker: {
          show: false,
        },
      },
      states: {
        hover: {
          filter: {
            type: "lighten",
            value: 0.1,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "darken",
            value: 0.1,
          },
        },
      },
      colors: ["#00FF99"],
      legend: {
        show: false,
      },
    },
  });

  return (
    <OverviewContainer>
      {/* {isMobile && <DashboardNoti />} */}
      <OverviewHeader>
        <ButtonCommon className="green">
          <img
            style={{
              marginRight: "12px",
            }}
            width={21}
            height={21}
            src="./img/Dashboard/Overview/finger_icon.svg"
            alt="icon"
            loading="lazy"
          />
          Stake Now
        </ButtonCommon>
        <figure>
          <img
            width={296}
            height={57}
            src="./img/Dashboard/Overview/logo.png"
            alt="icon"
            loading="lazy"
          />
        </figure>
      </OverviewHeader>
      <OverviewBody>
        <OverviewLeft>
          <OverviewFrame>
            <OverviewFrameHeader>
              <TitleCommon
                title="Trading Volume"
                description="My reward earned"
              />
              <OverviewValue>
                <p>$23.09B</p>
                <span>All time Volume</span>
              </OverviewValue>
            </OverviewFrameHeader>
            <OvewrviewTextUpdate>
              <p>
                Date
                <span>June 2024</span>
              </p>
            </OvewrviewTextUpdate>
            <OverviewChart>
              <Chart
                options={chartData.options}
                series={chartData.series}
                type="bar"
                height={200}
              />
            </OverviewChart>
          </OverviewFrame>
          <OverviewFrame>
            <OverviewChart>
              <ChartCommon height={218} />
            </OverviewChart>
          </OverviewFrame>
        </OverviewLeft>
        <OverviewRight>
          <OverviewTime>
            <p>Data Update</p>
            <span>2024-08-15 09:08</span>
          </OverviewTime>
          <OverviewFrameTop>
            <div className="over-view-inner">
              <OverviewFrame className="small">
                <OverviewSmallLeft>
                  <TitleCommon title="Trading Volume" />
                  <OverviewValue>
                    <p>$23.09B</p>
                    <span>07/15 - 08/13</span>
                  </OverviewValue>
                </OverviewSmallLeft>
                <OverviewSmallRight>
                  <ChartSmall backgroundLinear={["#1AF799"]} />
                </OverviewSmallRight>
              </OverviewFrame>
            </div>
            <div className="over-view-inner">
              <OverviewFrame className="small">
                <OverviewSmallLeft>
                  <TitleCommon title="Number of Address" />
                  <OverviewValue>
                    <p>699</p>
                  </OverviewValue>
                </OverviewSmallLeft>
                <OverviewSmallRight>
                  <ChartSmall backgroundLinear={["#1AF7EC"]} />
                </OverviewSmallRight>
              </OverviewFrame>
              <OverviewFrame className="small">
                <OverviewSmallLeft>
                  <TitleCommon title="Number of Transaction" />
                  <OverviewValue>
                    <p>1,160</p>
                  </OverviewValue>
                </OverviewSmallLeft>
                <OverviewSmallRight>
                  <ChartSmall backgroundLinear={["#897CFF"]} />
                </OverviewSmallRight>
              </OverviewFrame>
            </div>
          </OverviewFrameTop>
          <OverviewFrameBottom>
            <OverviewFrame className="small">
              <OverviewSmallLeft>
                <TitleCommon title="Max Supply" />
                <OverviewValue>
                  <p>93,653</p>
                  <span>BNB Chain</span>
                </OverviewValue>
              </OverviewSmallLeft>
              <OverviewSmallRight>
                <ChartSmall backgroundLinear={["#FF7AA4"]} />
              </OverviewSmallRight>
            </OverviewFrame>
            <OverviewFrame className="small">
              <OverviewSmallLeft>
                <TitleCommon title="Fully Diluted Valuation" />
                <OverviewValue>
                  <p>15,680</p>
                  <span>BNB Chain</span>
                </OverviewValue>
              </OverviewSmallLeft>
              <OverviewSmallRight>
                <ChartSmall backgroundLinear={["#D9FF71"]} />
              </OverviewSmallRight>
            </OverviewFrame>
          </OverviewFrameBottom>
        </OverviewRight>
      </OverviewBody>
    </OverviewContainer>
  );
};

export default Overview;
