import styled from "styled-components";

export const PoolModalContainer = styled.div``
export const PoolModalWrapper = styled.div`
  .content-heading {
    display: flex;
    justify-content: space-between;
    padding-right: 30px;
    margin-bottom: 5px;
    .title-content {
      color: var(--Neutral-White, #FFF);
      font-size: 24px;
      font-weight: 600;
    }
    .text-content {
      color: var(--Sub-light-text, #9F9F9F);
      font-size: 13px;
      position: relative;
      padding-left: 20px;
      &:before {
        position: absolute;
        content: "";
        top: 50%;
        left: 0;
        margin-top: -7px;
        background: url('/img/Dashboard/Pool/modal_icn_lock.png') center / cover no-repeat;
        width: 14px;
        height: 14px;
      }
      span {
        color: var(--Mint-Green-300, #81EE97);
        font-family: Rubik;
        font-size: 15px;
        font-weight: 600;
      }
    }
  }
`
export const PoolModalShare = styled.div`
  border-radius: 6px;
  background: #222125;
  padding: 20px 16px;
  text-align: center;
  margin-bottom: 10px;
  .title-share {
    color: var(--Neutral-White, #FFF);
    font-family: Rubik;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.32px;
  }
  .number-share {
    color: var(--Neutral-White, #FFF);
    font-size: 28px;
    font-weight: 500;
    letter-spacing: -0.56px;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      color: var(--Neutral-White, #9F9F9F);
      font-size: 22px;
      letter-spacing: -0.44px;
      display: flex;
      align-items: center;
      gap: 3px;
    }
  }
  .text-share {
    color: var(--Sub-light-text, #9F9F9F);
    font-size: 13px;
    span {
      position: relative;
      padding-left: 18px;
      font-size: 12px;
      letter-spacing: -.01em;
      &:before {
        position: absolute;
        content: "";
        top: 50%;
        left: 0;
        margin-top: -7px;
        width: 14px;
        height: 14px;
        background: url('/img/Dashboard/mining/icn_warning.png') center / cover no-repeat;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`
export const PoolModalStake = styled.div`
  margin-bottom: 10px;
  .title-stake {
    color: #DFDFDF;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.14px;
    margin-bottom: 2px;
  }
  input {
    border: solid 1px #ffffff !important;
    border-radius: 5px;
    padding-left: 14px;
    padding-right: 14px;
    &:focus {
      border: solid 1px #ffffff !important;
    }
    &::placeholder {
      color: #5B5B5B;
      font-family: Rubik;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      position: relative;
      bottom: 7px;
    }
  }
`
export const PoolModalButton = styled.button`
  background: unset;
  outline: none;
  border: none;
  color: var(--Neutral-White, #FFF);
  text-align: center;
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--stroke-25-button, rgba(255, 255, 255, 0.40));
  background: var(--Surface-Background, rgba(40, 40, 40, 0.70));
  background-blend-mode: luminosity;
  box-shadow: 2px 4px 16px 0px rgba(248, 248, 248, 0.06) inset;
  backdrop-filter: blur(50px);
  padding: 18px;
  cursor: pointer;
`