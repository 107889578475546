import styled from "styled-components";
import { BlockFrame, ButtonCommon } from "../../Layout/styled";

export const LiquidityContainer = styled.div``;
export const LiquidityWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;
export const LiquidtitySeeMore = styled.div`
  color: #939393;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;
export const LiquidityLeft = styled.div`
  width: 100%;
  .text-apy {
    color: #81EE97;
  }
  .add {
    cursor: pointer;
    border-radius: 6px;
    border: 1px solid var(--stroke-25-button, rgba(255, 255, 255, 0.40));
    background: var(--Surface-Background, rgba(40, 40, 40, 0.70));
    background-blend-mode: luminosity;
    box-shadow: 2px 4px 16px 0px rgba(248, 248, 248, 0.06) inset;
    backdrop-filter: blur(50px);
    padding: 10px 24px;
    text-align: center;
    width: max-content;
    margin: 0 auto;
  }
  .soon {
    border-radius: 4px;
    background: linear-gradient(220deg, #B4B4B4 11.31%, #636363 83.28%);
    box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.25) inset;
    width: max-content;
    padding: 4px 8px;
    font-size: 12px;
    font-weight: 500;
    margin: 0 auto;
  }
  .ant-table-cell {
    &:last-child {
      text-align: center;
    }
  }
  @media (min-width: 1023.98px) and (max-width: 1440px) {
    width: calc(100% - 364px);
  }
  @media screen and (max-width: 1024px) {
    order: 2;
    position: relative;
    z-index: 2;
    ${BlockFrame} {
      border: none;
      padding: 0;
      background: unset;
    }
    .add-swiper {
      padding-bottom: 30px;
      .swiper-wrapper {
        gap: 10px;
      }
      .swiper-pagination-bullet {
        width: 5px;
        height: 5px;
        border-radius: 9px;
        background-color: #9f9f9f;
        transform: scale(1) !important;
        &.swiper-pagination-bullet-active {
          width: 29px;
          background-color: #81ee97;
        }
      }
    }
  }
`;
export const LiquidityLeftCard = styled.div`
  width: calc(100% - 42px);
  padding: 20px 16px;
  border-radius: 6px;
  background: rgba(97, 97, 97, 0.32);
  .block-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .block-heading-images {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .block-info {
    .title-pair {
      color: #fff;
      font-size: 15px;
      font-weight: 700;
    }
    .text-pair {
      color: #bfbfbf;
      font-size: 13px;
    }
  }
  .block-heading-tvl {
    text-align: center;
    span {
      display: block;
    }
    .text {
      color: #9f9f9f;
      font-size: 13px;
    }
    .number {
      color: #fff;
      font-size: 15px;
      font-weight: 500;
    }
  }
  .liquidity-card-right {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px 20px 5px 5px;
    border-radius: 9px;
    border: 1px solid var(--stroke-25-button, rgba(255, 255, 255, 0.4));
    background: var(--Surface-Background, rgba(40, 40, 40, 0.7));
    background-blend-mode: luminosity;
    box-shadow: 2px 4px 16px 0px rgba(248, 248, 248, 0.06) inset;
    backdrop-filter: blur(50px);
    .text {
      border-radius: 6px;
      border: 1.5px solid rgba(255, 204, 0, 0.12);
      background: rgba(31, 31, 31, 0.54);
      box-shadow: 0px 0px 8px 0px rgba(248, 248, 248, 0.6) inset;
      color: #d3d3d3;
      text-align: right;
      font-size: 13px;
      font-weight: 600;
      width: 44px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .number {
      display: block;
      text-align: center;
      width: calc(100% - 49px);
      color: #fff;
      font-size: 20px;
      font-weight: 500;
    }
  }
`;
export const LiquidityRight = styled.div`
  max-width: 476px;
  width: 100%;
  @media (min-width: 1023.98px) and (max-width: 1440px) {
    flex-shrink: 0;
    width: 340px;
  }
  @media screen and (max-width: 1024px) {
    order: 1;
    max-width: unset;
  }
`;
export const LiquidityList = styled.ul`
  display: flex;
  gap: 8px;
`;
export const LiquidityItem = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: calc(50% - 8px);
  min-height: 390px;
  padding: 20px;
  border-radius: 6px;
  overflow: hidden;
  &::before {
    content: "";
    position: absolute;
    background: url("./img/Dashboard/Liquidity/liquidity_pool_decor.png")
      no-repeat center / 100% auto;
    right: 0;
    bottom: 0;
    width: 650px;
    height: 336px;
    pointer-events: none;
    opacity: 0.3;
  }
`;
export const LiqItemHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
  & > div {
    display: flex;
    align-items: center;
    gap: 10px;
    & > p {
      color: #fff;
      font-size: 16px;
      font-weight: 700;
    }
    & > div {
      display: flex;
      align-items: center;
      & > figure {
        width: 37px;
        height: 36px;
        &:nth-child(2) {
          margin-left: -10px;
        }
      }
    }
  }
  ${ButtonCommon} {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 16px;
  }
`;
export const LiqItemContent = styled.div`
  margin-bottom: 20px;
  p {
    margin-bottom: 5px;
    color: #9f9f9f;
    font-size: 13px;
  }
  span {
    color: #fff;
    font-size: 28px;
    font-weight: 500;
  }
`;
export const TablePairName = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  & > div {
    display: flex;
    align-items: center;
    & > figure {
      width: 37px;
      height: 36px;
      &:nth-child(2) {
        margin-left: -10px;
      }
    }
  }
`;
export const CommingSoonButton = styled.div`
  border-radius: 4px;
  padding: 4px 8px;
  background: linear-gradient(220deg, #b4b4b4 11.31%, #636363 83.28%);
  box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.25) inset;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
`;
export const LiqRightHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  @media screen and (max-width: 1024px) {
    position: relative;
    z-index: 2;
  }
`;
export const LiqRightContent = styled.div``;
export const BlockAdd = styled.div`
  position: relative;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  z-index: 0;
  &:before {
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: url("/img/Dashboard/Liquidity/bg_01.gif") center / cover
      no-repeat;
    width: calc(100% + 40px);
    height: 100%;
    z-index: -1;
    pointer-events: none;
  }
  ${ButtonCommon} {
    padding-right: 10px;
    p {
      width: 22px;
      height: 22px;
      font-size: 20px;
      color: #939393;
    }
  }
`;
