import React, { useContext, useEffect, useState } from "react";
import { BlockPlan, PoolPlanContainer, PoolPlanWrapper } from "./styled";
import BackCommon from "../../../components/Common/Back";
import TableCommon from "../../../components/Common/Table";
import { ContextProviderWrapper } from "../../../components/Context";
import PoolItem from "../PoolItem";
import moment from "moment";
import { formatNumberMega } from "../../../utils/formatNumber";
import PoolModal from "../PoolModal";

const PoolPlan = ({
  setDetailPage,
  userProfile,
  getWallet,
  loadingData,
  setLoadingData
}: any) => {
  const { isDesktop } = useContext(ContextProviderWrapper)!;
  const [dataHistory, setDataHistory] = useState<any>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemDataHistory, setItemdataHistory] = useState<any>();

  //modal
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const getDataHistory = (data: any) => {
    setItemdataHistory(data);
  };

  const headingData = [
    {
      name: "Time",
      dataIndex: "",
      key: "",
      render: (data: any) => {
        return (
          <p className="table-text">
            {moment(parseInt(data.createdAt)).format("HH:mm - D/M/YYYY")}
          </p>
        );
      },
    },
    {
      name: "Amout",
      dataIndex: "",
      key: "",
      render: (data: any) => {
        return <p className="amout">{formatNumberMega(data.amount)} ONI</p>;
      },
    },
    {
      name: "Option",
      dataIndex: "",
      key: "",
      render: (data: any) => {
        return <p className="table-text">{data.timeLimit} Months</p>;
      },
    },
    {
      name: "Type",
      dataIndex: "",
      key: "",
      render: (data: any) => {
        return (
          <p
            className={`table-text ${
              data.type === "Automatic" ? "is-color" : ""
            }`}
            style={{textTransform: 'capitalize'}}
          >
            {data.type}
          </p>
        );
      },
    },
    {
      name: "Unlock Time",
      dataIndex: "",
      key: "",
      render: (data: any) => {
        return (
          <p className="table-text">
            {moment(parseInt(data.expiredAt)).format("HH:mm - D/M/YYYY")}
          </p>
        );
      },
    },
    {
      name: "Action",
      dataIndex: "",
      key: "",
      render: (data: any) => {
        const isExpired = Date.now() > parseInt(data.expiredAt);
        if (data.manualStatus !== "leave") {
          return (
            <button
              className="table-action"
              onClick={() => {
                setIsModalOpen(true);
                getDataHistory(data);
              }}
            >
              Unlock
            </button>
          );
        }
        return (
          <button className="table-action locked" disabled>
            Locked
          </button>
        );
      },
    },
  ];

  // table mobile
  const headingDataMobile = [
    {
      title: "Network Details",
      dataIndex: "name",
      key: "name",
      render: (text: any, record: any) => (
        <PoolItem
          record={record}
          setIsModalOpen={setIsModalOpen}
          setItemdataHistory={setItemdataHistory}
        />
      ),
    },
  ];

  // get history pool
  useEffect(() => {
    if (userProfile?.wallet) {
      const newData = [...userProfile?.wallet?.poolShares];
      setDataHistory(newData?.reverse());
    }
  }, [loadingData]);

  return (
    <>
      <PoolModal
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
        itemDataHistory={itemDataHistory}
        userProfile={userProfile}
        getWallet={getWallet}
        setLoadingData={setLoadingData}
      />
      <PoolPlanContainer>
        <PoolPlanWrapper>
          {isDesktop && (
            <BackCommon backFunction={() => setDetailPage(false)} />
          )}
          <BlockPlan>
            <h2 className="title-plan">Your Joined Plan</h2>
            {/* <p className="text-plan">BNB Chain</p> */}
            {isDesktop ? (
              <TableCommon data={dataHistory} heading={headingData} />
            ) : (
              <TableCommon data={dataHistory} heading={headingDataMobile} />
            )}
          </BlockPlan>
        </PoolPlanWrapper>
      </PoolPlanContainer>
    </>
  );
};

export default PoolPlan;
