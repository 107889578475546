import styled from "styled-components";
import { TitleWrapper } from "../../components/Common/Title/styled";
import { BlockFrame, ButtonCommon, StatusTag } from "../../Layout/styled";
import { LiqFrame, LiquidityActions, LiquidityInfo } from "../Liquidity/Add/styled";
import { TablePairName } from "../Liquidity/styled";

export const SwapContainer = styled.div`
`
export const SwapWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  @media screen and (max-width: 1024px) {
    flex-direction: column-reverse;
  }
`
export const SwapLeft = styled.div`
  width: calc(100% - 500px);
  ${BlockFrame} {
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
    .ant-table-cell {
      &:nth-child(3),
      &:nth-child(5),
      &:nth-child(7) {
        display: none;
      }
      &:nth-child(4) {
        order: 2;
      }
    }
    .ant-table-row {
      flex-direction: unset;
      align-items: center;
      justify-content: space-between;
      padding: 0 16px;
    }
    .ant-table-cell {
      padding: 10px 0 !important;
    }
    ${TablePairName} {
      gap: 5px;
      margin-right: 5px;
      p {
        font-size: 14px;
        font-weight: 400;
      }
      span {
        font-weight: 400;
        font-size: 13px;
      }
    }
    ${StatusTag} {
      min-width: unset;
    }
  }
`
export const SwapRight = styled.div`
  width: 476px;
  flex-shrink: 0;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`
export const SwapRightHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
  ${TitleWrapper} {
    margin-bottom: 0;
  }
`
export const SwapChart = styled.div`

`
export const SwapChartHeader = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  & > figure {
    width: 36px;
    height: 36px;
  }
`
export const SwapRightActions = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: space-between;
  figure {
    width: 20px;
    height: 21px;
    cursor: pointer;
  }
`
export const ButtonSwitch = styled.figure`
  position: absolute;
  left: 50%;
  bottom: -20px;
  margin-left: -18px;
  width: 36px;
  height: 36px;
  z-index: 5;
  transition: all .15s linear;
  cursor: pointer;
  &:hover {
    transform: rotate(180deg);
  }
`
export const SwapForm = styled.div`
  ${LiqFrame} {
    &::before {
      content: none;
    }
  }
  ${LiquidityActions} {
    ${ButtonCommon} {
      &:nth-child(1) {
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 767.98px) {
    ${LiquidityInfo} {
      ${ButtonCommon} {
        padding: 0;
        width: 38px;
        height: 38px;
        img {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
`