import styled from "styled-components";

export const LoadingWrapper = styled.div`
  position: relative;
  &.isLoading {
    width: max-content;
    margin: 0 auto;
  }
  img {
    width: 68px;
    height: 68px;
    animation: translateLogo 3s infinite linear;
  }
  @keyframes translateLogo {
    0% {
      transform: translate(0);
    }
    30% {
      opacity: .1;
    }
    50% {
      transform: translateY(10px);
      opacity: .5;
    }
    100% {
      transform: translate(0);
      opacity: 1;
    }
  }
`