import styled from "styled-components";
import { ButtonCommon } from "../../../Layout/styled";

export const ModalDecor = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  background: url('./img/Common/modal_decor.png') no-repeat center / 100% auto;
  width: 495px;
  height: 250px;
`
export const ModalTitle = styled.div`
  margin-bottom: 15px;
  h2 {
    color: #FFF;
    font-size: 24px;
    font-weight: 600;
    line-height: 100%;
    text-transform: capitalize;
    margin-bottom: 10px;
  }
  p {
    color: #9F9F9F;
    font-size: 13px;
    line-height: 120%;
  }
`
export const ModalBody = styled.div`
  & > figure {
    position: relative;
    width: max-content;
    margin: 0 auto 15px;
  }
  ${ButtonCommon} {
    width: 100%;
    justify-content: center;
    position: relative;
    padding: 16px 20px;
  }
`
export const ModalTransaction = styled.div`
  padding: 3px 12px;
  max-width: max-content;
  margin: 0 auto 20px;
  border-radius: 33px;
  border: 1px solid rgba(128, 127, 127, 0.4);
  background: rgba(92, 92, 92, 0.70);
  background-blend-mode: luminosity;
  box-shadow: 2px 4px 16px 0px rgba(248, 248, 248, 0.06) inset;
  backdrop-filter: blur(50px);
  transition: all .15s linear;
  &:hover {
    opacity: .7;
  }
  a {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #C7C7C7;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
  }
`