import styled from "styled-components";

export const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  figure {
    width: 37px;
    height: 36px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: auto;
    }
  }
  div {
    p {
      color: #fff;
      font-size: 16px;
      font-weight: 700;
    }
    span {
      color: #9f9f9f;
      font-size: 13px;
    }
  }
  @media screen and (max-width: 1024px) {
    div {
      p {
        font-size: 12px;
      }
    }
  }
`;
