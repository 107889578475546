import styled from "styled-components";

export const InputContainer = styled.div`
  width: 100%;
  .ant-input-affix-wrapper,
  input {
    border-radius: 10px;
    border: 0;
    padding: 0;
    background: transparent;
    color: #fff;
    font-size: 30px;
    &::placeholder {
      color: #bfbfbf;
    }
  }
  .ant-input-outlined[disabled] {
    color: rgba(255, 255, 255, 0.78);
  }
  .ant-input-affix-wrapper {
    input {
      font-size: 30px;
      border-radius: 0;
    }
  }
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused,
  .ant-input-focused,
  .ant-input:focus,
  .ant-input-affix-wrapper:hover,
  .ant-input:hover {
    border-color: transparent;
    box-shadow: none;
    background-color: transparent;
  }
  .ant-input-affix-wrapper-disabled {
    background-color: transparent !important;
  }
  .ant-input-prefix {
    margin-right: 5px;
  }
  .ant-input-group-addon {
    background: transparent;
    font-size: 14px;
    color: #0d0c43;
    &:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    &:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
  .addon-input {
    .ant-input {
      padding-top: 17px;
      padding-bottom: 17px;
    }
  }
`;
