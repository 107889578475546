import styled from "styled-components";

export const HintCommonWrapper = styled.div`
  margin-top: 20px;
  padding: 20px;
  border-radius: 6px;
  background: rgba(97, 97, 97, 0.22);
  @media screen and (max-width: 1023px) {
    margin-top: 0;
  }
`;
export const HintHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid #323232;
  & > div {
    display: flex;
    align-items: center;
    gap: 5px;
    figure {
      width: 14px;
      height: 14px;
    }
  }
`;
export const HintClose = styled.div`
  cursor: pointer;
  width: 17px;
  height: 16px;
`;
export const HintBody = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  & > div {
    display: flex;
    align-items: center;
    gap: 10px;
    figure {
      width: 82px;
      height: 82px;
    }
    & > div {
      p {
        color: #fff;
        font-size: 15px;
        font-weight: 700;
        margin-bottom: 2px;
      }
      span {
        color: #bfbfbf;
        font-size: 13px;
      }
    }
  }
  & > figure {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
`;
