import { HintBody, HintClose, HintCommonWrapper, HintHeader } from "./styled";

const HintCommon = () => {
  return (
    <HintCommonWrapper>
      <HintHeader>
        <div>
          <figure>
            <img
              width={14}
              height={14}
              src="./img/Common/hint_icon.svg"
              alt="icon"
              loading="lazy"
            />
          </figure>
          <p>How it works</p>
        </div>
        <HintClose>
          <img
            width={17}
            height={16}
            src="./img/Common/close_icon.svg"
            alt="icon"
            loading="lazy"
          />
        </HintClose>
      </HintHeader>
      <HintBody>
        <div>
          <figure>
            <img
              width={82}
              height={82}
              src="./img/Gif/hint_gif.gif"
              alt="gif"
              loading="lazy"
            />
          </figure>
          <div>
            <p>Liquidity</p>
            <span>
              Classical AMM-like pool. Suitable for
              <br /> most assets.
            </span>
          </div>
        </div>
        <figure>
          <img width={24} height={24} src="./img/Common/play_icon.png" alt="icon" loading="lazy" />
        </figure>
      </HintBody>
    </HintCommonWrapper>
  );
};

export default HintCommon;
