import React, { useEffect, useState } from "react";
import {
  BlockGetName,
  BlockGetNameInner,
  BlockSendMessage,
  BlockText,
  ButtonSent,
  ContentCircle,
  LiveChatContainer,
  LogoSupport,
} from "./styled";
import InputCommon from "../Input";
import { useAccount } from "wagmi";
import { shortenAddress } from "../../../utils/addressUser";
import { ReactTinyLink } from "react-tiny-link";

const LiveChat = () => {
  const { address } = useAccount();
  const [listChat, setListChat] = useState<any>([]);
  const [textInput, setTextInput] = useState<any>();
  const [answer, setAnswer] = useState(false);
  const [isShowChat, setIsShowChat] = useState(false);

  const onChange = (e: any) => {
    setTextInput(e.target.value);
  };
  const handleChat = () => {
    if (textInput) {
      setListChat([...listChat, textInput]);
      setTextInput("");
      setAnswer(true);
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      handleChat();
    }
  };
  const handleShowChat = () => {
    setIsShowChat(!isShowChat);
  };

  return (
    <LiveChatContainer>
      <ContentCircle>
        <LogoSupport onClick={handleShowChat}>
          <img
            src={`${
              isShowChat
                ? "img/Common/icn_support_05.png"
                : "/img/Common/icn_support.png"
            }`}
            width={51}
            height={52}
            loading="lazy"
            alt=""
          />
        </LogoSupport>
      </ContentCircle>
      <BlockGetName className={isShowChat ? "is-show" : ""}>
        <BlockGetNameInner>
          <div className="content-heading">
            <h3 className="title">
              Hi, {address ? shortenAddress(address) : "Anonymous"}
            </h3>
            <p className="description">How Can We Help?</p>
          </div>
          <img
            src="/img/Common/icn_support_03.png"
            width={51}
            alt=""
            className="img-heading"
          />
        </BlockGetNameInner>
        <BlockText>
          {listChat.length > 0 ? (
            listChat.map((item: any, index: number) => (
              <>
                <p className="question" key={index}>
                  {item}
                </p>
                {answer && (
                  <div className="answer">
                    <span className="telegram">Please Contact: </span>
                    <ReactTinyLink
                      cardSize="small"
                      showGraphic={false}
                      maxLine={1}
                      minLine={1}
                      url="https://t.me/trackjohn"
                    />
                  </div>
                )}
              </>
            ))
          ) : (
            <img
              className="no-content"
              src="/img/Common/support_img_02.png"
              width={147}
              loading="lazy"
            />
          )}
        </BlockText>
        <BlockSendMessage>
          <div className="content-left">
            <h3 className="title-message">Send us a message</h3>
            <InputCommon
              placeHolder="We typically reply in a few minutes"
              onChange={onChange}
              value={textInput}
              onKeyDown={handleKeyDown}
            />
          </div>
          <ButtonSent onClick={handleChat} disabled={textInput ? false : true}>
            <img
              src="/img/Common/icn_support_04.png"
              width={24}
              height={24}
              loading="lazy"
              alt="Send"
            />
          </ButtonSent>
        </BlockSendMessage>
      </BlockGetName>
    </LiveChatContainer>
  );
};

export default LiveChat;
