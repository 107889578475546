import styled from "styled-components";
import { BlockFrame } from "../../../Layout/styled";

export const MiningDetailWrapper = styled.div`
  width: 100%;
`;
export const MiningDetailBlocks = styled.div`
  ${BlockFrame} {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    &:nth-child(1) {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    &:nth-child(2) {
    }
  }
`;
export const MiningSR = styled.div`
  width: 50%;
  padding: 16px 16px 20px;
  overflow: hidden;
  border-radius: 6px;
  position: relative;
  &:before {
    position: absolute;
    content: "";
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 3px;
    border-radius: 20px;
    background-color: #44a456;
  }
  &:after {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    background: url("/img/Dashboard/mining/mining_bg_02.png") center / cover
      no-repeat;
    width: 650px;
    height: 148px;
    opacity: 0.5;
    pointer-events: none;
  }
  &:nth-child(1) {
    background: linear-gradient(
      180deg,
      rgba(99, 255, 128, 0.19) 0%,
      rgba(53, 131, 67, 0.19) 100%
    );
  }
  &:nth-child(2) {
    background: linear-gradient(
      180deg,
      rgba(97, 97, 97, 0.22) 0%,
      rgba(199, 199, 199, 0.22) 100%
    );
    &:before {
      background-color: #767676;
    }
  }
  & > p {
    color: #fff;
    font-size: 28px;
    font-weight: 500;
  }
  @media screen and (max-width: 1024px) {
    padding-left: 10px;
    padding-right: 10px;
    &:after {
      background-image: url("/img/Dashboard/mining/mining_bg.png");
      width: 100%;
      height: 100%;
    }
    & > p {
      font-size: 19px;
    }
  }
`;
export const SRHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 50px;
  p {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
  }
`;
export const MiningInfoDetail = styled.div`
  border-radius: 8px;
  background: #222125;
  padding: 12px 16px;
  & > h2 {
    color: #81ee97;
    font-size: 16px;
    font-weight: 700;
    border-bottom: 1px solid #323232;
    padding-bottom: 12px;
    margin-bottom: 12px;
  }
  & > div {
    & > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      &:not(:last-child) {
        margin-bottom: 12px;
      }
      p {
        color: #c8c8c8;
        font-size: 14px;
        font-weight: 400;
      }
      span {
        font-size: 15px;
        color: #dadada;
        font-weight: 500;
      }
    }
  }
`;
export const MDBlock = styled.div`
  position: relative;
  display: flex;
  width: 33.33%;
  border-radius: 6px;
  padding: 16px;
  justify-content: space-between;
  flex-direction: column;
  h2 {
    font-weight: 600;
    line-height: 120%;
  }
  p {
    font-size: 13px;
  }
  &::before {
    content: "";
    position: absolute;
    bottom: 12px;
    left: 50%;
    margin-left: -30px;
    width: 60px;
    height: 3px;
    border-radius: 10px;
    pointer-events: none;
  }
  &.green {
    background: linear-gradient(
      180deg,
      rgba(99, 255, 128, 0.19) 0%,
      rgba(53, 131, 67, 0.19) 100%
    );
    &::before {
      background-color: #44a456;
    }
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      background: url("./img/Dashboard/Swap/period_decor_icon.png") no-repeat
        center / 100% auto;
      width: 233px;
      height: 265px;
      pointer-events: none;
    }
  }
  &.gray {
    background: linear-gradient(
      180deg,
      rgba(97, 97, 97, 0.22) 0%,
      rgba(199, 199, 199, 0.22) 100%
    );
    &::before {
      background-color: #646464;
    }
    &::after {
      content: "%";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-size: 128px;
      font-weight: 500;
      mix-blend-mode: soft-light;
      pointer-events: none;
    }
    h2 {
      font-size: 24px;
    }
    p {
      color: #bfbfbf;
    }
  }
  &.block-small {
    justify-content: space-between;
    padding: 0;
    gap: 8px;
    &::before {
      content: none;
    }
  }
  @media screen and (max-width: 1024px) {
    padding: 10px;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url("/img/Dashboard/mining/mining_bg.png") center / cover
        no-repeat !important;
    }
    &.gray {
      h2 {
        font-size: 16px;
      }
    }
    p {
      font-size: 10px;
    }
    &.green {
      border-radius: 6px;
      overflow: hidden;
      &:after {
        width: 100%;
        height: 100%;
        background: linear-gradient(
          180deg,
          rgba(99, 255, 128, 0.19) 0%,
          rgba(53, 131, 67, 0.19) 100%
        );
      }
    }
    &.gray {
      border-radius: 6px;
      overflow: hidden;
      &:after {
        content: "";
        width: 100%;
        height: 100%;
        background: linear-gradient(
          180deg,
          rgba(97, 97, 97, 0.22) 0%,
          rgba(199, 199, 199, 0.22) 100%
        );
      }
    }
  }
`;
export const MiningDescription = styled.div`
  display: flex;
  min-height: 340px;
  gap: 8px;
  margin-bottom: 20px;
  @media screen and (max-width: 1024px) {
    flex-wrap: wrap;
    ${MDBlock} {
      width: calc(100% / 2 - 24px);
      &.first {
        background: linear-gradient(
          180deg,
          rgba(94, 233, 201, 0.28) 0%,
          rgba(53, 131, 113, 0.28) 100%
        );
        &:before {
          background-color: #315852;
        }
      }
      &.second {
        background: rgba(48, 248, 255, 0.19);
        &:before {
          background-color: #295e61;
        }
      }
    }
  }
`;
export const MDBlockSmall = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 6px;
  height: 100%;
  padding: 16px;
  overflow: hidden;
  &::before {
    content: "";
    position: absolute;
    bottom: 12px;
    left: 50%;
    margin-left: -30px;
    width: 60px;
    height: 3px;
    border-radius: 10px;
    pointer-events: none;
  }
  &.first {
    background: linear-gradient(
      180deg,
      rgba(94, 233, 201, 0.28) 0%,
      rgba(53, 131, 113, 0.28) 100%
    );
    &::before {
      background-color: #366157;
    }
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      background: url("./img/Dashboard/Swap/small_decor_header.png") no-repeat
        center / 100% auto;
      width: 154px;
      height: 94px;
      pointer-events: none;
    }
  }
  &.second {
    background: rgba(48, 248, 255, 0.19);
    &::before {
      background-color: #2b6465;
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      background: url("./img/Dashboard/Swap/small_decor_footer.png") no-repeat
        center / 100% auto;
      width: 154px;
      height: 74px;
      pointer-events: none;
    }
  }
`;
export const MDBlockHeader = styled.div`
  p {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
  & > a {
    color: #81ee97;
    font-size: 13px;
    font-weight: 500;
    text-decoration-line: underline;
  }
`;
export const MDBlockBody = styled.div`
  margin-bottom: 10px;
  p {
    color: #9f9f9f;
    font-size: 13px;
    font-weight: 400;
  }
  span {
    color: #fff;
    font-size: 24px;
    font-weight: 500;
  }
  @media screen and (max-width: 1024px) {
    span {
      font-size: 19px;
    }
  }
`;
