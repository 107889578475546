import React, { useContext, useEffect, useRef, useState } from "react";
import { ContextProviderWrapper } from "../../../components/Context";
import { formatNumberMega } from "../../../utils/formatNumber";
import moment from "moment";
interface PoolItemProps {
  record:any;
  setIsModalOpen: any;
  setItemdataHistory:any
}

const PoolItem: React.FC<PoolItemProps> = ({
  record,
  setIsModalOpen,
  setItemdataHistory
}) => {
  const [showStaking, setShowStaking] = useState(false);
  const { setNetWorkMember, netWorkMember } = useContext(ContextProviderWrapper)!;
  const totalInnerRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0)

  const handleShowStaking = () => {
    setShowStaking(!showStaking);
  };

  const handleShowMember = () => {
    setNetWorkMember(true)
  }

  useEffect(() => {
    if (showStaking && totalInnerRef.current) {
      const height = totalInnerRef.current.getBoundingClientRect().height;
      setHeight(height)
    }
  }, [showStaking]);

  return (
    <>
      <div className={`block-row ${record?.manualStatus === 'leave' ? 'leaved' : ''} ${showStaking ? "is-active" : ""}`}>
        <div className="block-row-heading">
          <p className="name">Stake time: {record.date}</p>
          <p className="more" onClick={handleShowStaking}>
            {!showStaking ? 'Show less' : 'See more'}
            <img src="/img/Common/play_icon.png" alt="more" />
          </p>
        </div>
        <div className="total-staking" style={{height: showStaking ? height : '0'}}>
          {showStaking ? (
            <div className="block-content" ref={totalInnerRef}>
              <div className="total-inner">
              <p className="text-total">Amount</p>
              <span className="number-staking">{formatNumberMega(record.amount)}</span>
            </div>
              <div className="total-inner">
                <p className="text-total">Option</p>
                <span className="number-staking">{record.timeLimit} Month</span>
              </div>
              <div className="total-inner">
                <p className="text-total">Type</p>
                <span className="number-staking">{record.type}</span>
              </div>
              <div className="total-inner">
                <p className="text-total">Unlock Time</p>
                <span className="number-staking">{moment(parseInt(record.expiredAt)).format("HH:mm - D/M/YYYY")}</span>
              </div>
              <div className="total-inner is-button">
                <button className="button-lock" onClick={()=>{setIsModalOpen(true); setItemdataHistory(record)}}>Unlock</button>
              </div>
            </div>
          ) : (
            <div className="total-inner">
              <p className="text-total">Amount</p>
              <span className="number-staking">{formatNumberMega(record.amount)}</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PoolItem;
