import styled from "styled-components";

export const SelectContainer = styled.div`
  .ant-select {
    border-radius: 10px;
    border: 1px solid transparent;
    background: #323334;
    height: 42px;
    .ant-select-arrow {
      opacity: 1 !important;
    }
    .ant-select-selector {
      background-color: transparent;
      color: #fff;
      border-color: transparent !important;
      border: none;
      box-shadow: none !important;
    }
    .ant-select-selection-item {
      color: #fff;
      font-weight: 600;
      font-size: 16px;
      div {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
  }
  .ant-select-dropdown {
    background-color: #1b1c20;
    .ant-select-item-option-content {
      color: #fff;
      div {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
    .ant-select-item-option-active {
      background-color: #6e6e6e !important;
    }
    .ant-select-item-option.ant-select-item-option-selected {
      background-color: transparent;
    }
  }
  .ant-select-selection-placeholder {
    color: #fff;
  }
  @media screen and (max-width: 767.98px) {
    .ant-select {
      .ant-select-selection-item {
        font-size: 14px;
      }
    }
  }
`;
