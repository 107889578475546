import { TitleWrapper } from "./styled";

const TitleCommon = ({ title, description }: any) => {
  return (
    <TitleWrapper>
      <h2>{title}</h2>
      {typeof description === "string" ? <p>{description}</p> : description}
    </TitleWrapper>
  );
};

export default TitleCommon;
