import styled from "styled-components";
import { TitleWrapper } from "../../components/Common/Title/styled";
import { ButtonCommon } from "../../Layout/styled";
import { DashboardNotiContainer } from "../../components/Common/DashboardNoti/styled";

export const NetworkContainer = styled.div`
  @media screen and (max-width: 1024px) {
    margin: -20px -20px 0;
    ${DashboardNotiContainer} {
      margin-bottom: 0;
    }
  }
`;
export const NetworkWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 25px;
  max-width: 1920px;
  margin: 0 auto;
  .frame-list {
    width: calc(100% - 476px);
  }
  ${TitleWrapper} {
    margin-bottom: 0;
  }
  @media (min-width: 768px) and (max-width: 1370px) {
    .frame-list {
      width: calc(100% - 360px);
    }
  }
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    .frame-list {
      width: 100%;
      border: none;
      background: linear-gradient(180deg, #1f1f1f 0%, #6ea380 100%);
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 28px;
      border-bottom-right-radius: 28px;
      padding: 0;
    }
  }
`;
export const NetworkStaking = styled.div`
  max-width: 476px;
  width: 100%;
  .ant-table-content {
    table {
      min-width: unset;
    }
  }
  @media screen and (max-width: 1024px) {
    max-width: unset;
  }
`;
export const NetworkListHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap;
  @media screen and (max-width: 1024px) {
    padding: 16px 12px;
    flex-direction: column;
    background: url("/img/Dashboard/Network/bg_01.png") top -194px center / cover
      no-repeat;
    margin-bottom: 0;
  }
`;
export const NetworkTotalStaking = styled.div`
  p {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 10px;
    span {
      position: relative;
      padding-left: 30px;
      &:before {
        position: absolute;
        content: "";
        top: 50%;
        margin-top: -15px;
        left: 0;
        background: url("/img/Dashboard/Network/icon_user.png") center / cover
          no-repeat;
        width: 30px;
        height: 27px;
      }
    }
  }
  .total-staking {
    display: block;
    text-align: center;
    font-size: 36px;
    font-weight: 500;
    background: var(
      --Number,
      linear-gradient(180deg, #ceffd1 0%, #4ec575 100%)
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;
export const NetworkRef = styled.div`
  display: flex;
  & > p {
    display: flex;
    align-items: center;
    color: #9f9f9f;
    font-size: 14px;
    margin: 0 8px;
  }
  @media (min-width: 1024.98px) and (max-width: 1220px) {
    ${ButtonCommon} {
      font-size: 13px;
    }
  }
  @media screen and (max-width: 1024px) {
    gap: 12px;
    flex-wrap: wrap;
    justify-content: center;
    ${ButtonCommon} {
      justify-content: flex-start;
      text-align: center;
      &.green {
        font-size: 17px;
        font-weight: 400;
        width: max-content;
        span {
          width: 200px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      &.yellow {
        width: max-content;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 4px;
        p {
          border: 1px solid rgba(255, 204, 0, 0.12);
          background: rgba(31, 20, 0, 0.54);
          box-shadow: 0px 0px 8px 0px rgba(248, 248, 248, 0.6);
          padding: 10px;
          margin-right: 10px;
        }
      }
    }
  }
`;
export const TeamDataHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 12px;
  & > div {
    position: relative;
    width: max-content;
    figure {
      mix-blend-mode: luminosity;
      transition: all 0.15s linear;
      opacity: 0.5;
      width: 36px;
    }
    p {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.15s linear;
      font-size: 10px;
      font-weight: 500;
      color: #9c9c9c;
    }
  }
  & > p {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
  }
`;
export const TeamDataInfo = styled.div`
  position: relative;
  z-index: 5;
  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
    p {
      font-size: 14px;
    }
  }
  @media screen and (max-width: 1370px) {
    & > div {
      p {
        font-size: 12px;
      }
    }
  }
`;
export const TeamData = styled.ul`
  .ant-pagination {
    display: none;
  }
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  z-index: 5;
  & > li {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    width: calc(100% / 4 - 38px);
    min-height: 200px;
    padding: 16px;
    border-radius: 8px;
    background: linear-gradient(
      180deg,
      rgba(97, 97, 97, 0.22) 0%,
      rgba(199, 199, 199, 0.22) 100%
    );
    cursor: pointer;
    transition: all 0.15s linear;
    overflow: hidden;
    &::before,
    &::after {
      content: "";
      position: absolute;
      pointer-events: none;
    }
    &::before {
      top: 0;
      right: 0;
      background: url("./img/Dashboard/Network/team_decor.png") no-repeat center /
        100% auto;
      width: 90px;
      height: 87px;
      mix-blend-mode: luminosity;
    }
    &::after {
      top: -290px;
      right: -70px;
      width: 502px;
      height: 286px;
      background-color: #81ee97;
      filter: blur(94px);
      z-index: 1;
      opacity: 0;
      transition: all 0.15s linear;
    }
    &:hover,
    &.active {
      background: linear-gradient(
        180deg,
        rgba(99, 255, 128, 0.19) 0%,
        rgba(53, 131, 67, 0.19) 100%
      );
      ${TeamDataHeader} {
        & > div {
          figure {
            mix-blend-mode: unset;
            opacity: 1;
          }
          p {
            background: linear-gradient(180deg, #ceffd1 0%, #4ec575 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
      &::after {
        opacity: 0.7;
      }
    }
  }
  @media (min-width: 1024.98px) and (max-width: 1230px) {
    & > li {
      width: calc(100% / 3 - 38px);
    }
  }
  @media screen and (max-width: 1024px) {
    display: block;
    width: calc(100% - 40px);
    padding: 0 20px;
    .name {
      color: #fff;
      font-family: Rubik;
      font-size: 16px;
      font-weight: 700;
    }
    .more {
      color: #b0b0b0;
      font-size: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      img {
        width: 24px;
        height: 24px;
        transition: transform 0.26s ease;
      }
    }
    .block-row {
      padding: 10px;
      border: 2px solid transparent;
      position: relative;
      transition: all 0.26s ease;
      position: relative;
      min-height: 92px;
      &:after {
        position: absolute;
        content: "";
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: linear-gradient(
          180deg,
          rgba(99, 255, 128, 0.19) 0%,
          rgba(53, 131, 67, 0.19) 100%
        );
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.26s ease;
      }
      &.is-active {
        border-radius: 8px;
        border: 2px solid #ceffd1;
        backdrop-filter: blur(15.399999618530273px);
        &:after {
          opacity: 1;
        }
        .total-inner {
          border-radius: 9px;
        }
        .text-total {
          border-radius: 6px;
          border: 1.5px solid rgba(255, 204, 0, 0.12);
          background: rgba(23, 60, 28, 0.54);
          box-shadow: 0px 0px 8px 0px rgba(248, 248, 248, 0.6) inset;
        }
        .more {
          img {
            transform: rotate(90deg);
          }
        }
      }
      .total-staking {
        height: 0;
        transition: all 0.26s ease;
      }
      .show-detail {
        width: 34px;
        height: 34px;
        display: block;
        position: absolute;
        right: 10px;
        top: 58%;
        right: 5px;
        transform: translateY(-50%);
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .block-row-heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
      }
      .total-inner {
        position: relative;
        padding: 5px;
        display: flex;
        align-items: center;
        gap: 10px;
        border-radius: 9px;
        border: 1px solid var(--stroke-25-button, rgba(255, 255, 255, 0.4));
        background: var(--Surface-Background, rgba(40, 40, 40, 0.7));
        background-blend-mode: luminosity;
        box-shadow: 2px 4px 16px 0px rgba(248, 248, 248, 0.06) inset;
        backdrop-filter: blur(50px);
      }
      .text-total {
        border-radius: 6px;
        border: 1.5px solid rgba(255, 204, 0, 0.12);
        background: rgba(31, 31, 31, 0.54);
        box-shadow: 0px 0px 8px 0px rgba(248, 248, 248, 0.6) inset;
        width: 125px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
      }
      .number-staking {
        display: flex;
        justify-content: center;
        text-align: center;
        width: calc(100% - 135px);
        color: #fff;
        font-size: 20px;
        font-weight: 500;
        img {
          width: 20px;
          height: 19px;
          object-fit: cover;
          position: relative;
          top: 2px;
        }
      }
      .block-content {
        .total-inner {
          &:not(:last-child) {
            margin-bottom: 4px;
          }
        }
      }
    }
    .ant-table-container .ant-table-cell {
      font-weight: 400;
      padding: 0 !important;
    }
    .ant-table-row {
      border-radius: 6px;
      background: rgba(97, 97, 97, 0.32);
      backdrop-filter: blur(14.449999809265137px);
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
`;
export const StakingSum = styled.div`
  & > div {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    min-height: 60px;
    position: relative;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #ceffd1;
    background: linear-gradient(
      180deg,
      rgba(99, 255, 128, 0.19) 0%,
      rgba(53, 131, 67, 0.19) 100%
    );
    backdrop-filter: blur(27px);
    overflow: hidden;
    margin-bottom: 10px;
    & > div {
      display: flex;
      align-items: center;
      gap: 5px;
      margin-right: auto;
      margin-bottom: 15px;
    }
    p {
      font-size: 32px;
      font-weight: 500;
      background: linear-gradient(180deg, #ceffd1 0%, #4ec575 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    &::before {
      content: "";
      position: absolute;
      bottom: -30px;
      left: 0;
      background: url("./img/Dashboard/Network/decor_staking.png") no-repeat
        center / 100% auto;
      width: 476px;
      height: 118px;
      opacity: 0.4;
    }
  }
`;
export const StakingList = styled.div`
  @media screen and (max-width: 1024px) {
    .empty-data {
      color: #fff;
    }
  }
`;
export const StakingListHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 10px;
  & > div {
    &:nth-child(1) {
      h2 {
        color: #fff;
        font-size: 24px;
        font-weight: 500;
      }
      p {
        color: #9f9f9f;
        font-size: 13px;
        span {
          color: #81ee97;
          font-size: 13px;
          font-weight: 600;
        }
      }
    }
    &:nth-child(2) {
      display: flex;
      align-items: center;
      gap: 5px;
      p {
        color: #fff;
        text-align: right;
        font-size: 24px;
        font-weight: 500;
      }
      figure {
        width: 25px;
      }
    }
  }
`;
export const StakingListBody = styled.ul`
  .ant-table-thead {
    display: none;
  }
  & > li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    padding: 8px;
    border-radius: 6px;
    background: rgba(97, 97, 97, 0.22);
    &:not(:last-child) {
      margin-bottom: 5px;
    }
    & > p {
      color: #9f9f9f;
      text-align: center;
      font-size: 16px;
    }
    & > div {
      p {
        text-align: center;
        &:nth-child(1) {
          color: #616161;
          font-size: 12px;
        }
        &:nth-child(2) {
          color: #fff;
          font-size: 15px;
        }
      }
    }
  }
`;
