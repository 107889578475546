import styled from "styled-components";
import { TitleWrapper } from "../../components/Common/Title/styled";
import { BlockFrame, ButtonCommon } from "../../Layout/styled";
import { LiquidityActions } from "../Liquidity/Add/styled";
import { LaunchpadListItem } from "../Launchpad/styled";
import { TablePairName } from "../Liquidity/styled";
import { BorderAnimation } from "../../Layout/Dashboard/styled";

export const MiningContainer = styled.div``;
export const MiningWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  & > ${BlockFrame} {
    width: 100%;
  }
  @media screen and (max-width: 1244px) {
    flex-direction: column;
    ${ButtonCommon} {
      &:nth-child(1) {
        width: 100%;
      }
    }
    ${LaunchpadListItem} {
      width: 290px;
    }
  }
`;
export const MiningHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
  justify-content: space-between;
  ${TitleWrapper} {
    margin-bottom: 0;
  }
  @media screen and (max-width: 1023px) {
    width: 100%;
    margin-bottom: 0;
  }
`;
export const MiningAction = styled.div`
  max-width: 476px;
  width: 100%;
  @media screen and (min-width: 1244.98px) and (max-width: 1370px) {
    max-width: 360px;
  }
  @media screen and (max-width: 1244px) {
    margin-top: 20px;
    max-width: unset;
  }
`;
export const MiningLiqCreate = styled.div`
  display: flex;
  justify-content: center;
  width: max-content;
  padding: 8px 20px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: rgba(40, 40, 40, 0.7);
  background-blend-mode: luminosity;
  box-shadow: 2px 4px 16px 0px rgba(248, 248, 248, 0.06) inset;
  backdrop-filter: blur(50px);
  cursor: pointer;
  @media screen and (max-width: 1023px) {
    font-size: 14px;
    padding: 10px;
  }
`;
export const MiningActionHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
  margin-bottom: 20px;
  ${TitleWrapper} {
    margin-bottom: 0;
  }
  @media screen and (max-width: 1023px) {
    width: 100%;
    flex-wrap: wrap;
  }
`;
export const MiningActionBody = styled.div`
  ${LiquidityActions} {
    ${ButtonCommon} {
      width: 100%;
    }
  }
`;
export const MiningBody = styled.div`
  .ant-table-cell {
    &:nth-child(5) {
      width: 300px;
    }
  }
`;
export const MiningBlock = styled.div`
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;
export const MiningTabs = styled.div`
  .ant-tabs-tab-btn {
    color: #9f9f9f;
    font-size: 20px;
    &:hover {
      color: #fff;
    }
  }
  .ant-tabs-tab.ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: #fff;
    }
  }
  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before {
    border-color: #9f9f9f;
  }
  .ant-tabs .ant-tabs-ink-bar {
    background: #fff;
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;
export const MiningBlockHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  flex-wrap: wrap;
  & > div {
    display: flex;
    align-items: center;
    gap: 10px;
    & > div {
      &:nth-child(2) {
        display: block !important;
        & > p {
          color: #fff;
          font-size: 15px;
          letter-spacing: -0.032em;
          &:nth-child(2) {
            display: block;
          }
        }
      }
    }
  }
  figure {
    width: 37px;
    height: 34px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    &:nth-child(2) {
      margin-left: -10px;
    }
  }
  @media screen and (max-width: 1023px) {
    flex-direction: unset !important;
    & > div {
      &:nth-child(2) {
        flex-direction: column;
        gap: 0;
        align-items: flex-end;
        & > p {
          font-size: 13px;
          color: #9f9f9f;
        }
      }
      & > div {
        display: flex;
      }
    }
  }
`;
export const MiningBlockList = styled.ul`
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  &.is-soon {
    overflow: hidden;
    & > li {
      filter: blur(3px);
    }
    &::before,
    &::after {
      content: "";
      position: absolute;
    }
    &::before {
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #121415 url("./img/Common/blur_frame.png") no-repeat center /
        cover;
      z-index: 5;
      opacity: 0.7;
    }
    &::after {
      left: 50%;
      top: 50%;
      margin-left: -50px;
      margin-top: -58px;
      background: url("./img/Common/img_soon.png") no-repeat center / 100% auto;
      width: 99px;
      height: 116px;
      z-index: 7;
    }
  }
  & > li {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    width: 25%;
    padding: 10px;
    border-radius: 8px;
    overflow: hidden;
    transition: all 0.1s linear;
    border: 1px solid transparent;
    ${BorderAnimation} {
      visibility: hidden;
    }
    &.active {
      /* border-color: #38943b; */
      ${BorderAnimation} {
        visibility: visible;
      }
    }
    &::before,
    &::after {
      content: "";
      position: absolute;
      background: no-repeat center / 100% auto;
      pointer-events: none;
      right: 0;
    }
    &::before {
      bottom: -2vw;
      background-image: url("./img/Dashboard/Swap/decor_swap_item.png");
      width: 100%;
      height: 255px;
      mix-blend-mode: exclusion;
      opacity: 0.3;
    }
    &::after {
      width: 93px;
      height: 91px;
      top: 0;
    }
    &:nth-child(1) {
      &::after {
        background-image: url("./img/Dashboard/mining/mining_decor_1.png");
      }
    }
    &:nth-child(2) {
      &::after {
        background-image: url("./img/Dashboard/mining/mining_decor_2.png");
      }
    }
    &:nth-child(3) {
      &::after {
        background-image: url("./img/Dashboard/mining/mining_decor_3.png");
      }
    }
    &:nth-child(4) {
      &::after {
        background-image: url("./img/Dashboard/mining/mining_decor_4.png");
      }
    }
    &:nth-child(5) {
      &::after {
        background-image: url("./img/Dashboard/mining/mining_decor_5.png");
      }
    }
    ${MiningBlockHeader} {
      margin-bottom: 120px;
    }
  }
  @media screen and (min-width: 1720.98px) and (max-width: 1859px) {
    flex-wrap: wrap;
    & > li {
      width: calc(100% / 4 - 40px);
    }
  }
  @media screen and (min-width: 1024px) and (max-width: 1720px) {
    flex-wrap: wrap;
    & > li {
      width: calc(100% / 3 - 40px);
    }
  }
`;
export const MiningItem = styled.div`
  ${MiningBlockHeader} {
    justify-content: space-between;
    & > div {
      & > p {
        color: #fff;
        font-size: 16px;
        font-weight: 700;
      }
      &:nth-child(1) {
        display: flex;
        align-items: center;
        & > div {
          display: flex;
        }
      }
      &:nth-child(2) {
        text-align: right;
        display: block;
        p {
          color: #9f9f9f;
          font-size: 13px;
        }
        span {
          display: block;
          color: #fff;
          font-size: 15px;
          font-weight: 500;
        }
      }
    }
  }
`;
export const MiningBlockBody = styled.div``;
export const MiningItemInfo = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 15px;
  & > div {
    &:nth-child(2) {
      text-align: right;
    }
    p {
      color: #9f9f9f;
      font-size: 13px;
    }
    span {
      color: #fff;
      font-size: 15px;
      font-weight: 500;
    }
  }
`;
export const MiningReward = styled.div`
  display: flex;
  gap: 10px;
  border-radius: 9px;
  margin-bottom: 5px;
  min-height: 35px;
  padding: 5px 20px 5px 5px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(40, 40, 40, 0.3);
  background-blend-mode: luminosity;
  box-shadow: 2px 4px 16px 0px rgba(248, 248, 248, 0.06) inset;
  backdrop-filter: blur(50px);
  & > div {
    display: flex;
    align-items: center;
    padding: 10px;
    gap: 6px;
    width: max-content;
    border-radius: 6px;
    border: 1px solid rgba(255, 204, 0, 0.12);
    background: rgba(31, 31, 31, 0.54);
    box-shadow: 0px 0px 8px 0px rgba(248, 248, 248, 0.6) inset;
    color: #d3d3d3;
    font-size: 13px;
    font-weight: 600;
  }
  & > p {
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    margin: auto;
  }
`;
export const MiningItemAction = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  & > div {
    width: 33.33%;
    padding: 5px 8px;
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(40, 40, 40, 0.4);
    background-blend-mode: luminosity;
    box-shadow: 2px 4px 16px 0px rgba(248, 248, 248, 0.06) inset;
    backdrop-filter: blur(50px);
    color: #fff;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
  }
`;
export const MininingSelect = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .ant-select {
    background-color: transparent;
    .ant-select-selection-item {
      padding-inline-end: 48px;
      img {
        width: 60px;
        height: auto;
      }
    }
  }
  .ant-select-dropdown {
    .ant-select-item-option-content {
      img {
        width: 60px;
        height: auto;
      }
    }
  }
`;

export const BlockTable = styled.div`
  .is-uppercase {
    text-transform: uppercase;
  }
  .time-transaction {
    display: flex;
    flex-direction: column-reverse;
    span {
    }
  }
  .text-des {
    display: inline-block;
    width: 244px;
  }
  .ant-table-content {
    .ant-table-cell {
      font-weight: 400 !important;
      font-size: 15px;
      text-align: center;
    }
    .ant-table-tbody {
      .ant-table-cell {
        &:nth-child(5) {
          text-align: left;
        }
        &:nth-child(2) {
          text-align: center;
        }
        &:nth-child(3) {
          ${TablePairName} {
            justify-content: center;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    overflow: hidden;
    margin-top: 20px;
    width: 100%;
    .ant-table-content {
      overflow: hidden;
      table {
        display: block;
      }
    }
    .ant-table-container {
      .text-des {
        width: 56%;
      }
    }
    .ant-table-tbody {
      display: block;
      .ant-table-cell {
        font-weight: 400 !important;
        &:nth-child(5) {
          text-align: center;
        }
        &:nth-child(2) {
          text-align: left !important;
        }
      }
      .ant-table-cell {
        text-align: left;
        color: #9f9f9f;
        &:nth-child(5) {
          display: flex;
          align-items: center;
        }
        > p,
        > span {
          text-align: left !important;
          color: #9f9f9f;
        }
      }
      .color-grey {
        color: #9f9f9f !important;
      }
      ${TablePairName} {
        display: block;
        justify-content: flex-start;
      }
    }
    .text-heading-mobile {
      font-size: 16px;
      color: #fff !important;
      width: 120px;
      display: inline-block;
      text-align: left;
    }
    .text-des {
      display: inline-block;
    }
    &.empty-data {
      .ant-table-placeholder,
      .ant-table-cell {
        display: block;
        width: 100%;
      }
    }
  }
`;
