import { Link } from "react-router-dom";
import BackCommon from "../../../components/Common/Back";
import { BlockFrame } from "../../../Layout/styled";
import { MiningBlockHeader } from "../styled";
import {
  MDBlock,
  MDBlockBody,
  MDBlockHeader,
  MDBlockSmall,
  MiningDescription,
  MiningDetailBlocks,
  MiningDetailWrapper,
  MiningInfoDetail,
  MiningSR,
  SRHeader,
} from "./styled";
import { useContext } from "react";
import { ContextProviderWrapper } from "../../../components/Context";

const MiningDetail = ({ backFunction }: any) => {
  const { isDesktop } = useContext(ContextProviderWrapper)!;
  return (
    <MiningDetailWrapper>
      <BackCommon
        backFunction={() => {
          backFunction(0);
        }}
      />
      <MiningDetailBlocks>
        <BlockFrame>
          <MiningSR>
            <SRHeader>
              <p>My Staked</p>
              <img
                width={19}
                height={18}
                src="./img/Common/hint.svg"
                alt=""
                loading="lazy"
              />
            </SRHeader>
            <p>1,000,000,000</p>
          </MiningSR>
          <MiningSR>
            <SRHeader>
              <p>Rewards</p>
              <img
                width={19}
                height={18}
                src="./img/Common/hint.svg"
                alt=""
                loading="lazy"
              />
            </SRHeader>
            <p>0.0</p>
          </MiningSR>
        </BlockFrame>
        <BlockFrame>
          <MiningBlockHeader>
            <div>
              <figure>
                <img
                  width={37}
                  height={36}
                  src="./img/Common/oni_icon.png"
                  alt="icon"
                  loading="lazy"
                />
              </figure>
              <figure>
                <img
                  width={37}
                  height={36}
                  src="./img/Common/bnb_icon.png"
                  alt="icon"
                  loading="lazy"
                />
              </figure>
            </div>
            <p>ONI/BNB</p>
          </MiningBlockHeader>
          <MiningDescription>
            <MDBlock className="green">
              <MDBlockHeader>
                <h2>Staking Period</h2>
                <p>
                  2024-08-05
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                  >
                    <path
                      d="M5.14258 10.3592L5.14258 3.97524C5.1426 3.86224 5.17613 3.75178 5.23892 3.65783C5.30171 3.56388 5.39095 3.49066 5.49535 3.44742C5.59975 3.40418 5.71462 3.39287 5.82545 3.4149C5.93628 3.43694 6.03809 3.49135 6.118 3.57124L9.31 6.76324C9.41712 6.8704 9.4773 7.01571 9.4773 7.16724C9.4773 7.31876 9.41712 7.46408 9.31 7.57124L6.118 10.7632C6.03809 10.8431 5.93628 10.8975 5.82545 10.9196C5.71462 10.9416 5.59975 10.9303 5.49535 10.8871C5.39095 10.8438 5.30171 10.7706 5.23892 10.6766C5.17613 10.5827 5.1426 10.4722 5.14258 10.3592Z"
                      fill="url(#paint0_linear_289_5274)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_289_5274"
                        x1="7.30994"
                        y1="3.40393"
                        x2="7.30994"
                        y2="10.9305"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#F0FDF2" />
                        <stop offset="1" stop-color="#BBF7C6" />
                      </linearGradient>
                    </defs>
                  </svg>
                  2026-08-05
                </p>
              </MDBlockHeader>
              <MDBlockBody>
                <p>Period</p>
                <span>24 months</span>
              </MDBlockBody>
            </MDBlock>
            {!isDesktop && (
              <MDBlock className="first">
                <MDBlockHeader>
                  <h2>Mining Pool</h2>
                  <Link to="#">Go Explore</Link>
                </MDBlockHeader>
                <MDBlockBody>
                  <p>Address</p>
                  <span>0x3a89...3e77</span>
                </MDBlockBody>
              </MDBlock>
            )}

            {isDesktop && (
              <MDBlock className="block-small">
                <MDBlockSmall className="first">
                  <MDBlockHeader>
                    <h2>Mining Pool</h2>
                    <Link to="#">Go Explore</Link>
                  </MDBlockHeader>
                  <MDBlockBody>
                    <p>Address</p>
                    <span>0x3a89...3e77</span>
                  </MDBlockBody>
                </MDBlockSmall>
                <MDBlockSmall className="second">
                  <MDBlockHeader>
                    <h2>Liquidity Pool</h2>
                    <Link to="#">Go Explore</Link>
                  </MDBlockHeader>
                  <MDBlockBody>
                    <p>Address</p>
                    <span>0x3a89...3aaa</span>
                  </MDBlockBody>
                </MDBlockSmall>
              </MDBlock>
            )}
            <MDBlock className="gray">
              <MDBlockHeader>
                <h2>APY</h2>
                <p>The Annual Percentage Yield (APY)</p>
              </MDBlockHeader>
              <MDBlockBody>
                <p>Period</p>
                <span>24 months</span>
              </MDBlockBody>
            </MDBlock>
            {!isDesktop && (
              <MDBlock className="second">
                <MDBlockHeader>
                  <h2>Liquidity Pool</h2>
                  <Link to="#">Go Explore</Link>
                </MDBlockHeader>
                <MDBlockBody>
                  <p>Address</p>
                  <span>0x3a89...3aaa</span>
                </MDBlockBody>
              </MDBlock>
            )}
          </MiningDescription>
          <MiningInfoDetail>
            <h2>pONI-BNB LP</h2>
            <div>
              <div>
                <p>Total Rewards</p>
                <span>Auto (2%)</span>
              </div>
              <div>
                <p>Daily Rewards</p>
                <span>3%</span>
              </div>
              <div>
                <p>Released Rewards</p>
                <span>0.5%</span>
              </div>
              <div>
                <p>Total Value Locked (TVL)</p>
                <span>2,12321,121</span>
              </div>
            </div>
          </MiningInfoDetail>
        </BlockFrame>
      </MiningDetailBlocks>
    </MiningDetailWrapper>
  );
};

export default MiningDetail;
