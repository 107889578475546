import styled from "styled-components";

export const BackContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  margin-bottom: 16px;
  figure {
    width: 32px;
    height: 32px;
  }
`