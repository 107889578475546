import styled from "styled-components";
import { ButtonCommon } from "../../Layout/styled";

export const OniBotContainer = styled.div`
  position: relative;
  overflow: hidden;
  margin: 0 0 20px;
  min-height: 100vh;
  padding: 100px 20px 0;
  background: linear-gradient(0deg, #0d0d0d -0.94%, #000 59.39%);
  border-radius: 16px;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: url("./img/Gif/bot_bg.gif") no-repeat center / cover;
    width: 100%;
    height: 500px;
    mix-blend-mode: plus-darker;
    opacity: 0.1;
    pointer-events: none;
  }
  @media screen and (max-width: 767px) {
    &::before {
      height: 650px;
    }
  }
`;
export const OniBotWrapper = styled.div`
  position: relative;
`;
export const OniBotTitle = styled.div`
  text-align: center;
  margin-bottom: 70px;
  h2 {
    color: #f7f8f8;
    font-size: 53px;
  }
  p {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: #f7f8f8;
    font-size: 50px;
    text-transform: uppercase;
  }
`;
export const OniBotButton = styled.button`
  color: #fff;
  text-align: center;
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  outline: none;
  border: none;
  border-radius: 8px;
  border: 1px solid var(--stroke-25-button, rgba(255, 255, 255, 0.4));
  background: var(--Surface-Background, rgba(40, 40, 40, 0.7));
  background-blend-mode: luminosity;
  box-shadow: 2px 4px 16px 0px rgba(248, 248, 248, 0.06) inset;
  backdrop-filter: blur(50px);
  width: 176px;
  height: 48px;
  margin: 20px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
export const OniBotInner = styled.div`
  position: relative;
  margin: 0 -20px;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: -360px;
    background: url("./img/Dashboard/Bot/onibot_intro_bg.png") no-repeat center /
      101% auto;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
  @media screen and (max-width: 1024px) {
    &:before {
      content: none;
    }
  }
`;
export const OniBotIntro = styled.div`
  position: relative;
  z-index: 10;
  padding-top: 260px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > figure {
    margin-top: -270px;
  }
  @media screen and (max-width: 1023px) {
    flex-direction: column;
    padding-top: 0;
    .image-intro {
      width: 100%;
      height: auto;
      margin: 0 -20px 20px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
`;
export const BotIntro = styled.div`
  margin: 0 auto;
  figure {
    margin-bottom: 15px;
  }
  & > h2 {
    color: #f7f8f8;
    font-size: 48px;
    font-weight: 500;
    line-height: 120%;
    text-transform: capitalize;
    margin-bottom: 25px;
  }
  & > p {
    color: #f7f8f8;
    font-size: 17px;
    font-weight: 600;
    line-height: 24.5px;
    margin-bottom: 50px;
    span {
      display: block;
      color: #8a8f98;
      font-size: 17px;
      font-weight: 400;
    }
  }
  @media screen and (max-width: 1023px) {
    order: 2;
    width: 96%;
    h2 {
      font-size: 32px;
    }
    & > p {
      margin-bottom: 20px;
    }
  }
`;
export const BotIntroButtons = styled.div`
  display: flex;
  gap: 10px;
  @media screen and (max-width: 1024px) {
    justify-content: space-between;
    ${ButtonCommon} {
      width: max-content;
      padding: 0 12px;
      min-height: 43px;
      justify-content: center;
      font-size: 12px;
      &:nth-child(1) {
        width: 30%
      }
      &:nth-child(2) {
        width: 20%;
      }
      &:nth-child(3) {
        width: 40%
      }
    }
  }
`;
export const OniBotJourney = styled.div`
  position: relative;
  background: #020202;
  min-height: 1000px;
  padding-top: 70px;
  z-index: 1;
  &::before {
    pointer-events: none;
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    background: url("./img/Dashboard/Bot/onibot_journey_bg.jpg") no-repeat
      center / cover;
    width: 100%;
    height: 723px;
    z-index: -1;
  }
  @media screen and (max-width: 1024px) {
    min-height: unset;
    padding-bottom: 110px;
  }
`;
export const JourneyTitle = styled.div`
  width: max-content;
  margin: 0 auto 60px;
  figure {
    margin: 0 auto 15px;
    text-align: center;
  }
  h2 {
    color: #f7f8f8;
    text-align: center;
    font-size: 48px;
    font-weight: 500;
    line-height: 120%;
    text-transform: capitalize;
  }
  @media screen and (max-width: 1024px) {
    width: unset;
    h2 {
      font-size: 30px;
    }
  }
`;
export const JourneyContent = styled.div`
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  margin-bottom: 120px;
  &:hover {
    .image-first {
      transform: rotate(25deg);
      z-index: 0;
    }
    .image-second {
      transform: rotate(-25deg);
      z-index: 1;
    }
  }
  img {
    position: absolute;
    left: 50%;
    transition: all 0.26s ease;
  }
  .image-first {
    margin-left: -350px;
    z-index: 1;
    margin-top: 260px;
  }
  .image-second {
    margin-left: -40px;
    z-index: 0;
  }
  @media screen and (max-width: 1024px) {
    min-height: 150px;
    margin-bottom: 0;
    .image-first {
      width: 241px;
      margin-top: 90px;
      margin-left: -189px;
    }
    .image-second {
      width: 241px;
      margin-left: -49px;
    }
  }
`;
export const JourneyContentText = styled.div`
  text-align: center;
  h3 {
    color: #f7f8f8;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
  }
  p {
    color: #8a8f98;
    font-size: 24px;
  }
`;
export const BotFuture = styled.div`
  background: url("/img/Dashboard/Bot/future_bg.jpg") center / cover no-repeat;
  margin: 0 -20px -20px;
  padding-bottom: 20px;
  .bot-inner {
    padding: 165px 138px;
  }
  h2 {
    color: #f9f9f9;
    font-size: 70px;
    text-transform: uppercase;
    max-width: 813px;
    margin-bottom: 37px;
  }
  ${ButtonCommon} {
    gap: 5px;
    padding: 16px 32px;
  }
`;
export const BotFutureUpdate = styled.div`
  border-radius: 13.5px;
  border: 1px solid #fff;
  background: linear-gradient(
      180deg,
      rgba(29, 32, 34, 0.59) 0%,
      rgba(38, 40, 41, 0.59) 100%
    ),
    rgba(113, 113, 113, 0.26);
  backdrop-filter: blur(7.5px);
  width: calc(98% - 108px);
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
  padding: 54px;
`;
export const BotFutureUpdateLeft = styled.div`
  width: 55%;
  .title {
    color: #f9f9f9;
    font-size: 18px;
    margin-bottom: 22px;
  }
  .block-input {
    border-radius: 6px;
    border: 1px solid var(--stroke-25-button, rgba(255, 255, 255, 0.4));
    background: rgba(3, 3, 3, 0.7);
    background-blend-mode: luminosity;
    box-shadow: 2px 4px 16px 0px rgba(248, 248, 248, 0.06) inset;
    backdrop-filter: blur(50px);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 6px 6px 6px 20px;
    input {
      width: calc(100% - 105px);
      outline: none;
      color: #f9f9f9;
      &::placeholder {
        color: rgba(255, 255, 255, 0.5);
        font-family: Rubik;
        font-size: 13.395px;
        font-weight: 400;
      }
    }
    .ant-input-affix-wrapper {
      background-color: unset;
      border: none;
      box-shadow: none;
    }
    .ant-input-clear-icon {
      color: #fff;
    }
    button {
      min-width: 100px;
      border-radius: 6px;
      border: 1px solid #ebeef2;
      background: linear-gradient(180deg, #a3aab4 0%, #555861 100%);
      padding: 8px 16px;
      color: #111;
      text-align: center;
      font-family: Rubik;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      text-transform: uppercase;
    }
  }
`;
export const BotFutureUpdateRight = styled.div`
  display: flex;
  justify-content: space-between;
  width: 35%;
`;
export const BotFutureUpdateList = styled.ul`
  li {
    &:not(:last-child) {
      margin-bottom: 14px;
    }
  }
  a {
    color: #f9f9f9;
    font-size: 16px;
  }
`;
export const BotFutureUpdateSocial = styled.ul`
  li {
    &:not(:last-child) {
      margin-bottom: 14px;
    }
  }
  a {
    color: #f9f9f9;
    font-family: Rubik;
    font-size: 16px;
  }
`;
